import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@mui/styles';
import { colors, Dialog, DialogActions, DialogTitle, DialogContent, DialogContentText, Slide, Button } from '@mui/material';

import services from './services';

const useStyles = makeStyles((theme) => ({
    paper: {
        textAlign: 'center'
    },
    cancelar: {
        color: colors.blue[500]
    },
    aceptar: {
        color: colors.green[500]
    }
}));

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const AgregarColaborador = ({ open, title, text, data, cancel, accept, ...props }) => {
    const classes = useStyles();

    return (
        <div>
            <Dialog open={open} TransitionComponent={Transition} keepMounted aria-describedby="alert-dialog-slide-description">
                <DialogTitle>{title}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">{text}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button className={classes.cancelar} onClick={cancel}>
                        Cancelar
                    </Button>
                    <Button className={classes.aceptar} onClick={accept}>
                        Aceptar
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default AgregarColaborador;
