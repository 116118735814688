import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@mui/styles';
import { colors } from '@mui/material';
import { AddBox } from '@mui/icons-material';

import { Tabla } from 'ui-component';
import AvisoConfirmacion from 'ui-component/AvisoConfirmacion';
import LayautContext from 'Context/ContextLayaut';
import Services from '../services';
import FormMaterial from './FormMaterial';
import { ActualizarData } from 'utils/herramientas';
import { isObject } from 'lodash';

const useStyles = makeStyles((theme) => ({
    paper: {
        textAlign: 'center'
    },
    cancelar: {
        color: colors.red[500]
    },
    aceptar: {
        color: colors.green[500]
    },
    texto: {
        color: colors.indigo[900],
        textAlign: 'center',
        fontSize: 13
    },
    textoPequeno: {
        color: colors.indigo[900],
        textAlign: 'center',
        fontSize: 9
    },
    textoTabla: {
        color: colors.indigo[900],
        textAlign: 'left',
        fontSize: 9
    },
    textoTablaTH: {
        color: colors.indigo[900],
        textAlign: 'left',
        fontSize: 11
    },
    cantidadTabla: {
        color: colors.indigo[900],
        textAlign: 'right',
        fontSize: 9
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200
    }
}));

const Materiales = ({ ...props }) => {
    const classes = useStyles();
    const services = Services();
    const [confirm, setConfirm] = useState({ open: false });
    const [ctls, setCtls] = useState([]);
    const [data, setData] = useState([]);
    const context = useContext(LayautContext);

    // Functions
    const abrirFormMaterial = () => (item) => {
        let titulo = item?.idmaterial ? 'Editar ' : 'Agregar ';
        titulo += 'material';
        context.formulario.abrir({
            open: true,
            width: 'sm',
            titulo: titulo,
            btnAceptarName: 'Guardar',
            propsForm: { data: item ? item : null, ctls: ctls },
            formulario: FormMaterial,
            aceptar: item?.idmaterial ? editarMaterial(item.idmaterial) : registrarMaterial
        });
    };

    const construirFormData = (data) => {
        let formData = new FormData();
        for (let field in data) {
            let valor = data[field];
            if (isObject(valor) && field != 'archivoMaterial') valor = JSON.stringify(valor);
            formData.append(field, valor);
        }
        return formData;
    };

    // Services
    const getInfoInicial = async () => {
        await services.getInfoInicialMaterial((res) => {
            setData(res.materiales);
            setCtls({ departamentos: res.departamentos, areas: res.areas, puestos: res.puestos });
        });
    };

    const registrarMaterial = (dataForm) => {
        services.crearMaterialInduccion(
            (dataResponse) => {
                setData(dataResponse.materiales);
                context.formulario.cerrar();
            },
            construirFormData({
                ...dataForm,
                archivoMaterial: dataForm?.archivoMaterial[0]
            })
        );
    };

    const editarMaterial = (idmaterial) => (dataForm) => {
        if (!idmaterial) return;
        services.editarMaterialInduccion(
            (dataResponse) => {
                let nuevaData = ActualizarData(data, 'idmaterial', [dataResponse.materiales[0]]);
                if (nuevaData) setData(nuevaData);
                context.formulario.cerrar();
            },
            construirFormData({
                ...dataForm,
                idmaterial: idmaterial,
                archivoMaterial: dataForm?.archivoMaterial[0]
            })
        );
    };

    const abrirAdvertencia = (item) => () => {
        let estatus = item?.idestatus == '1' ? 'desactivar' : 'activar';
        let mensaje = '¿Desea ' + estatus + ' el material?';
        setConfirm({ open: true, text: mensaje, aceptar: cambioEstatusMaterial(item) });
    };

    const cerrarAdvertencia = () => setConfirm({ open: false });

    const cambioEstatusMaterial = (item) => () => {
        cerrarAdvertencia();
        services.actualizarEstatusMaterial(
            (dataResponse) => {
                let nuevaData = ActualizarData(data, 'idmaterial', [dataResponse.materiales[0]]);
                if (nuevaData) setData(nuevaData);
            },
            { idmaterial: item.idmaterial, idestatus: item.idestatus }
        );
    };

    // Config
    const acciones = [{ label: 'Agregar material', icon: <AddBox />, click: abrirFormMaterial, disabled: false /*!permisos.crear*/ }];
    const accionesFila = [
        { label: 'Editar', onClick: (row) => abrirFormMaterial()(row), enabled: (row) => row.idestatus != 0 },
        { label: 'Sin opciones', onClick: (row) => {}, enabled: (row) => row.idestatus == 0 }
    ];

    const columnas = [
        { label: 'Nombre material', name: 'nmaterial', filtrar: true },
        { label: 'Departamento', name: 'ndepartamento', filtrar: true },
        { label: 'Área', name: 'narea', filtrar: true },
        { label: 'Puesto', name: 'npuesto', filtrar: true },
        {
            label: 'Estatus',
            name: 'idestatus',
            filtrar: false,
            type: {
                opcion: 'switch',
                accion: abrirAdvertencia,
                disabled: (row) => ['-201', '-202', '-203'].includes(row.idestatus)
            }
        }
    ];

    const cargarFunciones = () => {
        getInfoInicial();
    };

    useEffect(() => {
        cargarFunciones();
    }, []);

    return (
        <>
            <Tabla rows={data} accionesTabla={acciones} columns={columnas} accionesFila={accionesFila} columnKey="idmaterial" />
            <AvisoConfirmacion
                open={confirm.open}
                title="Advertencia"
                text={confirm.text}
                accept={confirm.aceptar}
                cancel={() => setConfirm({ open: false })}
            />
        </>
    );
};

export default Materiales;
