import { useEffect, useState } from 'react';

// material-ui
import { Search } from '@mui/icons-material';
import { Grid, Box, InputBase, IconButton, Tabs, Tab, TablePagination } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { styled, alpha } from '@mui/material/styles';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import ColaboradorCard from './Components/ColaboradorCard';
import OpenCard from './Components/OpenCard';
import Scrollbars from 'react-custom-scrollbars';

import Services from './Components/services';
import headers from './Components/headers';
import { useLogin } from 'Context/ContextLogin';

const SearchInput = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.primary.dark, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.primary.dark, 0.25)
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: 'auto'
    }
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.secondary.dark
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '12ch',
            '&:focus': {
                width: '20ch'
            }
        }
    }
}));

// ==============================|| DEFAULT DASHBOARD ||============================== //

const Dashboard = () => {
    const services = Services();
    const {
        usuario: { areas }
    } = useLogin();
    const [option, setOption] = useState(areas[0]?.idarea || null);
    const [colaboradores, setColaboradores] = useState({ original: [], filtered: [] });
    const [paginacion, setPaginacion] = useState({ numberPage: 0, rowsPerPage: 20 });
    const [dialogInfoCard, setDialogInfoCard] = useState({ open: false, data: {}, close: () => {} });

    // Handle
    const handleCloseDialogInfoCard = () => {
        setDialogInfoCard({ open: false, data: {}, close: () => {} });
    };

    // services
    const getInfoInicial = (idarea) => {
        services.getInfoInicial(
            (res) => {
                setColaboradores({ original: res.colaboradores, filtered: res.colaboradores });
            },
            { idarea }
        );
    };

    // Functions
    const openDialogInfoCard = (data) => {
        setDialogInfoCard({ open: true, data: data, close: handleCloseDialogInfoCard });
    };

    const searchColaborador = (event) => {
        const search = event.target.value;
        if (!search) return setColaboradores({ ...colaboradores, filtered: colaboradores.original });
        const newColaboradores = colaboradores.original.filter((colab) => colab.nombrePersona.toUpperCase().includes(search.toUpperCase()));
        return setColaboradores({ ...colaboradores, filtered: newColaboradores });
    };
    const clickCambioTipo = (event, idarea) => {
        setOption(idarea);
        getInfoInicial(idarea);
    };
    useEffect(() => {
        if (areas?.length) {
            setOption(areas[0].idarea);
            getInfoInicial(areas[0].idarea);
        }
    }, [areas]);

    return (
        <MainCard title="Colaboradores">
            <Grid container justifyContent="space-between">
                <Grid item>
                    {areas.length > 1 && (
                        <Grid item>
                            <Tabs value={option} onChange={clickCambioTipo} aria-label="basic tabs example">
                                {areas.map((area) => (
                                    <Tab key={`idarea_${area.idarea}`} label={area.narea} value={area.idarea} />
                                ))}
                            </Tabs>
                        </Grid>
                    )}
                </Grid>
                <Grid item>
                    <SearchInput onChange={searchColaborador}>
                        <SearchIconWrapper>
                            <Search />
                        </SearchIconWrapper>
                        <StyledInputBase placeholder="Buscar…" inputProps={{ 'aria-label': 'search' }} />
                    </SearchInput>
                </Grid>
            </Grid>

            <Scrollbars style={{ height: 'calc(90vh - 270px)' }}>
                <Grid container justifyContent="center" spacing={2}>
                    {colaboradores.filtered
                        .slice(
                            paginacion.numberPage * paginacion.rowsPerPage,
                            paginacion.numberPage * paginacion.rowsPerPage + paginacion.rowsPerPage
                        )
                        .map((colab) => (
                            <Grid item key={`colaborador_${colab.idColaborador}`}>
                                <IconButton onClick={() => openDialogInfoCard(colab)}>
                                    <ColaboradorCard data={colab} />
                                </IconButton>
                            </Grid>
                        ))}
                </Grid>
            </Scrollbars>
            <Grid container justifyContent="space-between">
                <Grid item>
                    <TablePagination
                        rowsPerPageOptions={[20, 40, 50]}
                        component="div"
                        count={colaboradores.filtered.length}
                        rowsPerPage={paginacion.rowsPerPage}
                        page={paginacion.numberPage}
                        onPageChange={(e, newPage) => setPaginacion({ ...paginacion, numberPage: newPage })}
                        onRowsPerPageChange={(event) => setPaginacion({ numberPage: 0, paginacion, rowsPerPage: +event.target.value })}
                    />
                </Grid>
            </Grid>
            <OpenCard open={dialogInfoCard.open} data={dialogInfoCard.data} close={dialogInfoCard.close} />
        </MainCard>
    );
};

export default Dashboard;
