import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import JoditEditor from 'jodit-react';
import { Controller } from 'react-hook-form';
import { FormControl, FormControlLabel } from '@mui/material';

const config = {
    colorPickerDefaultTab: 'color',
    buttons: [
        'bold',
        'strikethrough',
        'underline',
        'italic',

        '|',
        'ul',
        'ol',
        '|',
        'outdent',
        'indent',
        '|',
        'font',
        'fontsize',
        'brush',
        'paragraph',
        '|',
        'table',
        'align',
        'undo',
        'redo',
        '|',
        'hr',
        'eraser',
        'copyformat',
        '|',
        'symbol',
        'fullsize',
        'print'
    ],
    language: 'es',
    enter: 'DIV'
};
const InputCreateHtml = ({ defaultValue, rules, name, control, label }) => {
    const onBlur = (onchange) => (event) => onchange(event);

    return (
        <Controller
            defaultValue={defaultValue || ''}
            rules={rules || {}}
            name={name}
            control={control}
            render={({ field }) => <JoditEditor config={config} value={field.value} onBlur={onBlur(field.onChange)} />}
        />
    );
};

InputCreateHtml.propTypes = {
    control: PropTypes.any,
    name: PropTypes.string.isRequired,
    rules: PropTypes.object,
    defaultValue: PropTypes.any
};

export default InputCreateHtml;
