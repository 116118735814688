import React, { useState, useEffect } from 'react';
import { Bookmark, Star } from '@mui/icons-material';
import { Chip, Grid, Typography, Breadcrumbs, Divider, Box } from '@mui/material';
import { makeStyles, withStyles, useTheme } from '@mui/styles';
import { Calificacion, Lectura, Video, Examen } from 'views/HomeParticipantes/ComponentsHomeVentas';
import Scrollbars from 'react-custom-scrollbars';

// ==============================|| DEFAULT DASHBOARD ||============================== //

const useStyles = makeStyles((theme) => ({
    curso: {
        backgroundColor: theme.palette.secondary[800]
    },
    grupo: {
        borderRadius: '8px',
        color: theme.palette.primary.light,
        backgroundColor: theme.palette.secondary[800]
    }
}));

const Actividad = (props) => {
    const { breadcrumbs, data, regresar } = props;
    const classes = useStyles();
    const theme = useTheme();
    // const arrBradcrumbs = breadcrumbs?.split('|,');
    // const collapse = [cambiarOpenP, cambiarOpenS, cambiarOpenT, cambiarOpenA];
    // const prefix = ['curso', 'sesion', 'temario', 'actividades'];

    return (
        <Grid sx={{ backgroundColor: theme.palette.black.main, marginTop: 1 }} container spacing={4}>
            {/* <Grid item xs={12}>
                <Breadcrumbs aria-label="breadcrumb">
                    <StyledBreadcrumb label="Inicio" icon={<Bookmark fontSize="small" />} onClick={() => regresar()} />
                    {arrBradcrumbs.map((bread, key) => {
                        const crumb = bread?.split('^~');
                        const id = crumb[0];
                        const titulo = crumb[1];

                        // Necesita los prefijos para saber el id seleccionado
                        return key < 4 ? (
                            <StyledBreadcrumb
                                key={`${id}-${titulo}`}
                                label={titulo}
                                icon={<Bookmark fontSize="small" />}
                                onClick={() => collapse[key](`${prefix[key]}${id}`)}
                            />
                        ) : (
                            <StyledBreadcrumb key={`${id}-${titulo}`} label={titulo} icon={<Star fontSize="small" />} />
                        );
                    })}
                </Breadcrumbs>
            </Grid> */}
            <Grid sx={{ backgroundColor: theme.palette.black.dark }} item xs={12}>
                <Box sx={{ ml: 2, mb: 2 }}>
                    <Typography variant="menuHeader">{data.titulo}</Typography>
                </Box>
            </Grid>
            <Grid sx={{ color: theme.palette.black.light }} xs={12}>
                <Divider sx={{ color: theme.palette.black.light, borderBlockColor: theme.palette.black.light }} />
            </Grid>
            <Grid sx={{ m: 2 }} item xs={12}>
                <Box>
                    {data.showResultado && <Calificacion {...props} />}
                    {data.lectura && !data.showResultado && <Lectura {...props} />}
                    {data.video && !data.showResultado && <Video {...props} />}
                    {data.examen && !data.showResultado && <Examen {...props} />}
                </Box>
            </Grid>
        </Grid>
    );
};

export default Actividad;
