import React, { useState, useEffect, useContext } from 'react';
import { AddBox } from '@mui/icons-material';
import { ModalGeneral, Tabla } from 'ui-component';
import headers from './components/headers';
import MainCard from 'ui-component/cards/MainCard';
import LayautContext from 'Context/ContextLayaut';
import AvisoConfirmacion from 'ui-component/AvisoConfirmacion';
import { ServiciosGrupo, FormGrupo } from './components';
import { Button } from '@mui/material';

const Grupos = ({ permisos }) => {
    //
    const context = useContext(LayautContext);
    const serviciosGrupos = ServiciosGrupo();
    // States

    const [grupo, setGrupo] = useState([]);
    const [ctl, setCtl] = useState({ estatusGrupo: [] });
    const [confirm, setConfirm] = useState({ open: false });
    const [modal, setModal] = useState({ open: false, titulo: '', width: 'xs', component: <></>, cerrar: () => {} });
    // Handle
    const actualizarData = (nuevaGrupo = []) => {
        const datausuario = grupo.map((oc) => oc.idGrupo);
        nuevaGrupo.forEach((usuarioI) => {
            const indice = datausuario.indexOf(usuarioI.idGrupo);
            if (indice >= 0) grupo.splice(indice, 1, usuarioI);
            else grupo.splice(0, 0, usuarioI);
        });
        setGrupo([...grupo]);
    };
    const abrirEditarGrupo = (row) => {
        context.formulario.abrir({
            open: true,
            propsForm: { data: row, ctl: ctl },
            formulario: FormGrupo,
            aceptar: (dataForm) => {
                serviciosGrupos.updateGrupo(
                    (res) => {
                        actualizarData(res.grupo);
                        context.formulario.cerrar();
                    },
                    { idGrupo: row.idGrupo, ...dataForm }
                );
            }
        });
    };
    const abrirNuevoGrupo = () => () => {
        context.formulario.abrir({
            open: true,
            width: 'sm',
            propsForm: { data: null, ctl: ctl },
            formulario: FormGrupo,
            aceptar: (dataForm) => {
                serviciosGrupos.insertGrupo((res) => {
                    actualizarData(res.grupo);
                    context.formulario.cerrar();
                }, dataForm);
            }
        });
    };
    const abrirConfirmarCambio = (grupo) => (e) => {
        let texto = `¿Estás seguro de volver a activar el grupo ${grupo.nombre}?`;
        let status = 1;
        if (grupo.estatus === '1') {
            texto = `¿Estás seguro de desactivar el grupo ${grupo.nombre}?`;
            status = 0;
        }
        setConfirm({
            open: true,
            text: texto,
            aceptar: () =>
                serviciosGrupos.cambiarEstatusGrupo(
                    (nuevaData) => {
                        actualizarData(nuevaData.grupo);
                        setConfirm({ open: false });
                    },
                    { ids: grupo.idGrupo, estatus: status }
                )
        });
    };
    const cerrarVerCursos = () => setModal({ open: false, titulo: '', width: 'xs', component: <></>, cerrar: () => {} });
    const verCursos = (row) => {
        serviciosGrupos.getCursoByGrupo(
            (res) => {
                setModal({
                    open: true,
                    titulo: 'Cursos',
                    width: 'lg',
                    component: <Tabla columns={headers(abrirConfirmarCambio, ctl).curso} columnKey="idCurso" rows={res.cursos} />,
                    cerrar: cerrarVerCursos
                });
            },
            { idGrupo: row.idGrupo }
        );
    };
    // Config
    const acciones = [{ label: 'Nuevo usuario', icon: <AddBox />, click: abrirNuevoGrupo }];
    const accionesFila = [
        { label: 'Editar', onClick: abrirEditarGrupo, enabled: true },
        { label: 'Mostrar cursos', onClick: verCursos, enabled: true }
    ];
    useEffect(() => {
        serviciosGrupos.grupoInt((ct) => {
            setGrupo(ct.grupo || []);
            setCtl({ ...ctl, estatusGrupo: ct.estatusGrupo });
        });
    }, []);
    return (
        <MainCard title="Grupos">
            <Tabla
                rows={grupo}
                columns={headers(abrirConfirmarCambio, ctl).grupo}
                accionesTabla={acciones}
                accionesFila={accionesFila}
                columnKey="idGrupo"
            />
            <AvisoConfirmacion
                open={confirm.open}
                title="Advertencia"
                text={confirm.text}
                accept={confirm.aceptar}
                cancel={() => setConfirm({ open: false })}
            />
            <ModalGeneral
                open={modal.open}
                titulo={modal.titulo}
                width={modal.width}
                acciones={<Button onClick={modal.cerrar}>Cerrar</Button>}
            >
                {modal.component}
            </ModalGeneral>
        </MainCard>
    );
};

export default Grupos;
