import React, { useState, useEffect, useRef, useContext } from 'react';
import LayautContext from 'Context/ContextLayaut';

import { makeStyles } from '@mui/styles';
import { Container, Box, colors, Radio, RadioGroup, FormControl, FormControlLabel } from '@mui/material';

import { AddBox, DeleteForever, GetApp } from '@mui/icons-material';
import PropTypes from 'prop-types';

import { Tabla } from 'ui-component';
import MainCard from 'ui-component/cards/MainCard';

import ModalEnvioFormulario from './Components/ModalEnvioFormulario';
import ModalEscribirReporte from './Components/ModalEscribirReporte';
import AgregarColaborador from './Components/AgregarColaborador';
import { useLogin } from 'Context/ContextLogin';
// import AgregarColaborador from '../Trainer/Components/AgregarColaborador';

import Services from './Components/services';
import headers from './Components/headers';
import GroupsIcon from '@mui/icons-material/Groups';
import localUtils from './Components/utils';

const useStyles = makeStyles((theme) => ({
    paper: {
        textAlign: 'center'
    },
    cancelar: {
        color: colors.red[500]
    },
    aceptar: {
        color: colors.green[500]
    },
    texto: {
        color: colors.indigo[900],
        textAlign: 'center',
        fontSize: 13
    },
    textoPequeno: {
        color: colors.indigo[900],
        textAlign: 'center',
        fontSize: 9
    },
    textoTabla: {
        color: colors.indigo[900],
        textAlign: 'left',
        fontSize: 9
    },
    textoTablaTH: {
        color: colors.indigo[900],
        textAlign: 'left',
        fontSize: 11
    },
    cantidadTabla: {
        color: colors.indigo[900],
        textAlign: 'right',
        fontSize: 9
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200
    }
}));

const CursosGenerales = ({ ...props }) => {
    const {
        usuario: { areas }
    } = props;
    const classes = useStyles();
    const services = Services();
    const context = useContext(LayautContext);
    // States
    const [cargando, setCargando] = useState(false);
    const [mensaje, setMensaje] = useState({ open: false });
    const [confirm, setConfirm] = useState({ open: false, title: '', text: '', accept: () => {} });

    const [modalEnvioForm, setModalEnvioForm] = useState({ open: false, data: {} });
    const [modalEscribirReporte, setModalEscribirReporte] = useState({ open: false, data: {} });

    const [ctl, setCtl] = useState({ personal: [], estatusGrupo: [] });
    const [personal, setPersonal] = useState({ personal: [], allData: [] });
    const [agregarColaborador, setAgregarColaborador] = useState({
        open: false,
        title: '',
        data: { row: [], personal: [] },
        cancel: () => {}
    });

    const closeAgregarColaborador = () => {
        setAgregarColaborador({ open: false, title: '', data: { row: [], personal: [] }, cancel: () => {} });
    };

    // Services
    const getInfoInicialAdmin = async () => {
        let idAreas = areas.map((area) => area.idarea);
        await services.getInfoInt(
            (res) => {
                if (res?.status == 1) {
                    setCtl({ ...ctl, personal: res?.data?.personal });
                }
            },
            { areas: idAreas }
        );
    };

    // Functions
    const cargarFunciones = () => {
        getInfoInicialAdmin();
    };

    const enviarFormulario = (row) => {
        context.formulario.abrir({
            open: true,
            titulo: 'Enviar formulario',
            propsForm: { data: row },
            formulario: ModalEnvioFormulario,
            mensajeConfirmacion: 'El correo será enviado.',
            aceptar: (data) => {
                const correoToSend = row.email_personal ? row.email_personal : data.correo;
                const sendData = { correo: correoToSend };

                services.sendCorreoFormulario((res) => {
                    context.formulario.cerrarModalForm();
                }, sendData);
            }
        });
    };

    const escribirReporte = (row) => {
        context.formulario.abrir({
            open: true,
            titulo: 'Enviar reporte',
            propsForm: { data: row },
            formulario: ModalEscribirReporte,
            mensajeConfirmacion: 'El reporte será enviado.',
            aceptar: (data) => {
                services.sendCorreoReporte((res) => {
                    context.formulario.cerrarModalForm();
                }, data);
            }
        });
    };

    const asigGrup = () => {
        setAgregarColaborador({
            open: true,
            title: 'Agregar colaborador',
            data: { personal: ctl.personal, withLeader: 0 },
            cancel: closeAgregarColaborador
        });
    };

    useEffect(() => {
        cargarFunciones();
    }, []);

    // Config
    const acciones = [
        {
            label: 'Asignar Curso',
            icon: <GroupsIcon />,
            click: (data) => (even) => {
                asigGrup(1, data);
            },
            dataSelected: false,
            color: classes.btnBorrar
        }
    ];
    const accionesFila = [
        {
            label: 'Enviar formulario',
            onClick: (row) => {
                enviarFormulario(row);
            },
            enabled: true
        },
        {
            label: 'Escribir reporte',
            onClick: (row) => {
                escribirReporte(row);
            },
            enabled: true
        }
        //{ label: 'Enviar evaluación', onClick: (row) => {}, enabled: true },
        //{ label: 'Asignar Colaborador', onClick: (row) => {}, enabled: (row) => row.isTrainer == 1 },
        //{ label: 'Asignar Curso', onClick: (row) => {}, enabled: (row) => !(row.isTrainer == 1) }
    ];

    return (
        <MainCard title="Capacitación Administrativa">
            <Tabla
                rows={ctl.personal}
                columns={headers.personalAdmin}
                accionesFila={accionesFila}
                accionesTabla={acciones}
                columnKey="idpersona"
                isSeleccionable
            />
            <AgregarColaborador
                open={agregarColaborador.open}
                title={agregarColaborador.title}
                data={agregarColaborador.data}
                cancel={agregarColaborador.cancel}
                updatesetPersonal={getInfoInicialAdmin}
                infoUsuario={props.usuario}
            />
        </MainCard>
    );
};

export default CursosGenerales;
