const columns = (cambioEstatus) => ({
    grupo: [
        { label: 'Grupo', name: 'nombre', filtrar: true },
        { label: 'Modificación', name: 'fechaModificacion', type: { opcion: 'date', accion: 4 }, filtrar: true },
        {
            label: 'Estatus',
            name: 'estatus',
            filtrar: false,
            type: { opcion: 'switch', accion: cambioEstatus }
        }
    ],
    curso: [
        { label: 'Curso', name: 'titulo', filtrar: true },
        { label: 'Costo', name: 'costo', type: { opcion: 'numero', accion: 'money' }, filtrar: true },
        {
            label: 'Interno',
            name: 'interno',
            type: (row) => (row.interno == '1' ? 'Sí' : 'No'),
            filtrar: {
                id: 'idint',
                value: 'nint',
                ctl: [
                    { idint: '1', nint: 'Sí' },
                    { idint: '0', nint: 'No' }
                ]
            }
        },
        { label: 'Nivel requerido', name: 'nivelRequerido', filtrar: true },
        { label: 'Duración', name: 'duracionCurso', filtrar: true },
        {
            label: 'Extemporaneo',
            name: 'extemporaneo',
            type: (row) => (row.extemporaneo == '1' ? 'Sí' : 'No'),
            filtrar: {
                id: 'idext',
                value: 'next',
                ctl: [
                    { idext: '1', next: 'Sí' },
                    { idext: '0', next: 'No' }
                ]
            }
        },
        { label: 'Calificación aprobatoria', name: 'califAprobatoria', filtrar: true }
    ]
});

export default columns;
