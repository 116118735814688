import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@mui/styles';
import { colors, TextField, Grid } from '@mui/material';

import { useFormContext } from 'react-hook-form';

const useStyles = makeStyles((theme) => ({
    paper: {
        textAlign: 'center'
    },
    cancelar: {
        color: colors.blue[500]
    },
    aceptar: {
        color: colors.green[500]
    },
    divGrupoAlumnos: {
        marginTop: '50px'
    }
}));

const AgregarGrupotrainer = ({ data, ...props }) => {
    const classes = useStyles();

    const {
        register,
        formState: { errors }
    } = useFormContext();

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <TextField
                    label="Nombre"
                    size="small"
                    fullWidth
                    {...register('nombre', { required: { value: true, message: 'Campo requerido' } })}
                    error={Boolean(errors.nombre)}
                    helperText={errors.nombre?.message}
                    defaultValue={data?.nombre}
                />
            </Grid>
        </Grid>
    );
};

export default AgregarGrupotrainer;
