import React, { useState, useEffect } from 'react';
import { Inbox, Drafts, KeyboardArrowDown, KeyboardArrowUp, StarBorder } from '@mui/icons-material';
import { Grid, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Divider, IconButton, Tooltip } from '@mui/material';
import { useFormContext, Controller } from 'react-hook-form';

const FormOrdenar = ({ open, data, ...props }) => {
    const {
        register,
        watch,
        formState: { errors },
        control
    } = useFormContext();

    const [dataOrden, setDataOrden] = useState([]);
    const [modal, setModal] = useState({ open: false, titulo: '' });

    const IconLabelButton = ({ icon, label, onClick, disabled, ...props }) => (
        <Tooltip title={label} disableInteractive>
            <IconButton onClick={onClick} disabled={disabled}>
                {icon}
            </IconButton>
        </Tooltip>
    );

    // Functions
    const agregarOrden = (dataToOrdenar) => dataToOrdenar.map((orden, index) => ({ ...orden, orden: index + 1 }));

    const Duplicados = (arr) => {
        const busqueda = arr.reduce((acc, data) => {
            acc[data.orden] = acc[data.orden] + 1 || 0;
            return acc;
        }, {});
        return arr.filter((data) => busqueda[data.orden]);
    };

    const subirPosicion = (ordenIndex, ordenSelected) => {
        const ordenReorden = dataOrden.map((orden, index) => {
            // Subir seleccionado
            if (ordenIndex - 1 == index) return { ...dataOrden[ordenIndex], orden: orden.orden };
            // Bajar posicion anterior
            if (ordenIndex == index) return { ...dataOrden[ordenIndex - 1], orden: orden.orden };
            return orden;
        });
        setDataOrden(ordenReorden);
        return ordenReorden;
    };
    const bajarPosicion = (ordenIndex, ordenSelected) => {
        const ordenReorden = dataOrden.map((orden, index) => {
            // Bajar seleccionado
            if (ordenIndex + 1 == index) return { ...dataOrden[ordenIndex], orden: orden.orden };
            // Subir posicion posterior
            if (ordenIndex == index) return { ...dataOrden[ordenIndex + 1], orden: orden.orden };
            return orden;
        });

        setDataOrden(ordenReorden);
        return ordenReorden;
    };

    useEffect(() => {
        const duplicados = Duplicados(data.dataOrden);
        const dataNueva = duplicados.length ? agregarOrden(data.dataOrden) : data.dataOrden;
        setDataOrden(dataNueva);
    }, []);

    return (
        <>
            <Controller
                control={control}
                name="dataOrdenada"
                render={({ field: { onChange } }) => (
                    <List>
                        <ListItem disablePadding>
                            <Grid container>
                                <Grid item xs={1}>
                                    <ListItemText secondary="Orden" />
                                </Grid>
                            </Grid>
                        </ListItem>
                        {dataOrden.map((orden, index) => (
                            <>
                                <ListItem disablePadding>
                                    <Grid container>
                                        <Grid item xs={1}>
                                            <ListItemText primary={orden.orden} />
                                        </Grid>
                                        <Grid item xs={9}>
                                            <ListItemText primary={orden.titulo || orden.npregunta} />
                                        </Grid>
                                        <Grid item xs={1}>
                                            <IconLabelButton
                                                icon={<KeyboardArrowUp />}
                                                label="Subir"
                                                onClick={() => onChange(subirPosicion(index, orden))}
                                                disabled={index == 0}
                                            />
                                        </Grid>
                                        <Grid item xs={1}>
                                            <IconLabelButton
                                                icon={<KeyboardArrowDown />}
                                                label="Bajar"
                                                onClick={() => onChange(bajarPosicion(index, orden))}
                                                disabled={index == dataOrden.length - 1}
                                            />
                                        </Grid>
                                    </Grid>
                                </ListItem>
                                <Divider />
                            </>
                        ))}
                    </List>
                )}
            />
        </>
    );
};

export default FormOrdenar;
