import React from 'react';
import { Tabla } from 'ui-component';
import {
    Collapse,
    colors,
    Grid,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableRow,
    TableHead,
    IconButton,
    Tooltip,
    Button
} from '@mui/material';
import { makeStyles, styled } from '@mui/styles';
import clsx from 'clsx';

const Item = styled(Typography)(({ theme }) => ({
    ...theme.typography.body1,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary
}));
const useStyles = makeStyles((theme) => ({
    respuestaCorrecta: {
        background: theme.palette.success.main,
        border: 0,
        borderRadius: 3,
        color: 'white'
    },
    respuestaSeleccionada: {
        border: `3px solid ${theme.palette.info.main}`
    }
}));

const FormCostoGrupo = (props) => {
    const classes = useStyles();
    const { rows, columns, accionesTabla, accionesFila, columnKey, data } = props;
    return (
        <Tabla
            rows={rows}
            columns={columns}
            accionesTabla={accionesTabla}
            accionesFila={accionesFila}
            columnKey={columnKey}
            DetailByrow={(row) => (
                <Grid container spacing={2}>
                    {row?.respuestas?.map((rsp) => (
                        <Grid item xs={3} key={rsp.nrespuesta}>
                            <Item
                                className={clsx(
                                    rsp.isCorrecta == '1' ? classes.respuestaCorrecta : null,
                                    rsp.idRespuestaUsuario != null ? classes.respuestaSeleccionada : null
                                )}
                                color="purple"
                            >
                                {row.idTipoPregunta == '1' ? `R- ${rsp.nrespuesta}` : `R- ${rsp.comentario}`}
                            </Item>
                        </Grid>
                    ))}
                </Grid>
            )}
        />
    );
};

export default FormCostoGrupo;
