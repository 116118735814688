import LayautContext from 'Context/ContextLayaut';
import { useContext } from 'react';

const ServiciosCatalogo = () => {
    const context = useContext(LayautContext);
    return {
        puestosInt: context.llamarServidor('Catalogo/getPuestoInt'),
        insertPuesto: context.llamarServidorRespuesta('Catalogo/insertPuesto'),
        updatePuesto: context.llamarServidorRespuesta('Catalogo/updatePuesto'),
        cambioEstatusPuesto: context.llamarServidorRespuesta('Catalogo/cambioEstatusPuesto'),
        getPermisosPaginaByPuesto: context.llamarServidor('Catalogo/getPermisosPaginaByPuesto'),
        cambioPermisosPuesto: context.llamarServidorRespuesta('Catalogo/cambioPermisosPuesto'),
        rolesInt: context.llamarServidor('Catalogo/getRolesInt'),
        insertRol: context.llamarServidorRespuesta('Catalogo/insertRol'),
        updateRol: context.llamarServidorRespuesta('Catalogo/updateRol'),
        cambioEstatusRol: context.llamarServidorRespuesta('Catalogo/cambioEstatusRol'),
        getPermisosPaginaByRol: context.llamarServidor('Catalogo/getPermisosPaginaByRol'),
        cambioPermisosRol: context.llamarServidorRespuesta('Catalogo/cambioPermisosRol'),
        getPaginas: context.llamarServidor('Catalogo/getPaginas'),
        addPagina: context.llamarServidorRespuesta('Catalogo/addPagina'),
        editPagina: context.llamarServidorRespuesta('Catalogo/editPagina'),
        addRolesPagina: context.llamarServidorRespuesta('Catalogo/addRolesPagina'),
        cambiarEstatusPagina: context.llamarServidorRespuesta('Catalogo/cambiarEstatusPagina'),
        cambioEstatusPermisoPagina: context.llamarServidorRespuesta('Catalogo/cambioEstatusPermisoPagina'),
        getRolesPagina: context.llamarServidor('Catalogo/getRolesPagina')
    };
};

export default ServiciosCatalogo;
