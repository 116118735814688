import React, { useState, useEffect, useContext } from 'react';
import { AddBox } from '@mui/icons-material';
import { Tabla } from 'ui-component';
import headers from './components/headers';
import MainCard from 'ui-component/cards/MainCard';
import LayautContext from 'Context/ContextLayaut';
import AvisoConfirmacion from 'ui-component/AvisoConfirmacion';
import { ServiciosProveedor } from './components';

const Proveedor = ({ permisos }) => {
    //
    const context = useContext(LayautContext);
    const serviciosProveedor = ServiciosProveedor();

    // States

    const [proveedor, setProveedor] = useState([]);
    const [ctl, setCtl] = useState({ estatusProveedor: [] });
    const [confirm, setConfirm] = useState({ open: false });

    // Handle
    const actualizarData = (nuevaGrupo = []) => {
        const datausuario = proveedor.map((oc) => oc.idGrupo);
        nuevaGrupo.forEach((usuarioI) => {
            const indice = datausuario.indexOf(usuarioI.idGrupo);
            if (indice >= 0) proveedor.splice(indice, 1, usuarioI);
            else proveedor.splice(0, 0, usuarioI);
        });
        setProveedor([...proveedor]);
    };

    const abrirConfirmarCambio = (proveedor) => (e) => {
        let texto = `¿Estás seguro de volver a activar el proveedor ${proveedor.nombre}?`;
        let status = 1;
        if (proveedor.estatus === '1') {
            texto = `¿Estás seguro de desactivar el proveedor ${proveedor.nombre}?`;
            status = 0;
        }
        setConfirm({
            open: true,
            text: texto,
            aceptar: () =>
                serviciosProveedor.cambiarEstatusProveedor(
                    (nuevaData) => {
                        actualizarData(nuevaData.proveedor);
                        setConfirm({ open: false });
                    },
                    { ids: proveedor.idGrupo, estatus: status }
                )
        });
    };

    // Config
    // const acciones = [{ label: 'Nuevo usuario', icon: <AddBox />, click: abrirNuevoGrupo }];
    // const accionesFila = [{ label: 'Editar', onClick: abrirEditarGrupo, enabled: true }];
    useEffect(() => {
        serviciosProveedor.proveedorInt((ct) => {
            setProveedor(ct.proveedor || []);
            // setCtl({ ...ctl, estatusProveedor: ct.estatusProveedor });
        });
    }, []);

    return (
        <MainCard title="Proveedor">
            <Tabla
                rows={proveedor}
                columns={headers(abrirConfirmarCambio, ctl).proveedor}
                // accionesTabla={acciones}
                // accionesFila={accionesFila}
                columnKey="idProveedor"
            />
        </MainCard>
    );
};

export default Proveedor;
