const columns = {
    pagina: (cambioEstatus) => [
        { label: '#', name: 'idpagina', filtrar: true },
        { label: 'Nombre', name: 'npagina', filtrar: true },
        { label: 'Ruta', name: 'ruta', filtrar: true },
        { label: 'Categoria', name: 'idcategoria', filtrar: true },
        { label: 'Orden', name: 'orderby', filtrar: true },
        {
            label: 'Estatus',
            name: 'idestatus',
            filtrar: false,
            type: { opcion: 'switch', accion: cambioEstatus }
        }
    ],
    roles: (abrirConfirmarCambio) => [
        { label: '#', name: 'idrol', filtrar: true },
        { label: 'Nombre', name: 'nrol', filtrar: true },
        {
            label: 'Estatus rol',
            name: 'idestatus',
            filtrar: false,
            type: { opcion: 'switch', accion: abrirConfirmarCambio }
        }
    ],
    rolesAdd: (ComponentEstatus) => [
        { label: '#', name: 'idrol', filtrar: true },
        { label: 'Nombre', name: 'nrol', filtrar: true },
        { label: 'Pagina', name: 'npagina', filtrar: true },
        { label: 'Descripción', name: 'descripcion', filtrar: true },
        {
            label: 'Estatus permiso',
            name: 'estatuspermiso',
            filtrar: false,
            type: ComponentEstatus
        }
    ]
};

export default columns;
