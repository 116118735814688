// material-ui
import { AddBox } from '@mui/icons-material';
import { Button, ButtonGroup, Typography } from '@mui/material';
import LayautContext from 'Context/ContextLayaut';
import { useContext, useEffect, useState } from 'react';
import { ModalGeneral } from 'ui-component';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import Tabla from 'ui-component/Tabla';
import ServiciosCatalogo from 'utils/serviciosCatalogo';
import { FormPermisosRelacion, FormPuesto } from './Components';

// ==============================|| SAMPLE PAGE ||============================== //

const Puestos = () => {
    const serviciosCatalogo = ServiciosCatalogo();
    const context = useContext(LayautContext);
    const [puesto, setPuesto] = useState([]);
    const [ctl, setCtl] = useState({ pagina: [] });
    const [modalPersmisos, setModalPermisos] = useState({ open: false, titulo: '', data: null });
    const actualizarData = (nuevaUsuario = []) => {
        const datausuario = puesto.map((oc) => oc.idpuesto);
        nuevaUsuario.forEach((usuarioI) => {
            const indice = datausuario.indexOf(usuarioI.idpuesto);
            if (indice >= 0) puesto.splice(indice, 1, usuarioI);
            else puesto.splice(0, 0, usuarioI);
        });
        setPuesto([...puesto]);
    };
    const abrirNuevoUsuario = () => () => {
        context.formulario.abrir({
            open: true,
            propsForm: { data: null },
            formulario: FormPuesto,
            aceptar: (data) => {
                serviciosCatalogo.insertPuesto((nuevaData) => {
                    context.formulario.cerrarModalForm();
                    setPuesto(nuevaData.puesto);
                }, data);
            }
        });
    };
    const cerrarPermisos = () => {
        setModalPermisos({ open: false, titulo: '', data: null });
    };
    const abrirEditarPuesto = (row) =>
        context.formulario.abrir({
            open: true,
            propsForm: { data: row },
            formulario: FormPuesto,
            aceptar: (data) => {
                serviciosCatalogo.updatePuesto(
                    (nuevaData) => {
                        actualizarData(nuevaData.puesto);
                        context.formulario.cerrarModalForm();
                    },
                    { idpuesto: row.idpuesto, ...data }
                );
            }
        });
    const abrirPermisos = (item) =>
        setModalPermisos({
            open: true,
            width: 'md',
            Formulario: FormPermisosRelacion,
            paddingDisabled: true,
            titulo: `Permisos para el puesto: ${item.npuesto}`,
            props: {
                id: 'idpuesto',
                ctlg: ctl,
                item: item,
                cambioPermisos: serviciosCatalogo.cambioPermisosPuesto,
                cambioPagina: (setCambio, pagina) =>
                    serviciosCatalogo.getPermisosPaginaByPuesto(setCambio, {
                        idpuesto: item.idpuesto,
                        idpagina: pagina.idpagina
                    })
            }
        });
    const abrirConfirmarCambio = (usuario) => (e) => {
        let texto = `¿Estás seguro de volver a activar el puesto ${usuario.npuesto}?`;
        let status = 1;
        if (usuario.idestatus === '1') {
            texto = `¿Estás seguro de desactivar el puesto ${usuario.npuesto}, un puesto inactivo no puede tiene permiosos?`;
            status = 0;
        }
        setModalPermisos({
            open: true,
            titulo: '¡Advertencia!',
            mensaje: texto,
            width: 'xs',
            aceptar: () =>
                serviciosCatalogo.cambioEstatusPuesto(
                    (nuevaData) => {
                        actualizarData(nuevaData.puesto);
                        cerrarPermisos({ open: false });
                    },
                    { idpuesto: usuario.idpuesto, idestatus: status }
                )
        });
    };
    // Configuracion de la tabla
    const columns = [
        { label: 'Puesto', name: 'npuesto', filtrar: true },
        {
            label: 'Estatus',
            name: 'idestatus',
            filtrar: false,
            type: { opcion: 'switch', accion: abrirConfirmarCambio }
        }
    ];
    const acciones = [{ label: 'Nuevo puesto', icon: <AddBox />, click: abrirNuevoUsuario }];
    const accionesFila = [
        { label: 'Editar', onClick: abrirEditarPuesto, enabled: true, filtrar: false },
        { label: 'Permisos', onClick: abrirPermisos, enabled: true }
    ];
    useEffect(() => {
        serviciosCatalogo.puestosInt((ct) => {
            setPuesto(ct.puesto);
            setCtl({ ...ctl, pagina: ct.pagina });
        });
    }, []);
    return (
        <MainCard title="Puestos">
            <Tabla
                rows={puesto}
                columns={columns}
                accionesTabla={acciones}
                accionesFila={accionesFila}
                isSeleccionable
                columnKey="idpuesto"
            />
            <ModalGeneral
                open={modalPersmisos.open}
                titulo={modalPersmisos.titulo}
                width={modalPersmisos.width}
                paddingDisabled={modalPersmisos.paddingDisabled}
                ContenidoMensaje={modalPersmisos.mensaje}
                acciones={
                    <>
                        <Button className="btnError" onClick={cerrarPermisos}>
                            Cerrar
                        </Button>
                        {Boolean(modalPersmisos.aceptar) && (
                            <Button color="primary" onClick={modalPersmisos.aceptar}>
                                Aceptar
                            </Button>
                        )}
                    </>
                }
            >
                {modalPersmisos.Formulario && <modalPersmisos.Formulario {...modalPersmisos.props} proveedor={modalPersmisos.proveedor} />}
            </ModalGeneral>
        </MainCard>
    );
};

export default Puestos;
