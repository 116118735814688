const columns = {
    personalAdmin: [
        { label: 'No. Empleado', name: 'num_empleado', filtrar: true },
        { label: 'Puesto', name: 'nom_puesto', filtrar: true },
        { label: 'Sede', name: 'nom_sede', filtrar: true },
        { label: 'Nombre', name: 'nombre_persona', filtrar: true },
        // { label: 'direccion', name: 'direccion', filtrar:false },
        // { label: 'cp', name: 'cp', filtrar:false },
        // { label: 'colonia', name: 'colonia', filtrar:false },
        // { label: 'municipio', name: 'municipio', filtrar:false },
        // { label: 'estado', name: 'estado', filtrar:false },
        // { label: 'CURP', name: 'CURP', filtrar: true },
        { label: 'Correo', name: 'email_personal', filtrar: true },
        // { label: 'Correo',   name: 'email_empresarial', filtrar:false },
        // { label: 'jefe_inmediato', name: 'jefe_inmediato', filtrar:false },
        { label: 'Fecha ingreso', name: 'fecha_ingreso', type: { opcion: 'date', accion: 1 }, filtrar: true },
        { label: 'Fecha contrato', name: 'fecha_contrato', type: { opcion: 'date', accion: 1 }, filtrar: true },
        { label: 'Tipo puesto', name: 'tipo_puesto', filtrar: true }
    ],

    personalVent: [
        { label: 'No. Empleado', name: 'num_empleado', filtrar: true },
        { label: 'Jefe inmediato', name: 'jefe_inmediato', filtrar: true },
        { label: 'Puesto', name: 'nom_puesto', filtrar: true },
        { label: 'Sede', name: 'nom_sede', filtrar: true },
        { label: 'Nombre', name: 'nombre_persona', filtrar: true },
        // { label: 'direccion', name: 'direccion', filtrar:false },
        // { label: 'cp', name: 'cp', filtrar:false },
        // { label: 'colonia', name: 'colonia', filtrar:false },
        // { label: 'municipio', name: 'municipio', filtrar:false },
        // { label: 'estado', name: 'estado', filtrar:false },
        { label: 'Telefono', name: 'telefono1', filtrar: true },
        { label: 'Correo', name: 'email_personal', filtrar: true },
        // { label: 'Correo',   name: 'email_empresarial', filtrar:false },
        // { label: 'jefe_inmediato', name: 'jefe_inmediato', filtrar:false },
        { label: 'Fecha ingreso', name: 'fecha_ingreso', type: { opcion: 'date', accion: 1 }, filtrar: true },
        { label: 'Fecha contrato', name: 'fecha_contrato', type: { opcion: 'date', accion: 1 }, filtrar: true }
    ],
    colaboradores: [
        { label: 'No. Empleado', name: 'num_empleado', filtrar: true },
        { label: 'Departamento', name: 'nom_subarea', filtrar: true },
        { label: 'Área', name: 'nom_area', filtrar: true },
        { label: 'Puesto', name: 'nom_puesto', filtrar: true },
        { label: 'Plaza', name: 'nom_sede', filtrar: true },
        { label: 'Nombre', name: 'nombre_persona', filtrar: true }
        // {
        //     label: 'Nombre',
        //     name: 'nombre_persona',
        //     filtrar: true,
        //     type: (data) => {
        //         const res = data.nombre_persona.split('|');
        //         const nombrePersona = res[0];

        //         if (data.isTrainer == 0) return nombrePersona;

        //         return (
        //             <div style={{ width: '100px', display: 'flex', justifyContent: 'center' }}>
        //                 <School color="secondary" />
        //                 {nombrePersona}
        //             </div>
        //         );
        //     }
        // }
        // { label: 'Correo', name: 'email_empresarial', filtrar: true }
    ]
};

export default columns;
