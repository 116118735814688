import React, { useState, useEffect } from 'react';
import { School, Group } from '@mui/icons-material';
import { Card, CardContent, Grid, Typography, Tooltip, IconButton, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import SubCardCollapase from 'ui-component/cards/SubCardCollapase';
import { BigPlayButton, Player } from 'video-react';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import PersonIcon from '@mui/icons-material/Person';
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';

// ==============================|| DEFAULT DASHBOARD ||============================== //

const useStyles = makeStyles((theme) => ({
    curso: {
        backgroundColor: theme.palette.secondary[800]
    },
    grupo: {
        borderRadius: '8px',
        color: theme.palette.primary.light,
        backgroundColor: theme.palette.secondary[800]
    }
}));

const Contacto = ({ infoCotac }) => {
    const classes = useStyles();

    return (
        <Grid container spacing={5}>
            <Grid item xs={12}>
                <ul>
                    <span>
                        <h4>
                            <PersonIcon fontSize="small" /> Especialista de capacitación
                        </h4>
                    </span>
                    <span>
                        <h4>
                            <ForwardToInboxIcon fontSize="small" /> Email: {infoCotac ? infoCotac?.correo : '--'}
                        </h4>
                    </span>
                </ul>
            </Grid>
        </Grid>
    );
};

export default Contacto;
