import React, { useState } from 'react';

import { Star, Visibility, Check, Close, Percent } from '@mui/icons-material';
import { Grid, Typography, IconButton, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    infoUser: {
        borderRadius: '8px',
        backgroundColor: '#fff'
    },
    listItem: {
        backgroundColor: theme.palette.secondary.dark,
        color: '#fff',
        borderRadius: '12px',
        border: '1px solid #fff',
        padding: 10
    },
    listItemText: {
        color: '#fff'
    }
}));

export default function CardDetalleActividad({ data }) {
    const classes = useStyles();
    const detalleActividad = data?.detalleActividad;

    // Components
    const Icon = ({ icon, text, tooltip }) => (
        <Tooltip title={tooltip} disableInteractive>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                {icon}
                <Typography sx={{ display: 'inline', color: '#dddded', mt: 1, mr: 1 }} variant="h4">
                    {text}
                </Typography>
            </div>
        </Tooltip>
    );

    return (
        <>
            <Grid className={classes.listItem} container>
                <Grid item xs={12}>
                    <Grid container>
                        <Grid item xs={10}>
                            <Typography sx={{ display: 'inline', color: '#dddded' }} component="span" variant="h4">
                                <Star color="primary" /> {detalleActividad?.titulo}
                            </Typography>
                        </Grid>
                        <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'end' }}>
                            {detalleActividad.idCalificacionDetalle && [(1, 2)].includes(detalleActividad.tipo) ? (
                                <Icon icon={<Visibility color="primary" />} tooltip="Visto." />
                            ) : null}
                            {detalleActividad.idCalificacionDetalle && detalleActividad.tipo == 3 ? (
                                <>
                                    <Icon icon={<Percent color="primary" />} text={detalleActividad.calificacion} tooltip="Calificación." />
                                    <Icon icon={<Check color="primary" />} text={detalleActividad.acieros} tooltip="Aciertos." />
                                    <Icon icon={<Close color="primary" />} text={detalleActividad.desaciertos} tooltip="Desaciertos." />
                                </>
                            ) : null}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}
