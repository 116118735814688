import { ExpandMore, Star, Grade, ChevronRight, KeyboardArrowDown } from '@mui/icons-material';
import { Button, Collapse, Box, LinearProgress, List, Stack, Typography, Tooltip, Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@mui/styles';
import Scrollbars from 'react-custom-scrollbars';

import { ReactComponent as IconVideo } from 'assets/images/icons/c-icono-video.svg';
import { ReactComponent as IconLectura } from 'assets/images/icons/c-icono-ojo-abierto.svg';
import { ReactComponent as IconEvaluacion } from 'assets/images/icons/c-icono-evaluacion.svg';

const style = {
    boxCursos: {
        boxShadow: 0,
        borderRadius: 0,
        width: '100%',
        mt: 1
    }
};

const BorderLinearProgress = withStyles((theme) => ({
    root: {
        height: 6,
        borderRadius: 0
    },
    colorPrimary: {
        backgroundColor: theme.palette.dark[100]
    },
    bar: {
        borderRadius: 0,
        backgroundColor: theme.palette.success.dark
    }
}))(LinearProgress);

const ContentButton = (props) => {
    const { tipo, data, id, open, calificacion } = props;

    const color = data.porcentajeCompletado == 100 ? 'black' : 'black';
    const expandIcon = `${tipo}${id}` == open ? <KeyboardArrowDown color={color} /> : <ChevronRight color={color} />;

    return (
        <Grid container sx={{ backgroundColor: props.theme.palette.secondary.light }}>
            <Grid item xs={12} sx={{ backgroundColor: props.theme.palette.secondary.light }} display="flex" justifyContent="start">
                {expandIcon}
                <Typography variant="cardTemarioTitle">{data.titulo}</Typography>
            </Grid>
            <Grid item xs={12} sx={{ backgroundColor: props.theme.palette.background.default }}>
                <Stack sx={{ width: '100%', p: 1, pb: 0 }}>
                    <props.BorderLinearProgress
                        variant="determinate"
                        value={data.porcentajeCompletado}
                        // label={`${Math.round(data.porcentajeCompletado)}%`}
                    />
                    <Box sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                        <props.IconTooltip title="Progreso">
                            <Typography sx={{ ml: 0.5 }} variant="cardTemarioFooter">{`${Math.round(
                                data.porcentajeCompletado
                            )}%`}</Typography>
                        </props.IconTooltip>
                        <Grade style={{ ...props.theme.typography.iconDefault, margin: '0px 5px 0px 5px' }} color="warning" />
                        <props.IconTooltip title="Promedio">
                            <Typography variant="cardTemarioFooter">{calificacion.toFixed(2)}%</Typography>
                        </props.IconTooltip>
                    </Box>
                </Stack>
            </Grid>
        </Grid>
    );
};

const DetalleActividad = (props) => {
    const { detalleActividades, setBreadcrumbs } = props;
    return (
        <List>
            {detalleActividades.map((detalleActividad, key) => (
                <div key={`detalleActividad${detalleActividad.idDetalleActividad}`}>
                    <div>
                        <Button
                            sx={{
                                ...style.boxCursos,
                                display: 'flex',
                                justifyContent: 'start',
                                backgroundColor: props.theme.palette.secondary.light
                            }}
                            onClick={() => {
                                setBreadcrumbs(detalleActividad.breadcrumb);
                                props.handleDetalleActividad({
                                    ...detalleActividad,
                                    idActividad: props.idActividad,
                                    idTemario: props.idTemario,
                                    idSesion: props.idSesion,
                                    idCurso: props.idCurso,
                                    califAprobatoria: props.califAprobatoria
                                });
                            }}
                            color="inherit"
                            disabled={props.diableByDependencias(
                                detalleActividades
                                    .filter((actividad) => actividad.idCalificacionDetalle != null && actividad.isAprobado == 1)
                                    .map((act) => act.idDetalleActividad),
                                JSON.parse(detalleActividad.dependenciaDetalle)?.map((act) => act.idDetalleActividad)
                            )}
                        >
                            <props.IconTooltip title={detalleActividad.idCalificacionDetalle ? 'Concluida' : 'Sin responder'}>
                                <props.IconType
                                    type={detalleActividad.tipo}
                                    isAprobado={detalleActividad.isAprobado}
                                    style={{
                                        ...props.theme.typography.iconDefault,
                                        marginRight: '5px'
                                    }}
                                    {...props}
                                />
                            </props.IconTooltip>

                            <Typography>{detalleActividad.titulo}</Typography>
                        </Button>
                    </div>
                </div>
            ))}
        </List>
    );
};

const Actividad = (props) => {
    const { actividades, setBreadcrumbs } = props;
    return (
        <List sx={{ pl: 1, pr: 1 }}>
            {actividades.map((actividad) => (
                <div key={`actividades${actividad.idActividad}`}>
                    <div>
                        <Button
                            sx={{ ...style.boxCursos, backgroundColor: props.theme.palette.secondary.light }}
                            onClick={() => {
                                setBreadcrumbs(actividad.breadcrumb);
                                props.cambiarOpenA(`actividades${actividad.idActividad}`);
                            }}
                            color="inherit"
                            disabled={props.diableByDependencias(
                                actividades
                                    .filter((act) => act.idCalificacionActividad != null && act.isAprobado == 1)
                                    .map((act) => act.idActividad),
                                JSON.parse(actividad.dependenciaActividad)?.map((act) => act.idActividad)
                            )}
                        >
                            <ContentButton
                                tipo="actividades"
                                data={actividad}
                                id={actividad.idActividad}
                                calificacion={actividad.calificacion}
                                open={props.openA}
                                {...props}
                            />
                        </Button>
                        <Collapse in={`actividades${actividad.idActividad}` == props.openA} timeout="auto" unmountOnExit>
                            <DetalleActividad
                                detalleActividades={actividad.detalleActividades}
                                idActividad={actividad.idActividad}
                                {...props}
                            />
                        </Collapse>
                    </div>
                </div>
            ))}
        </List>
    );
};

const Temario = (props) => {
    const { temarios, setBreadcrumbs } = props;
    return (
        <List sx={{ pl: 1, pr: 1 }}>
            {temarios.map((temario) => (
                <div key={`temario${temario.idTemario}`}>
                    <div>
                        <Button
                            sx={{ ...style.boxCursos, backgroundColor: props.theme.palette.secondary.light }}
                            onClick={() => {
                                setBreadcrumbs(temario.breadcrumb);
                                props.cambiarOpenT(`temario${temario.idTemario}`);
                            }}
                            color="inherit"
                            disabled={props.diableByDependencias(
                                temarios
                                    .filter((tem) => tem.idCalificacionTemario != null && tem.isAprobado == 1)
                                    .map((tem) => tem.idTemario),
                                JSON.parse(temario.dependenciaTemario)?.map((tem) => tem.idTemario)
                            )}
                        >
                            <ContentButton
                                tipo="temario"
                                data={temario}
                                id={temario.idTemario}
                                calificacion={temario.calificacion}
                                open={props.openT}
                                {...props}
                            />
                        </Button>
                        <Collapse in={`temario${temario.idTemario}` == props.openT} timeout="auto" unmountOnExit>
                            <Actividad actividades={temario.actividades} idTemario={temario.idTemario} {...props} />
                        </Collapse>
                    </div>
                </div>
            ))}
        </List>
    );
};

const Sesion = (props) => {
    const { sesiones, setBreadcrumbs } = props;
    return (
        <List sx={{ backgroundColor: props.theme.palette.background.default, p: 0 }}>
            {sesiones.map((sesion) => (
                <div key={`sesion${sesion.idSesion}`}>
                    <div>
                        <Button
                            sx={{ ...style.boxCursos, backgroundColor: props.theme.palette.secondary.light, m: 0 }}
                            onClick={() => {
                                setBreadcrumbs(sesion.breadcrumb);
                                props.cambiarOpenS(`sesion${sesion.idSesion}`);
                            }}
                            color="inherit"
                            disabled={props.diableByDependencias(
                                sesiones
                                    .filter((ses) => ses.idCalificacionSesion != null && ses.isAprobado == 1)
                                    .map((ses) => ses.idSesion),
                                JSON.parse(sesion.dependenciaSesion)?.map((ses) => ses.idSesion)
                            )}
                        >
                            <ContentButton
                                tipo="sesion"
                                data={sesion}
                                id={sesion.idSesion}
                                calificacion={sesion.calificacion}
                                open={props.openS}
                                {...props}
                            />
                        </Button>
                        <Collapse in={`sesion${sesion.idSesion}` == props.openS} timeout="auto" unmountOnExit>
                            <Temario temarios={sesion.temarios} idSesion={sesion.idSesion} {...props} />
                        </Collapse>
                    </div>
                </div>
            ))}
        </List>
    );
};

// const Curso = (props) => {
//     const { cursos } = props;
//     return (
//         <List>
//             {cursos && props.ctl.cursos.length > 0
//                 ? props.ctl.cursos.map((curso) => (
//                       <div key={curso.titulo}>
//                           <Button sx={style.boxCursos} onClick={() => props.cambiarOpenP(curso.idCurso)} color="inherit">
//                               <ContentButton tipo="curso" data={curso} id={curso.idCurso} open={props.open} {...props} />
//                           </Button>
//                           <Collapse in={curso.idCurso == props.open} timeout="auto" unmountOnExit>
//                               <Sesion sesiones={curso.sesiones} {...props} />
//                           </Collapse>
//                       </div>
//                   ))
//                 : 'No cuenta con cursos activos'}
//         </List>
//     );
// };

const TemarioC = (props) => {
    const { isLoading, ctl, onclick, setBreadcrumbs, curso } = props;

    const theme = useTheme();

    const handleDetalleActividad = (id) => {
        onclick(id);
    };

    // Functions
    const diableByDependencias = (arrActividadesRespondidas, arrDependencias) => {
        if (arrDependencias == undefined) return false;
        const arrEmpate = arrActividadesRespondidas.filter((id) => arrDependencias.includes(id));
        // Checamos si las actividades respondidas son las mismas de dependencia
        if (arrEmpate.length >= arrDependencias.length) return false;

        return true;
    };

    // Components
    const IconTooltip = ({ title, children }) => (
        <Tooltip title={title} disableInteractive>
            {children}
        </Tooltip>
    );
    const IconType = (props) => {
        const { type, isAprobado, style } = props;
        const color = () => {
            if (isAprobado == 1) return props.theme.palette.success.main;
            if (isAprobado == 0) return props.theme.palette.error.main;
            if (isAprobado == null) return props.theme.palette.dark.main;
            return 'white';
        };
        const modifyStyle = { ...style, fill: color() };
        let icon = null;
        switch (type) {
            case '1':
                icon = <IconLectura style={modifyStyle} />;
                break;
            case '2':
                icon = <IconVideo style={modifyStyle} />;
                break;
            case '3':
                icon = <IconEvaluacion style={modifyStyle} />;
                break;
            default:
                icon = null;
                break;
        }
        return icon;
    };

    const propsTemarios = {
        theme: theme,
        idCurso: curso.idCurso,
        califAprobatoria: curso.califAprobatoria,
        sesiones: curso.sesiones,
        handleDetalleActividad: handleDetalleActividad,
        diableByDependencias: diableByDependencias,
        IconTooltip: IconTooltip,
        IconType: IconType,
        BorderLinearProgress: BorderLinearProgress,
        ContentButton: ContentButton,
        setBreadcrumbs: setBreadcrumbs,
        ctl: ctl,
        ...props
    };

    return (
        <>
            <Scrollbars style={{ height: '70vh' }}>
                {/* <Curso /> */}
                <Sesion {...propsTemarios} />
            </Scrollbars>
        </>
    );
};

TemarioC.propTypes = {
    isLoading: PropTypes.bool
};

export default TemarioC;
