import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { colors } from '@mui/material';
import { Article } from '@mui/icons-material';

import { Tabla, ModalGeneral } from 'ui-component';
import MainCard from 'ui-component/cards/MainCard';
import Services from './Components/services';
import headers from './Components/headers';
import localUtils from './Components/utils';
import Materiales from './Components/Materiales';
import AsignarMaterial from './Components/AsignarMaterial';
import FiltrarByDia from './Components/FiltrarByDia';

const useStyles = makeStyles((theme) => ({
    paper: {
        textAlign: 'center'
    },
    cancelar: {
        color: colors.red[500]
    },
    aceptar: {
        color: colors.green[500]
    },
    texto: {
        color: colors.indigo[900],
        textAlign: 'center',
        fontSize: 13
    },
    textoPequeno: {
        color: colors.indigo[900],
        textAlign: 'center',
        fontSize: 9
    },
    textoTabla: {
        color: colors.indigo[900],
        textAlign: 'left',
        fontSize: 9
    },
    textoTablaTH: {
        color: colors.indigo[900],
        textAlign: 'left',
        fontSize: 11
    },
    cantidadTabla: {
        color: colors.indigo[900],
        textAlign: 'right',
        fontSize: 9
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200
    }
}));

const PersonalAdmin = ({ ...props }) => {
    const classes = useStyles();
    const services = Services();
    const [day, setDay] = useState({ day: 'Todos' });
    const [confirm, setConfirm] = useState({ open: false });
    const [modal, setModal] = useState({ open: false, titulo: '' });

    const [personal, setPersonal] = useState({ personal: [], allData: [] });

    // Functions
    const cerrarModal = () => setModal({ open: false });

    const changeCurrentDay = () => {
        setDay({ day: localUtils.getCurrentDate('Admin').day });
    };

    const filterForDay = (day, allData) => {
        if (day == 'Todos') {
            setPersonal({ personal: allData, allData: allData });
            return false;
        }

        const daysToFilter = localUtils.getDiaXDelMes(day);

        const dataFiltered = allData.filter((per) => daysToFilter.days.includes(per.fecha_ingreso));

        setPersonal({ ...personal, personal: dataFiltered });
        return true;
    };

    const abrirMateriales = () => () => {
        setModal({ open: true, titulo: 'Materiales', contenido: Materiales, props: {} });
    };

    const abrirAsignarMateriales = (persona) => {
        setModal({
            open: true,
            titulo: 'Material asignado a ' + persona.nombre_persona,
            contenido: AsignarMaterial,
            props: { idpersona: persona.idpersona, idpuesto: persona.idpuesto }
        });
    };

    // Services
    const getInfoInicialAdmin = async () => {
        await services.getInfoInicialAdmin((res) => filterForDay(day.day, res.personal));
        changeCurrentDay();
    };

    // Handle
    const handleChangeDay = (event) => {
        setDay({ [event.target.name]: event.target.value });
    };

    const cargarFunciones = () => {
        getInfoInicialAdmin();
    };

    useEffect(() => {
        cargarFunciones();
    }, []);

    useEffect(() => {
        filterForDay(day.day, personal.allData);
    }, [day]);

    // Config
    const acciones = [
        { component: FiltrarByDia, props: { value: day.day, onChange: handleChangeDay, estatus: 0 } },
        { label: 'Materiales', icon: <Article />, click: abrirMateriales, disabled: false /*!permisos.crear*/ }
    ];
    const accionesFila = [
        //{ label: 'Editar', onClick: (row) => {}, enabled: true },
        //{ label: 'Eliminar', onClick: (row) => {}, enabled: true },
        {
            label: 'Material Asignado',
            onClick: (row) => {
                abrirAsignarMateriales(row);
            },
            enabled: true
        }
    ];

    return (
        <MainCard title="Introducción Administrativa">
            <Tabla
                rows={personal.personal}
                accionesTabla={acciones}
                columns={headers.personalAdmin}
                columnKey="idpersona"
                accionesFila={accionesFila}
            />
            <ModalGeneral open={modal.open} width="md" titulo={modal.titulo} OnCerrar={cerrarModal}>
                {modal.contenido && <modal.contenido {...modal.props} />}
            </ModalGeneral>
        </MainCard>
    );
};

export default PersonalAdmin;
