import React, { useState, useEffect, useRef } from 'react';
import {
    Typography,
    Switch,
    DialogContent,
    DialogContentText,
    Grid,
    Divider,
    Tooltip,
    Slide,
    Button,
    IconButton,
    DialogActions,
    DialogTitle
} from '@mui/material';
import { Close, Forward } from '@mui/icons-material';
import { useFormContext } from 'react-hook-form';

import { Tabla } from 'ui-component';
import headers from './headers';
import ServiciosCatalogo from 'utils/serviciosCatalogo';
import AvisoConfirmacion from 'ui-component/AvisoConfirmacion';
import { styled, useTheme } from '@mui/material/styles';

const ModalRolesPagina = ({ open, isAdd, data, ctl, titulo, onClose, setCargando, setMensaje, handleClose, ...props }) => {
    // const {
    //     register,
    //     setValue,
    //     formState: { errors },
    //     control
    // } = useFormContext();

    const theme = useTheme();
    const serviciosCatalogo = ServiciosCatalogo();

    const [rols, setRols] = useState({ list: [], all: [] });
    const [rolsAdd, setRolsAdd] = useState([]);
    const [confirm, setConfirm] = useState({ open: false });

    const asignarRoles = (dataSelected, rols, rolsAdd) => {
        const idsSelected = dataSelected.map((data) => data.idrol);
        const newDataRols = rols.filter((data) => !idsSelected.includes(data.idrol));
        setRols({ ...rols, list: newDataRols });
        setRolsAdd([...rolsAdd, ...dataSelected]);
    };
    const quitarRoles = (dataSelected) => {
        const idsSelected = dataSelected.map((data) => data.idrol);
        const newDataRolsAdd = rolsAdd.filter((data) => !idsSelected.includes(data.idrol));
        setRolsAdd(newDataRolsAdd);
        setRols({ ...rols, list: [...rols.list, ...dataSelected] });
    };

    const cambiarEstatusRol = (rol, status) => {
        serviciosCatalogo.cambioEstatusRol(
            (nuevaData) => {
                data?.setRols({ list: nuevaData.roles, all: nuevaData.roles });
                asignarRoles(rolsAdd, nuevaData.roles, []);
                setConfirm({ open: false });
            },
            { idrol: rol.idrol, idestatus: status }
        );
    };
    const cambiarEstatusPermisoPagina = (rol, status) => {
        serviciosCatalogo.cambioEstatusPermisoPagina(
            (nuevaData) => {
                setRolsAdd(nuevaData.rolesPagina);
                setConfirm({ open: false });
            },
            { idrol: rol.idrol, idpagina: rol.idpagina, idestatus: status }
        );
    };
    const abrirConfirmarCambio = (rol, idestatus, funcion) => {
        let texto = `¿Estás seguro de volver a activar el rol ${rol.nrol}?`;
        let status = 1;
        if (idestatus === '1') {
            texto = `¿Estás seguro de desactivar el rol ${rol.nrol}?`;
            status = 0;
        }
        setConfirm({
            open: true,
            text: texto,
            aceptar: () => funcion(rol, status)
        });
    };
    const agregarPermisos = () => {
        const rolesNuevos = rolsAdd.filter((rol) => !rol.estatuspermiso);
        if (rolesNuevos.length <= 0) {
            return alert('No hay roles nuevos para agregar');
        }
        return setConfirm({
            open: true,
            text: 'Se registrarán los permisos seleccionados, ¿desea continuar?',
            aceptar: () =>
                serviciosCatalogo.addRolesPagina(
                    (res) => {
                        onClose();
                    },
                    { idpagina: data.idpagina, roles: rolesNuevos }
                )
        });
    };

    const ComponentEstatus = (row) => {
        if (row.estatuspermiso) {
            return (
                <Switch
                    checked={row.estatuspermiso === '1'}
                    onChange={() => abrirConfirmarCambio(row, row.estatuspermiso, cambiarEstatusPermisoPagina)}
                />
            );
        }
        return (
            <Tooltip title="Quitar de la lista">
                <IconButton onClick={() => quitarRoles([row])}>
                    <Close color="error" />
                </IconButton>
            </Tooltip>
        );
    };

    useEffect(() => {
        setRols(data?.rols);
        serviciosCatalogo.getRolesPagina(
            (rolesPagina) => {
                asignarRoles(rolesPagina, data?.rols.list, []);
            },
            { idpagina: data?.idpagina }
        );
    }, []);

    const accionesCatRoles = [
        { label: 'Asignar', icon: <Forward />, click: (selected) => (e) => asignarRoles(selected, rols.list, rolsAdd), dataSelected: true }
    ];
    const accionesRolesAsig = [
        { label: 'Quitar roles', icon: <></>, click: (selected) => (e) => quitarRoles(selected), dataSelected: true }
    ];

    return (
        <>
            <DialogTitle id="form-dialog-title">{titulo}</DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Typography variant="subTitle">Roles existentes</Typography>
                        <Tabla
                            rows={rols.list}
                            columns={headers.roles((row) => (e) => abrirConfirmarCambio(row, row.idestatus, cambiarEstatusRol))}
                            accionesTabla={accionesCatRoles}
                            accionesFila={[]}
                            isSeleccionable
                            filterGeneralDisabled
                            columnKey="idrol"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="subTitle">{`Roles de la página '${data?.npagina}'`}</Typography>
                        <Tabla
                            rows={rolsAdd}
                            columns={headers.rolesAdd(ComponentEstatus)}
                            accionesTabla={accionesRolesAsig}
                            accionesFila={[]}
                            filterGeneralDisabled
                            columnKey="idrol"
                        />
                    </Grid>
                </Grid>
                <AvisoConfirmacion
                    open={confirm.open}
                    title="Advertencia"
                    text={confirm.text}
                    accept={confirm.aceptar}
                    cancel={() => setConfirm({ open: false })}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="error">
                    Cancelar
                </Button>
                <Button onClick={agregarPermisos} color="success">
                    Aceptar
                </Button>
            </DialogActions>
        </>
    );
};

export default ModalRolesPagina;
