import React from 'react';
import { Grid, TextField } from '@mui/material';
import { useFormContext } from 'react-hook-form';

import { TextFieldNumber } from 'ui-component';

const FormTemario = ({ open, isAdd, data, ctl, setCargando, setMensaje, handleClose, ...props }) => {
    const {
        register,
        watch,
        formState: { errors },
        control
    } = useFormContext();
    return (
        <Grid container spacing={2}>
            <Grid item xs={6} md={4}>
                <TextFieldNumber
                    label="Orden de tema"
                    name="numTemario"
                    error={errors?.numTemario?.message}
                    helperText={errors.numTemario?.message}
                    defaultValue={Number(data?.orden)}
                    control={control}
                    rules={{ required: { value: true, message: 'Campo requerido' } }}
                />
            </Grid>
            <Grid item xs={8}>
                <TextField
                    label="Título *"
                    size="small"
                    fullWidth
                    {...register('titulo', {
                        required: { value: true, message: 'Campo requerido' },
                        validate: (value) => value.match(/(^\s+|\s+$)/g) == null || 'El inicio o el final no pueden ser espacios'
                    })}
                    error={Boolean(errors.titulo)}
                    helperText={errors.titulo?.message}
                    defaultValue={data?.titulo}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    label="Descripción *"
                    size="small"
                    fullWidth
                    {...register('descripcion', {
                        required: { value: true, message: 'Campo requerido' },
                        validate: (value) => value.match(/(^\s+|\s+$)/g) == null || 'El inicio o el final no pueden ser espacios'
                    })}
                    error={Boolean(errors.descripcion)}
                    helperText={errors.descripcion?.message}
                    defaultValue={data?.descripcion}
                    multiline
                />
            </Grid>
        </Grid>
    );
};

export default FormTemario;
