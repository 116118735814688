import React, { useState, useEffect } from 'react';
import { Grid, colors, Typography, Button } from '@mui/material';
import { CloudDownload } from '@mui/icons-material';
import { useFormContext } from 'react-hook-form';
import Services from '../services';
import { makeStyles } from '@mui/styles';

import TextFieldAutoComplete from 'ui-component/TextFieldAutoComplete';

const useStyles = makeStyles((theme) => ({
    helperTextError: {
        color: colors.red[500],
        marginLeft: '14px',
        marginRight: '14px',
        marginTop: '4px',
        fontSize: '0.75rem',
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif'
    },
    helperText: {
        color: 'black',
        marginLeft: '0px',
        marginRight: '14px',
        marginTop: '4px',
        fontSize: '1rem',
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif'
    },
    helperText2: {
        color: colors.grey[500],
        marginLeft: '14px',
        marginRight: '14px',
        marginTop: '4px',
        fontSize: '0.75rem',
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif'
    }
}));

const FormAsignarMaterial = (props) => {
    const classes = useStyles();
    const { data, idpuesto } = props;
    const services = Services();
    const [materiales, setMateriales] = useState([]);
    const [materialSeleccionado, setMaterialSeleccionado] = useState(null);
    const {
        register,
        watch,
        formState: { errors },
        control,
        setValue,
        getValues
    } = useFormContext();

    const cambioMaterial = (material) => {
        setValue('material', material);
        setMaterialSeleccionado(material);
    };

    const getMaterialesDisponibles = async () => {
        await services.getMaterialesDisponibles(
            (res) => {
                let listaMateriales = [];
                data?.forEach((material) => {
                    listaMateriales.push(material.idmaterial);
                }) || [];
                let materialDisponible = res.materiales.filter((material) => !listaMateriales.includes(material.idmaterial));
                setMateriales(materialDisponible);
            },
            { idpuesto: idpuesto }
        );
    };

    const cargarFunciones = () => {
        getMaterialesDisponibles();
    };

    useEffect(() => {
        cargarFunciones();
    }, []);

    return (
        <Grid container spacing={2}>
            <Grid item lg={12} md={12} xs={12}>
                <TextFieldAutoComplete
                    label="Material disponible para asignación *"
                    control={control}
                    name="material"
                    {...register('material')}
                    rules={{ required: { value: true, message: 'Campo requerido' } }}
                    options={materiales || []}
                    optionSelected={{ label: 'nmaterial', id: 'idmaterial' }}
                    error={errors.material?.message}
                    onChange={cambioMaterial}
                />
            </Grid>
            <Grid item lg={12} md={12} xs={12}>
                {materialSeleccionado && (
                    <>
                        <Typography style={{ fontWeight: 'bold' }}>Nombre del material: </Typography>
                        <Typography>{materialSeleccionado.nmaterial}</Typography>
                        <br></br>
                        <Typography style={{ fontWeight: 'bold' }}>Departamento: </Typography>
                        <Typography>{materialSeleccionado.ndepartamento}</Typography>
                        <br></br>
                        <Typography style={{ fontWeight: 'bold' }}>Área: </Typography>
                        <Typography>{materialSeleccionado.narea}</Typography>
                        <br></br>
                        <Typography style={{ fontWeight: 'bold' }}>Puesto: </Typography>
                        <Typography>{materialSeleccionado.npuesto}</Typography>
                        <br></br>
                        <Typography style={{ fontWeight: 'bold' }}>Requiere respuesta: </Typography>
                        <Typography>{Boolean(materialSeleccionado.requiere_respuesta) ? 'Sí' : 'No'}</Typography>
                        <br></br>
                        <Button
                            component="label"
                            role={undefined}
                            variant="contained"
                            tabIndex={-1}
                            startIcon={<CloudDownload />}
                            color="primary"
                            onClick={() => window.open(materialSeleccionado?.ruta, '_blank', 'noreferrer')}
                        >
                            Ver archivo
                        </Button>
                    </>
                )}
            </Grid>
        </Grid>
    );
};

export default FormAsignarMaterial;
