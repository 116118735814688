import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@mui/styles';
import { Container, Dialog, colors, Table, TableBody, TableRow, TableCell, Typography } from '@mui/material';
import { AddBox, DeleteForever, GetApp } from '@mui/icons-material';

import { Tabla } from 'ui-component';
import headers from './Components/headers';
import ModalAddEditPagina from './Components/ModalAddEditPagina';
import ModalRolesPagina from './Components/ModalRolesPagina';
import ServiciosCatalogo from 'utils/serviciosCatalogo';
import MainCard from 'ui-component/cards/MainCard';
import LayautContext from 'Context/ContextLayaut';
import AvisoConfirmacion from 'ui-component/AvisoConfirmacion';

const Pagina = ({ permisos }) => {
    //
    const context = useContext(LayautContext);
    const serviciosCatalogo = ServiciosCatalogo();

    // States
    const [paginas, setPaginas] = useState([]);
    const [rols, setRols] = useState({ list: [], all: [] });
    const [ctl, setCtl] = useState({ puesto: [] });
    const [dialog, setDialog] = useState({ open: false, propsForm: { data: {} }, onClose: () => {}, width: 'lg' });
    const [confirm, setConfirm] = useState({ open: false });

    // Handle
    const closeDialog = () => {
        setDialog({ open: false, onClose: () => {}, width: 'lg' });
    };
    const abrirNuevaPagina = () => () => {
        context.formulario.abrir({
            open: true,
            width: 'md',
            titulo: 'Pagina',
            propsForm: { data: null, isAdd: true, ctl: ctl },
            formulario: ModalAddEditPagina,
            aceptar: (dataForm) => {
                serviciosCatalogo.addPagina((data) => {
                    setPaginas(data.paginas || []);
                }, dataForm);
                context.formulario.cerrar();
            }
        });
    };
    const abrirEditarPagina = (row) => {
        context.formulario.abrir({
            open: true,
            titulo: 'Página',
            propsForm: { data: row, ctl: ctl },
            formulario: ModalAddEditPagina,
            aceptar: (dataForm) => {
                serviciosCatalogo.editPagina(
                    (nuevaData) => {
                        setPaginas(nuevaData.paginas || []);
                        context.formulario.cerrar();
                    },
                    { idpagina: row.idpagina, ...dataForm }
                );
            }
        });
    };
    const rolesPagina = (row) => {
        setDialog({
            open: true,
            width: 'lg',
            propsForm: { data: { ...row, rols, setRols }, titulo: `Roles de acceso para la página '${row.npagina}'`, ctl: ctl },
            formulario: ModalRolesPagina,
            onClose: closeDialog
        });
        // context.formulario.abrir({
        //     open: true,
        //     width: 'lg',
        //     titulo: `Roles de acceso para la página '${row.npagina}'`,
        //     propsForm: { data: { ...row, rols, setRols }, ctl: ctl },
        //     formulario: ModalRolesPagina,
        //     aceptar: (dataForm) => {
        //         serviciosCatalogo.addRolesPagina(
        //             (nuevaData) => {
        //                 setPaginas(nuevaData.paginas || []);
        //                 context.formulario.cerrar();
        //             },
        //             { idpagina: row.idpagina, ...dataForm }
        //         );
        //     }
        // });
    };

    const abrirConfirmarCambio = (pagina) => (e) => {
        let texto = `¿Estás seguro de volver a activar la pagina ${pagina.npagina}?`;
        let status = 1;
        if (pagina.idestatus === '1') {
            texto = `¿Estás seguro de desactivar la pagina ${pagina.npagina}?`;
            status = 0;
        }
        setConfirm({
            open: true,
            text: texto,
            aceptar: () =>
                serviciosCatalogo.cambiarEstatusPagina(
                    (nuevaData) => {
                        setPaginas(nuevaData.paginas);
                        setConfirm({ open: false });
                    },
                    { idpagina: pagina.idpagina, idestatus: status }
                )
        });
    };

    // Config
    const acciones = [{ label: 'Nuevo usuario', icon: <AddBox />, click: abrirNuevaPagina }];
    const accionesFila = [
        { label: 'Roles', onClick: rolesPagina, enabled: true },
        { label: 'Editar', onClick: abrirEditarPagina, enabled: true }
    ];
    useEffect(() => {
        serviciosCatalogo.getPaginas((data) => {
            setPaginas(data.paginas || []);
            setRols({ list: data.roles, all: data.roles } || rols);
        });
    }, []);
    return (
        <MainCard title="Páginas">
            <Tabla
                rows={paginas}
                columns={headers.pagina(abrirConfirmarCambio)}
                accionesTabla={acciones}
                accionesFila={accionesFila}
                columnKey="idpagina"
            />
            <AvisoConfirmacion
                open={confirm.open}
                title="Advertencia"
                text={confirm.text}
                accept={confirm.aceptar}
                cancel={() => setConfirm({ open: false })}
            />
            {dialog.open && (
                <Dialog
                    open={dialog.open}
                    onClose={dialog.onClose}
                    scroll="body"
                    fullWidth
                    maxWidth={dialog.width}
                    aria-labelledby="form-dialog-title"
                >
                    <dialog.formulario {...dialog.propsForm} onClose={dialog.onClose} />
                </Dialog>
            )}
        </MainCard>
    );
};

export default Pagina;
