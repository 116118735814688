import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@mui/styles';
import { Container, Box, colors, Table, TableBody, TableRow, TableCell, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import { AddBox, DeleteForever, GetApp } from '@mui/icons-material';
import PropTypes from 'prop-types';

import { Tabla } from 'ui-component';
import MainCard from 'ui-component/cards/MainCard';
import Services from './Components/services';
import headers from './Components/headers';

const useStyles = makeStyles((theme) => ({
    paper: {
        textAlign: 'center'
    },
    cancelar: {
        color: colors.red[500]
    },
    aceptar: {
        color: colors.green[500]
    },
    texto: {
        color: colors.indigo[900],
        textAlign: 'center',
        fontSize: 13
    },
    textoPequeno: {
        color: colors.indigo[900],
        textAlign: 'center',
        fontSize: 9
    },
    textoTabla: {
        color: colors.indigo[900],
        textAlign: 'left',
        fontSize: 9
    },
    textoTablaTH: {
        color: colors.indigo[900],
        textAlign: 'left',
        fontSize: 11
    },
    cantidadTabla: {
        color: colors.indigo[900],
        textAlign: 'right',
        fontSize: 9
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200
    }
}));

const PersonalOper = ({ ...props }) => {
    const classes = useStyles();
    const services = Services();
    const { register, handleSubmit, watch, errors } = useForm();

    // States
    const [cargando, setCargando] = useState(false);
    const [mensaje, setMensaje] = useState({ open: false });
    const [confirm, setConfirm] = useState({ open: false });

    const [personal, setPersonal] = useState([]);

    // Services
    const getInfoInicialOper = async () => {
        await services.getInfoInicialOper((res) => setPersonal(res.personal));
    };

    // Functions
    const cargarFunciones = () => {
        getInfoInicialOper();
    };

    // Handle
    useEffect(() => {
        cargarFunciones();
    }, []);

    // Config
    const acciones = [{ label: 'Nuevo usuario', icon: <AddBox />, click: (data) => (even) => {} }];
    const accionesFila = [
        { label: 'Editar', onClick: (row) => {}, enabled: true },
        { label: 'Eliminar', onClick: (row) => {}, enabled: true }
    ];

    return (
        <MainCard title="Introducción Operativa">
            <Tabla rows={personal} columns={headers.personalAdmin} columnKey="sucursal" />
        </MainCard>
    );
};

export default PersonalOper;
