function FechaHoraMX(date, tipo) {
    /* tipo 1 = fecha, 2=fecha hora, 3=Hoy, 4 FECHA HORA 24 5=d/m/a hora */
    const meses = ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'];
    const d = tipo != 3 ? new Date(date) : new Date();
    let month = d.getMonth() + 1;
    let day = d.getDate() + 1;
    const year = d.getFullYear();
    if (month.length < 2) month = `0${month}`;
    if (day.length < 2) day = `0${day}`;
    if (tipo == 1 || tipo == 3) return [day, meses[Number(month) - 1], year].join('-');
    if (tipo == 2) {
        let hours = d.getHours();
        let minutes = d.getMinutes();
        const ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12 ? hours % 12 : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? `0${minutes}` : minutes;
        return `${[day, meses[Number(month) - 1], year].join('-')} ${hours.toString().padStart(2, '0')}:${minutes} ${ampm}`;
    }
    if (tipo == 4) {
        const hours = d.getHours().toString().padStart(2, '0');
        const minutes = d.getMinutes().toString().padStart(2, '0');
        return `${[day, meses[Number(month) - 1], year].join('-')} ${hours}:${minutes}`;
    }
    if (tipo == 5) {
        const hours = (d.getHours() % 12 || 12).toString().padStart(2, '0'); // the hour '0' should be '12';
        const minutes = d.getMinutes().toString().padStart(2, '0');
        const ampm = d.getHours() ? 'PM' : 'AM';
        return `${[day, month, year].join('-')} ${hours}:${minutes} ${ampm}`;
    }
}

export default FechaHoraMX;
