const getCurrentDate = (type) => {
    const d = new Date();
    const nomDay = ['Domingo', 'Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sabado'];
    const fixDayMonth = (day) => (day < 10 ? `0${day}` : day);
    let dayToRadio = '';

    let year = d.getFullYear();
    let numMonth = d.getMonth();
    let dayOfMonth = d.getDate();
    const numDay = d.getDay();

    // Get day to Radio filter
    switch (type) {
        case 'Admin':
            dayToRadio = numDay > 2 ? 3 : 1;
            break;
        case 'Vent':
            dayToRadio = numDay > 3 ? 4 : 2;
            break;
        default:
            break;
    }

    const day = nomDay[dayToRadio];

    // Get current day
    const currentDay = `${year}-${fixDayMonth(numMonth + 1)}-${fixDayMonth(dayOfMonth)}`;

    // Get range of date
    const getLastDay = new Date(d.getFullYear(), d.getMonth() + 1, 0);
    year = getLastDay.getFullYear();
    numMonth = getLastDay.getMonth();
    dayOfMonth = getLastDay.getDate();
    const hasta = `${year}-${fixDayMonth(numMonth + 1)}-${fixDayMonth(dayOfMonth)}`;

    d.setDate(d.getDate() - 7);
    year = d.getFullYear();
    numMonth = d.getMonth();
    dayOfMonth = d.getDate();
    const desde = `${year}-${fixDayMonth(numMonth + 1)}-${fixDayMonth(dayOfMonth)}`;

    const res = { day: day, currentDay: currentDay, desde: desde, hasta: hasta };

    return res;
};

function getDiaXDelMes(nomDia) {
    if (nomDia == 'Todos') {
        return false;
    }

    const d = new Date();
    const month = d.getMonth();
    const selectDays = [];

    const objDias = { Domingo: 0, Lunes: 1, Martes: 2, Miercoles: 3, Jueves: 4, Viernes: 5, Sabado: 6 };

    const selectDay = objDias[nomDia];
    const fixDayMonth = (day) => (day < 10 ? `0${day}` : day);

    // Obtener el ultimo dia del mes anterior
    d.setMonth(d.getMonth() - 1);
    const getLastDay = new Date(d.getFullYear(), d.getMonth() + 1, 0);
    d.setDate(getLastDay.getDate());

    for (let index = 0; index < 7; index += 1) {
        if (d.getDay() == selectDay) {
            const year = d.getFullYear();
            const numMonth = d.getMonth();
            const dayOfMonth = d.getDate();

            const date = `${year}-${fixDayMonth(numMonth + 1)}-${fixDayMonth(dayOfMonth)}`;
            selectDays.push(date);
            break;
        }
        d.setDate(d.getDate() - 1);
    }

    d.setDate(1);
    d.setMonth(d.getMonth() + 1);
    // Obtener el primer dia del mes actual
    while (d.getDay() !== selectDay) {
        d.setDate(d.getDate() + 1);
    }

    // Obtener todos los dias seleccionados del mes
    while (d.getMonth() === month) {
        const year = d.getFullYear();
        const numMonth = d.getMonth();
        const dayOfMonth = d.getDate();

        const date = `${year}-${fixDayMonth(numMonth + 1)}-${fixDayMonth(dayOfMonth)}`;
        selectDays.push(date);
        d.setDate(d.getDate() + 7);
    }

    return { days: selectDays };
}

const utils = {
    getCurrentDate: getCurrentDate,
    getDiaXDelMes: getDiaXDelMes
};

export default utils;
