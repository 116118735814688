import React from 'react';
import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';

const FiltrarByDia = ({ value, onChange, estatus }) => (
    <FormControl component="fieldset">
        <RadioGroup aria-label="gender" name="day" value={value} onChange={onChange} row>
            {estatus == 1 ? (
                <FormControlLabel value="Martes" control={<Radio />} label="Martes" />
            ) : (
                <FormControlLabel value="Lunes" control={<Radio />} label="Lunes" />
            )}
            {estatus == 1 ? (
                <FormControlLabel value="Jueves" control={<Radio />} label="Jueves" />
            ) : (
                <FormControlLabel value="Miercoles" control={<Radio />} label="Miercoles" />
            )}
            <FormControlLabel value="Todos" control={<Radio />} label="Todos" />
        </RadioGroup>
    </FormControl>
);
export default FiltrarByDia;
