import React, { useEffect, useState, useRef } from 'react';
// material-ui
import PropTypes from 'prop-types';
import { styled, useTheme } from '@mui/material/styles';

import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import { Scrollbars } from 'react-custom-scrollbars';

// project imports
import Services from 'utils/serviciosVentas';
import banner from 'assets/images/Banner_UM.png';

// ==============================|| DEFAULT DASHBOARD ||============================== //

const BannerComponent = (props) => {
    const { permiso, usuario, cursos, banners } = props;

    const services = Services();
    const theme = useTheme();

    return (
        <Carousel autoPlay dynamicHeight emulateTouch infiniteLoop showStatus={false} showIndicators showThumbs={false}>
            {banners?.map((banner) => (
                <div>
                    <img src={banner?.linkDrop} alt="Hola" />
                </div>
            ))}
        </Carousel>
    );
};

export default BannerComponent;
