const columns = (cambioEstatus) => ({
    encuesta: [
        { label: 'Encuesta', name: 'nombre', filtrar: true },
        { label: 'Modificación', name: 'fechaModificacion', type: { opcion: 'date', accion: 4 }, filtrar: true },
        {
            label: 'Estatus',
            name: 'estatus',
            filtrar: false,
            type: { opcion: 'switch', accion: cambioEstatus }
        }
    ]
});

export default columns;
