import React, { useState, useEffect, useRef, useContext } from 'react';
import { makeStyles } from '@mui/styles';
import { Container, Box, colors, Table, TableBody, TableRow, TableCell, Typography, Paper, Button } from '@mui/material';

import { AddBox, DeleteForever, GetApp } from '@mui/icons-material';

import { Tabla, ModalGeneral } from 'ui-component';

import ServiciosUsers from 'utils/ServiciosUser';
import { FormPermisosRelacion, FormRol } from './Components';
import MainCard from 'ui-component/cards/MainCard';
import LayautContext from 'Context/ContextLayaut';
import ServiciosCatalogo from 'utils/serviciosCatalogo';

const Roles = ({ permisos }) => {
    const context = useContext(LayautContext);
    const serviciosCatalogo = ServiciosCatalogo();

    const [roles, setRoles] = useState([]);
    const [ctl, setCtl] = useState({ pagina: [] });
    const [modalPersmisos, setModalPermisos] = useState({ open: false, titulo: '', data: null });

    // Handle
    const actualizarData = (nuevaUsuario = []) => {
        const datausuario = roles.map((oc) => oc.idrol);
        nuevaUsuario.forEach((usuarioI) => {
            const indice = datausuario.indexOf(usuarioI.idrol);
            if (indice >= 0) roles.splice(indice, 1, usuarioI);
            else roles.splice(0, 0, usuarioI);
        });
        setRoles([...roles]);
    };
    const cerrarPermisos = () => {
        setModalPermisos({ open: false, titulo: '', data: null });
    };
    const abrirEditarPuesto = (row) => {
        context.formulario.abrir({
            open: true,
            propsForm: { data: row },
            formulario: FormRol,
            aceptar: (data) => {
                serviciosCatalogo.updateRol(
                    (nuevaData) => {
                        actualizarData(nuevaData.rol);
                        context.formulario.cerrarModalForm();
                    },
                    { idrol: row.idrol, ...data }
                );
            }
        });
    };
    const abrirNuevoUsuario = () => () => {
        context.formulario.abrir({
            open: true,
            propsForm: { data: null },
            formulario: FormRol,
            aceptar: (data) => {
                serviciosCatalogo.insertRol((nuevaData) => {
                    setRoles(nuevaData.rol);
                    context.formulario.cerrarModalForm();
                }, data);
            }
        });
    };
    const abrirConfirmarCambio = (usuario) => (e) => {
        let texto = `¿Estás seguro de volver a activar el rol ${usuario.nrol}?`;
        let status = 1;
        if (usuario.idestatus === '1') {
            texto = `¿Estás seguro de desactivar el rol ${usuario.nrol}, un rol inactivo no puede tiene permiosos?`;
            status = 0;
        }
        setModalPermisos({
            open: true,
            titulo: '¡Advertencia!',
            mensaje: texto,
            aceptar: () =>
                serviciosCatalogo.cambioEstatusRol(
                    (nuevaData) => {
                        actualizarData(nuevaData.rol);
                        cerrarPermisos({ open: false });
                    },
                    { idrol: usuario.idrol, idestatus: status }
                )
        });
    };

    const abrirPermisos = (item) => {
        setModalPermisos({
            open: true,
            Formulario: FormPermisosRelacion,
            paddingDisabled: true,
            titulo: `Permisos para el rol: ${item.nrol}`,
            props: {
                id: 'idrol',
                ctlg: ctl,
                item: item,
                cambioPermisos: serviciosCatalogo.cambioPermisosRol,
                cambioPagina: (setCambio, pagina) =>
                    serviciosCatalogo.getPermisosPaginaByRol(setCambio, { idrol: item.idrol, idpagina: pagina.idpagina })
            }
        });
    };
    // Config
    const columns = [
        { label: 'Rol', name: 'nrol', filtrar: true },
        {
            label: 'Estatus',
            name: 'idestatus',
            filtrar: false,
            type: { opcion: 'switch', accion: abrirConfirmarCambio }
        }
    ];
    const acciones = [{ label: 'Nuevo rol', icon: <AddBox />, click: abrirNuevoUsuario }];
    const accionesFila = [
        { label: 'Editar', onClick: abrirEditarPuesto, enabled: true },
        { label: 'Permisos', onClick: abrirPermisos, enabled: true }
    ];
    useEffect(() => {
        serviciosCatalogo.rolesInt((ct) => {
            setRoles(ct.rol || []);
            setCtl({ ...ctl, pagina: ct.pagina });
        });
    }, []);
    return (
        <MainCard title="Roles">
            <Tabla rows={roles} columns={columns} accionesTabla={acciones} accionesFila={accionesFila} columnKey="idrol" />
            <ModalGeneral
                open={modalPersmisos.open}
                titulo={modalPersmisos.titulo}
                width="sm"
                paddingDisabled={modalPersmisos.paddingDisabled}
                ContenidoMensaje={modalPersmisos.mensaje}
                acciones={
                    <>
                        <Button color="error" onClick={cerrarPermisos}>
                            Cerrar
                        </Button>
                        {Boolean(modalPersmisos.aceptar) && (
                            <Button color="success" onClick={modalPersmisos.aceptar}>
                                Aceptar
                            </Button>
                        )}
                    </>
                }
            >
                {modalPersmisos.Formulario && <modalPersmisos.Formulario {...modalPersmisos.props} proveedor={modalPersmisos.proveedor} />}
            </ModalGeneral>
        </MainCard>
    );
};

export default Roles;
