import React, { useEffect, useState } from 'react';
// material-ui
import PropTypes from 'prop-types';
import { styled, useTheme } from '@mui/material/styles';
import { Grade } from '@mui/icons-material';
import { ReactComponent as IconStar } from 'assets/images/icons/c-estrella.svg';
import { Box, LinearProgress, Card, CardMedia, CardActions, CardContent, Typography, Stack, Tooltip } from '@mui/material';
import { makeStyles, withStyles } from '@mui/styles';

// project imports
import Services from 'utils/serviciosVentas';

// ==============================|| DEFAULT DASHBOARD ||============================== //

const style = {
    card: { boxShadow: 0, borderRadius: 0, width: 200, margin: '20px 3px 3px 3px', cursor: 'pointer' },
    contentText: { textAlign: 'left' }
};

const Inicio = (props) => {
    const { permiso, usuario, curso } = props;
    const services = Services();
    const theme = useTheme();
    const srcPortada = curso?.portadaCurso ? curso.portadaCurso : 'https://cdn.pixabay.com/photo/2012/12/14/04/41/e-mail-69911_960_720.jpg';

    // Components
    const BorderLinearProgress = withStyles((theme) => ({
        root: {
            height: 10,
            borderRadius: 0
        },
        colorPrimary: {
            backgroundColor: theme.palette.dark[100]
        },
        bar: {
            borderRadius: 0,
            backgroundColor: theme.palette.success.dark
        }
    }))(LinearProgress);
    const IconTooltip = ({ title, children }) => (
        <Tooltip title={title} disableInteractive>
            {children}
        </Tooltip>
    );

    return (
        <Card sx={style.card} onClick={() => props.selectCurso(curso.idCurso)} raised>
            <CardMedia component="img" height="110" image={srcPortada} alt="portada" />
            <CardContent sx={{ p: 1, textAlign: 'left' }}>
                <Stack spacing={0.5} alignItems="left">
                    <Typography variant="cardTitle">{curso.titulo}</Typography>
                    <Typography variant="cardSubtitle">{curso.subtitulo}</Typography>
                </Stack>
            </CardContent>
            <BorderLinearProgress sx={{ width: '100%' }} variant="determinate" value={curso.porcentajeCompletado} />
            <CardActions sx={{ p: 1, textAlign: 'left' }}>
                <Stack sx={{ width: '100%' }}>
                    {/* <LinearProgressWithLabel porcentaje={curso.porcentajeCompletado} label={curso.porcentajeCompletado} /> */}
                    {/* <LinearProgress sx={{ width: '100%' }} variant="determinate" value={curso.porcentajeCompletado} /> */}
                    <Box sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                        <IconTooltip title="Progreso">
                            <Typography variant="cardSubtitle">{`${Math.round(curso.porcentajeCompletado)}%`}</Typography>
                        </IconTooltip>
                        <IconStar style={{ ...theme.typography.iconSm, margin: '0px 5px 0px 5px' }} />
                        <IconTooltip title="Promedio">
                            <Typography variant="cardSubtitle">{`${curso.calificacion.toFixed(2)}%`}</Typography>
                        </IconTooltip>
                    </Box>
                </Stack>
            </CardActions>
        </Card>
    );
};

export default Inicio;
