import React, { useState, useEffect, useContext } from 'react';
import { AddBox, Reorder } from '@mui/icons-material';

import { ModalGeneral, Tabla } from 'ui-component';
import headers from './components/headers';
import LayautContext from 'Context/ContextLayaut';
import AvisoConfirmacion from 'ui-component/AvisoConfirmacion';
import { ServiciosCurso, ModalCostosGrupo } from './components';
import FormActividad from './components/FormActividad';
import ModalPreguntasActividad from './components/ModalPreguntasActividad';
import FormDetalleActividad from './components/FormDetalleActividad';
import FormOrdenar from './components/FormOrdenar';
import FormDependenciaDetalle from './components/FormDependenciaDetalle';

const DetalleActividad = ({ permisos, actividad, onCambioNivel }) => {
    //
    const context = useContext(LayautContext);
    const serviciosCursos = ServiciosCurso();

    const [detalleActividad, setDetalleActividad] = useState([]);
    const [ctl, setCtl] = useState({ estatusCurso: [] });
    const [confirm, setConfirm] = useState({ open: false });
    const [modal, setModal] = useState({ open: false, titulo: '' });
    // Handle
    const actualizarData = (nuevaCurso = []) => {
        const datausuario = detalleActividad.map((oc) => oc.idDetalleActividad);
        nuevaCurso.forEach((detalleActividadI) => {
            const indice = datausuario.indexOf(detalleActividadI.idDetalleActividad);
            if (indice >= 0) detalleActividad.splice(indice, 1, detalleActividadI);
            else detalleActividad.splice(0, 0, detalleActividadI);
        });
        setDetalleActividad([...detalleActividad]);
    };

    const abrirNuevoCurso = () => () => {
        context.formulario.abrir({
            open: true,
            width: 'md',
            titulo: `Nuevo material para la actvidad "${actividad.titulo}"`,
            propsForm: { data: null, ctl: ctl },
            formulario: FormDetalleActividad,
            aceptar: (dataForm) => {
                const dataFormPivote = { ...dataForm };
                const formData = new FormData();
                if (dataFormPivote.video) dataFormPivote.video = dataFormPivote.video[0].file;
                Object.keys(dataFormPivote).forEach((key) => formData.append(key, dataFormPivote[key]));

                formData.append('idActividad', actividad.idActividad);
                formData.append('orden', detalleActividad.length + 1);

                /* si el tamaño del archivo supera el maximo permitido por el servidor (2 Mb)
                   el archivo se envia por pedazos (chunck file)
                */
                if (dataFormPivote?.video?.size > 1900000) {
                    const nombreOriginal = dataFormPivote.video.path;
                    formData.delete('video');
                    serviciosCursos.chunkFile(
                        (res, uidFolderName) => {
                            formData.append('video', uidFolderName + '/' + nombreOriginal);
                            formData.append('uidFolderName', uidFolderName);
                            serviciosCursos.insertDetalleActividad(
                                (res) => {
                                    actualizarData(res.detalleActividad);
                                    context.formulario.cerrar();
                                },
                                formData,
                                { cargando: false, isBigFileResponse: true, uidFolderName: uidFolderName }
                            );
                        },
                        {},
                        dataFormPivote.video
                    );
                    context.formulario.cerrar();
                } else {
                    serviciosCursos.insertDetalleActividad(
                        (res) => {
                            actualizarData(res.detalleActividad);
                            context.formulario.cerrar();
                        },
                        formData,
                        {
                            headers: {
                                'content-type': 'multipart/form-data'
                            }
                        }
                    );
                }
            }
        });
    };
    const abrirOrdenar = () => () => {
        context.formulario.abrir({
            open: true,
            width: 'sm',
            titulo: 'Ordenar Materiales',
            propsForm: { data: { dataOrden: detalleActividad }, ctl: ctl },
            formulario: FormOrdenar,
            aceptar: (dataForm) => {
                if (dataForm.dataOrdenada == undefined) return context.mensaje({ open: true, value: 'No se ha hacho ningun orden' });
                serviciosCursos.updateOrdenMaterial(
                    (res) => {
                        setDetalleActividad(res.detalleActividad);
                        context.formulario.cerrar();
                    },
                    { ...dataForm, idActividad: actividad.idActividad }
                );
                return true;
            }
        });
    };
    const abrirEditarActividadDetalle = (detalleActividad, isVer) => {
        context.formulario.abrir({
            open: true,
            width: 'md',
            isVer: isVer == 1,
            titulo: `${isVer ? '' : 'Editar'} ${detalleActividad.titulo} para la actvidad ${actividad.titulo}`,
            propsForm: { data: detalleActividad, ctl: ctl, isVer },
            formulario: FormDetalleActividad,
            aceptar: (dataForm) => {
                const dataFormPivote = { ...dataForm };
                const formData = new FormData();
                if (dataFormPivote.video && !dataFormPivote.videoActivo) {
                    dataFormPivote.video = dataFormPivote.video[0].file;
                }
                formData.append('idActividad', actividad.idActividad);
                formData.append('idDetalleActividad', detalleActividad.idDetalleActividad);
                Object.keys(dataFormPivote).map((key) => formData.append(key, dataFormPivote[key]));
                formData.append('idTipoActividad', detalleActividad.idTipoActividad);

                /* si el tamaño del archivo supera el maximo permitido por el servidor (2 Mb)
                   el archivo se envia por pedazos (chunck file)
                */
                if (dataFormPivote?.video?.size > 1900000 && !dataFormPivote.videoActivo) {
                    const nombreOriginal = dataFormPivote.video.path;
                    formData.delete('video');
                    serviciosCursos.chunkFile(
                        (res, uidFolderName) => {
                            formData.append('video', uidFolderName + '/' + nombreOriginal);
                            formData.append('uidFolderName', uidFolderName);
                            serviciosCursos.updateActivivdadDetalle(
                                (res) => {
                                    actualizarData(res.detalleActividad);
                                    context.formulario.cerrar();
                                },
                                formData,
                                { cargando: false, isBigFileResponse: true, uidFolderName: uidFolderName }
                            );
                        },
                        {},
                        dataFormPivote.video
                    );
                    context.formulario.cerrar();
                } else {
                    serviciosCursos.updateActivivdadDetalle(
                        (res) => {
                            actualizarData(res.detalleActividad);
                            context.formulario.cerrar();
                        },
                        formData,
                        {
                            headers: {
                                'content-type': 'multipart/form-data'
                            }
                        }
                    );
                }
            }
        });
    };
    const traerDatosExtra = (detalleActividad, isVer = 0) => {
        if (['1', '2'].includes(detalleActividad.idTipoActividad))
            serviciosCursos.getactividadDetalleExtra((extra) => abrirEditarActividadDetalle({ ...detalleActividad, ...extra }, isVer), {
                idDetalleActividad: detalleActividad.idDetalleActividad
            });
        else abrirEditarActividadDetalle(detalleActividad, isVer);
    };
    const abrirConfirmarCambio = (detalleActividad) => (e) => {
        let texto = `¿Estás seguro de volver a activar el material ${detalleActividad.titulo}?`;
        let status = 1;
        if (detalleActividad.estatus === '1') {
            texto = `¿Estás seguro de desactivar el material ${detalleActividad.titulo}, una actividad desactivada o será conciderada al tomar el curso?`;
            status = 0;
        }
        setConfirm({
            open: true,
            text: texto,
            aceptar: () =>
                serviciosCursos.cambiarEstatusActividadDetatalle(
                    (nuevaData) => {
                        actualizarData(nuevaData.detalleActividad);
                        setConfirm({ open: false });
                    },
                    { idDetalleActividad: detalleActividad.idDetalleActividad, estatus: status, idActividad: actividad.idActividad }
                )
        });
    };
    const abrirPreguntas = (detalleActividad) => {
        serviciosCursos.getActividadPregunta(
            (res) =>
                setModal({
                    open: true,
                    titulo: `Preguntas de la detalleActividad "${detalleActividad.titulo}"`,
                    formulario: ModalPreguntasActividad,
                    propsModal: { permisos: permisos, detalleActividad: { ...detalleActividad, preguntas: res.preguntas } }
                }),
            { idDetalleActividad: detalleActividad.idDetalleActividad }
        );
    };
    const cerrarModalCurso = () => setModal({ ...detalleActividad, open: false });

    const guardarDependencia = (dependenciaSelected, regSelected) => (event) => {
        const dataSend = {
            dataSelected: dependenciaSelected,
            idActividad: actividad.idActividad,
            idDetalleActividad: regSelected.idDetalleActividad
        };
        serviciosCursos.guardarDependenciaDetalle((res) => {
            setDetalleActividad(res.detalleActividad);
            cerrarModalCurso();
        }, dataSend);
    };

    const openFormDependencia = (selected) => {
        setModal({
            open: true,
            titulo: 'Agregar dependencias',
            formulario: FormDependenciaDetalle,
            propsModal: {
                data: {
                    selected: selected,
                    allData: detalleActividad.filter((det) => det.idDetalleActividad != selected.idDetalleActividad),
                    columnKey: 'idDetalleActividad'
                },
                headers: headers(() => {}, { estatusCurso: null }, null).detalleActividad,
                guardarDependencia: guardarDependencia,
                cerrar: cerrarModalCurso
            }
        });
    };

    // Config
    const acciones = [
        { label: 'Nuevo material', icon: <AddBox />, click: abrirNuevoCurso, disabled: !permisos.crear },
        { label: 'Ordenar material', icon: <Reorder />, click: abrirOrdenar, disabled: !permisos.editar }
    ];
    const accionesFila = [
        { label: 'Editar', onClick: (data) => traerDatosExtra(data), enabled: (row) => row.estatus == '1' && permisos.editarActividad },
        {
            label: 'Ver',
            onClick: (data) => traerDatosExtra(data, 1),
            enabled: (row) => ['1', '2'].includes(row.idTipoActividad) && permisos.ver
        },
        { label: 'Dependencia', onClick: openFormDependencia, enabled: (row) => row.estatus == '1' && permisos.editar },
        { label: 'Ver Preguntas', onClick: abrirPreguntas, enabled: (row) => row.idTipoActividad == 3 },
        { label: 'Sin opciones', onClick: () => {}, enabled: (row) => row.estatus == 0 && row.idTipoActividad == 1 && !permisos.ver }
    ];
    useEffect(() => {
        if (actividad)
            serviciosCursos.getDetalleActividad(
                (ct) => {
                    setDetalleActividad(ct.detalleActividad || []);
                },
                { idActividad: actividad.idActividad }
            );
        else setDetalleActividad([]);
    }, [actividad]);
    return (
        <>
            <Tabla
                rows={detalleActividad}
                columns={headers(abrirConfirmarCambio, ctl, permisos).detalleActividad}
                accionesTabla={acciones}
                accionesFila={accionesFila}
                columnKey="idDetalleActividad"
            />
            <AvisoConfirmacion
                open={confirm.open}
                title="Advertencia"
                text={confirm.text}
                accept={confirm.aceptar}
                cancel={() => setConfirm({ open: false })}
            />
            <ModalGeneral open={modal.open} width="md" titulo={modal.titulo} OnCerrar={cerrarModalCurso}>
                {modal.formulario && <modal.formulario {...modal.propsModal} />}
            </ModalGeneral>
        </>
    );
};

export default DetalleActividad;
