import { AddBox } from '@mui/icons-material';
import LayautContext from 'Context/ContextLayaut';
import React, { useState, useEffect, useRef, useContext } from 'react';
import { ModalGeneral, Tabla } from 'ui-component';
import AvisoConfirmacion from 'ui-component/AvisoConfirmacion';
import ServiciosCurso from './ServiciosCurso';
import FormCostoGrupo from './FormCostoGrupo';

const columns = [
    { label: 'Concepto', name: 'nombre', filtrar: true },
    { label: 'Costo', name: 'costo', filtrar: true, type: { opcion: 'numero', accion: 'money' } },
    { label: 'Hrs. Extra', name: 'horaExtra', filtrar: true },
    { label: 'Costo por horas. Extra', name: 'costoPorHora', filtrar: true },
    { label: 'Costo total extra', name: 'costoTotalExtra', filtrar: true, type: { opcion: 'numero', accion: 'money' } },
    { label: 'Usuario', name: 'nusuario', filtrar: true },
    { label: 'Modificacion', name: 'fechaModificacion', filtrar: true }
];

const ModalCostosGrupo = ({ curso, onCambio }) => {
    const context = useContext(LayautContext);
    const servicios = ServiciosCurso();
    const [costo, setCosto] = useState([]);
    const [modalEliminarCosto, setModalEliminarCosto] = useState({ open: false, text: '' });
    const actualizarData = (nuevaCosto = []) => {
        const idData = costo.map((oc) => oc.idCursoCosto);
        nuevaCosto.forEach((costoI) => {
            const indice = idData.indexOf(costoI.idCursoCosto);
            if (indice >= 0) costo.splice(indice, 1, costoI);
            else costo.splice(0, 0, costoI);
        });
        setCosto([...costo]);
    };
    const abrirNuevoCosto = () => () =>
        context.formulario.abrir({
            open: true,
            titulo: 'Nuevo costo',
            formulario: FormCostoGrupo,
            aceptar: (data) =>
                servicios.insertCursoCosto(
                    (res) => {
                        if (onCambio) onCambio(res);
                        actualizarData(res.costo);
                        context.formulario.cerrar();
                    },
                    { idCurso: curso.idCurso, ...data }
                )
        });
    const abrirEditarCosto = (costo) =>
        context.formulario.abrir({
            open: true,
            titulo: `Editar el costo por ${costo.nombre}`,
            formulario: FormCostoGrupo,
            propsForm: { data: { ...costo, costoExtraActivo: costo.costoTotalExtra > 0 } },
            aceptar: (data) =>
                servicios.updateCursoCosto(
                    (res) => {
                        if (onCambio) onCambio(res);
                        actualizarData(res.costo);
                        context.formulario.cerrar();
                    },
                    { idCurso: curso.idCurso, idCursoCosto: costo.idCursoCosto, ...data }
                )
        });
    const abrirEliminarCosto = (accion) => (costo) =>
        setModalEliminarCosto({
            open: true,
            text:
                accion == 0
                    ? `¿Estás seguro de eliminar costo por el concepto de ${costo.nombre}?`
                    : `¿Estás seguro volver a activar costo con el concepto de ${costo.nombre}?`,
            aceptar: () =>
                servicios.cambiarEstatusCursoCosto(
                    (res) => {
                        if (onCambio) onCambio(res);
                        actualizarData(res.costo);
                        setModalEliminarCosto({ ...modalEliminarCosto, open: false });
                    },
                    { idCursoCosto: costo.idCursoCosto, estatus: accion }
                )
        });
    const accionesTabla = [{ label: 'Nuevo costo', icon: <AddBox />, click: abrirNuevoCosto }];
    const accionesFila = [
        { label: 'Editar', onClick: abrirEditarCosto, enabled: true },
        { label: 'Eliminar Costo', onClick: abrirEliminarCosto(0), enabled: (costo) => costo.estatus == '1' },
        { label: 'Volver activar Costo', onClick: abrirEliminarCosto(1), enabled: (costo) => costo.estatus == '0' }
    ];
    useEffect(() => {
        setCosto(curso?.costo || []);
    }, [curso]);
    return (
        <>
            <Tabla
                rows={costo}
                columns={columns}
                onError={(cst) => cst.estatus != 1}
                accionesTabla={accionesTabla}
                accionesFila={accionesFila}
                columnKey="idCursoCosto"
            />
            <AvisoConfirmacion
                open={modalEliminarCosto.open}
                title="Advertencia"
                text={modalEliminarCosto.text}
                accept={modalEliminarCosto.aceptar}
                cancel={() => setModalEliminarCosto({ ...modalEliminarCosto, open: false })}
            />
        </>
    );
};

export default ModalCostosGrupo;
