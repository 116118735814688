import React, { useEffect, useState } from 'react';
import { Grid, AppBar, Toolbar, Box, Dialog, ListItemText, ListItem, IconButton, Typography, Slide } from '@mui/material';
import { Close } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';

import CardPurple from '../../../ui-component/cards/CardPurple';
import ListGrupos from './ListGrupos';
import ListCursos from './ListCursos';

import Services from './services';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const useStyles = makeStyles((theme) => ({
    background: {
        backgroundColor: theme.palette.primary.light,
        height: '100%'
    },
    infoUser: {
        borderRadius: '8px',
        backgroundColor: '#fff'
    }
}));

const OpenCard = ({ open, data, close, ...props }) => {
    const classes = useStyles();
    const services = Services();

    const [infoOpenCard, setInfoOpenCard] = useState({ grupos: [], cursos: [], cursosOriginal: [] });

    // Services
    const getInfoOpencard = () => {
        services.getInfoOpenCard(
            (res) => {
                if (res?.status == '1') {
                    const integrantes = res?.data?.integrantes?.map((integrante) => {
                        const idsGrupo = String(integrante.idsGrupo).split(',');
                        return { ...integrante, idsGrupo: idsGrupo };
                    });
                    const grupos = res?.data?.grupos?.map((grupo) => {
                        const newIntegrantes = integrantes.filter((integrante) => integrante.idsGrupo.includes(String(grupo.idGrupo)));
                        return { ...grupo, integrantes: newIntegrantes };
                    });
                    setInfoOpenCard({ grupos: grupos, cursos: res?.data?.cursos, cursosOriginal: res?.data?.cursos });
                }
            },
            { idColaborador: data?.idColaborador }
        );
    };

    // Functions
    const selectGroup = (grupo) => {
        const newCursos = infoOpenCard.cursosOriginal.filter((curso) => curso.idGrupo == grupo.idGrupo);
        setInfoOpenCard({ ...infoOpenCard, cursos: newCursos });
    };

    useEffect(() => {
        if (open) getInfoOpencard();
    }, [open]);

    return (
        <Dialog fullScreen open={open} onClose={close} TransitionComponent={Transition}>
            <AppBar sx={{ position: 'relative' }}>
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={close} aria-label="close">
                        <Close />
                    </IconButton>
                    {/* <Typography sx={{ fontSize: '1.200rem', fontWeight: 500, ml: 2, flex: 1 }} component="div">
                        Sound
                    </Typography> */}
                </Toolbar>
            </AppBar>
            <Box className={classes.background} p={2}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <CardPurple>
                            <Box className={classes.infoUser} p={2}>
                                <Typography mb={2} variant="h3">
                                    Colaborador
                                </Typography>
                                <Grid container spacing={4}>
                                    <Grid item>
                                        <div>
                                            <Typography variant="h4">#</Typography>
                                            <Typography variant="h5">{data.idpersona}</Typography>
                                        </div>
                                    </Grid>
                                    <Grid item>
                                        <div>
                                            <Typography variant="h4">Nombre</Typography>
                                            <Typography variant="h5">{data.nombrePersona}</Typography>
                                        </div>
                                    </Grid>
                                    <Grid item>
                                        <div>
                                            <Typography variant="h4">Correo personal</Typography>
                                            <Typography variant="h5">{data.email_personal}</Typography>
                                        </div>
                                    </Grid>
                                    <Grid item>
                                        <div>
                                            <Typography variant="h4">Correo empresarial</Typography>
                                            <Typography variant="h5">{data.email_empresarial}</Typography>
                                        </div>
                                    </Grid>
                                    <Grid item>
                                        <div>
                                            <Typography variant="h4">Dirección</Typography>
                                            <Typography variant="h5">{data.direccion}</Typography>
                                        </div>
                                    </Grid>
                                    <Grid item>
                                        <div>
                                            <Typography variant="h4">Puesto</Typography>
                                            <Typography variant="h5">{data.nom_puesto}</Typography>
                                        </div>
                                    </Grid>
                                    <Grid item>
                                        <div>
                                            <Typography variant="h4">Sede</Typography>
                                            <Typography variant="h5">{data.nom_sede}</Typography>
                                        </div>
                                    </Grid>
                                    <Grid item>
                                        <div>
                                            <Typography variant="h4">Jefe Inmediato</Typography>
                                            <Typography variant="h5">{data.jefe_inmediato}</Typography>
                                        </div>
                                    </Grid>
                                    <Grid item>
                                        <div>
                                            <Typography variant="h4">telefono</Typography>
                                            <Typography variant="h5">{data.telefono}</Typography>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Box>
                        </CardPurple>
                    </Grid>
                    <Grid item xs={4}>
                        <CardPurple>
                            <Box className={classes.infoUser} p={2}>
                                <Typography mb={2} variant="h3">
                                    Grupos
                                </Typography>
                                <ListGrupos data={{ grupos: infoOpenCard.grupos }} fn={{ selectGroup: selectGroup }} />
                            </Box>
                        </CardPurple>
                    </Grid>
                    <Grid item xs={8}>
                        <CardPurple>
                            <Box className={classes.infoUser} p={2}>
                                <Typography mb={2} variant="h3">
                                    Cursos
                                </Typography>
                                <ListCursos cursos={infoOpenCard.cursos} />
                            </Box>
                        </CardPurple>
                    </Grid>
                </Grid>
            </Box>
        </Dialog>
    );
};

export default OpenCard;
