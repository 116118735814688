import React from 'react';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, colors } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';

const useStyles = makeStyles((theme) => ({
    btnAceptar: {
        color: colors.green[500]
    },
    btnCancelar: {
        color: colors.red[500]
    }
}));
const ModalFormulario = (props) => {
    const { open, titulo, ContenidoMensaje, OnCancelar, OnCerrar, width, skroll, bloqueo, aceptar, children, ocultar } = props;
    const classes = useStyles();
    const methods = useForm();
    return (
        <Dialog open={open} onClose={OnCerrar} fullWidth maxWidth={width} aria-labelledby="form-dialog-title">
            {ocultar ? null : <DialogTitle id="form-dialog-title">{titulo}</DialogTitle>}
            <FormProvider {...methods}>
                <form autoComplete="off" onSubmit={methods.handleSubmit(aceptar)}>
                    <DialogContent>
                        <DialogContentText>{ContenidoMensaje}</DialogContentText>
                        <div className="MuiDialogContentText-root">{children}</div>
                    </DialogContent>
                    <DialogActions>
                        <Button id="btnCancelar" onClick={OnCancelar} className="btnError">
                            Cancelar
                        </Button>
                        <Button id="btnAceptar" type="submit" disabled={bloqueo} className={classes.btnAceptar}>
                            Aceptar
                        </Button>
                    </DialogActions>
                </form>
            </FormProvider>
        </Dialog>
    );
};

ModalFormulario.propTypes = {
    open: PropTypes.bool.isRequired,
    titulo: PropTypes.string.isRequired,
    ContenidoMensaje: PropTypes.string,
    OnCancelar: PropTypes.func.isRequired,
    children: PropTypes.node,
    aceptar: PropTypes.func.isRequired,
    width: PropTypes.string,
    bloqueo: PropTypes.bool,
    skroll: PropTypes.string
};

ModalFormulario.defaultProps = {
    width: 'sm',
    bloqueo: false,
    skroll: 'paper'
};

export default ModalFormulario;
