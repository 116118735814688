import React, { useState, useEffect, useContext } from 'react';
import { AddBox } from '@mui/icons-material';

import ModalAddEditUser from './components/ModalAddEditUser';

import { Tabla } from 'ui-component';
import headers from './components/headers';
import MainCard from 'ui-component/cards/MainCard';
import LayautContext from 'Context/ContextLayaut';

import { ServiciosCurso, ModalCostosGrupo } from './components';
import { Autocomplete, Breadcrumbs, Collapse, TextField, Typography } from '@mui/material';
import Curso from './Curso';
import Sesion from './Sesion';
import Temario from './Temario';
import Actividad from './Actividad';
import DetalleActividad from './DetalleActividad';

const niveles = ['Cursos', 'Sesiones', 'Temarios', 'Actividades', 'Material de actividad'];
const Cursos = ({ permisos, ...props }) => {
    //
    const context = useContext(LayautContext);
    const serviciosCursos = ServiciosCurso();
    const [rutaActiva, setRutaActiva] = useState([{ nivel: 0, value: null, id: null, nameLabel: '' }]);
    // States

    const [curso, setCurso] = useState([]);
    const [ctl, setCtl] = useState({ estatusCurso: [] });
    const actualizarRuta = (nivel, rutaCambiar) => {
        if (nivel >= 0) {
            rutaActiva.splice(nivel, rutaActiva.length - nivel, rutaCambiar);
            rutaActiva.push({ nivel: nivel + 1, modificar: !rutaActiva.some((ruta) => !ruta.modificar) });
            setRutaActiva([...rutaActiva]);
        } else {
            setRutaActiva([{ nivel: 0, modificar: true, value: null, id: null, nameLabel: '' }]);
        }
    };
    const cambiarNivel = (nivel) => (data, ctl) => {
        let ruta = {};
        switch (nivel) {
            case 0:
                ruta = { nivel: 0, value: data, modificar: true, id: 'idCurso', nameLabel: 'titulo', ctl: ctl };
                break;
            case 1:
                ruta = { nivel: 1, value: data, modificar: data.estatus == '1', id: 'idSesion', nameLabel: 'titulo', ctl: ctl };
                break;
            case 2:
                ruta = { nivel: 2, value: data, modificar: data.estatus == '1', id: 'idTemario', nameLabel: 'titulo', ctl: ctl };
                break;
            case 3:
                ruta = { nivel: 3, value: data, modificar: data.estatus == '1', id: 'idActividad', nameLabel: 'titulo', ctl: ctl };
                break;
            default:
                break;
        }
        actualizarRuta(nivel, ruta);
    };
    const cambioOpcionNivel = (ruta) => (event, value) => {
        if (value) {
            ruta.value = value;
            ruta.modificar = ruta.nivel == 0 || value.estatus == '1';
            actualizarRuta(ruta.nivel, ruta);
        } else actualizarRuta(ruta.nivel - 1, rutaActiva[ruta.nivel - 1]);
    };
    useEffect(() => console.log(rutaActiva), [rutaActiva]);
    const ultimoNivel = rutaActiva[rutaActiva.length - 1].nivel;
    permisos = {
        ...permisos,
        modificar: rutaActiva[rutaActiva.length - 1].modificar
    };
    return (
        <MainCard
            title={
                <Breadcrumbs>
                    {rutaActiva.map((ruta, index) =>
                        index !== rutaActiva.length - 1 ? (
                            <Autocomplete
                                key={`nivel_${index}`}
                                options={ruta.ctl}
                                size="small"
                                value={ruta.value}
                                getOptionLabel={(option) => option[ruta.nameLabel]}
                                defaultValue={ruta.value}
                                onChange={cambioOpcionNivel(ruta)}
                                filterSelectedOptions
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label={niveles[ruta.nivel]}
                                        inputProps={{
                                            ...params.inputProps,
                                            style: {
                                                width: `${Math.max(ruta.value[ruta.nameLabel]?.length, niveles[ruta.nivel].length) * 8}px`
                                            }
                                        }}
                                    />
                                )}
                            />
                        ) : (
                            <Typography variant="title">{niveles[ruta.nivel]}</Typography>
                        )
                    )}
                </Breadcrumbs>
            }
        >
            <Collapse in={ultimoNivel === 0}>
                <Curso permisos={permisos} onCambioNivel={cambiarNivel(0)} {...props} />
            </Collapse>
            <Collapse in={ultimoNivel === 1}>
                <Sesion
                    permisos={permisos}
                    onCambioNivel={cambiarNivel(1)}
                    curso={ultimoNivel === 1 ? rutaActiva[ultimoNivel - 1].value : null}
                />
            </Collapse>
            <Collapse in={ultimoNivel === 2}>
                <Temario
                    permisos={permisos}
                    onCambioNivel={cambiarNivel(2)}
                    sesion={ultimoNivel === 2 ? rutaActiva[ultimoNivel - 1].value : null}
                />
            </Collapse>
            <Collapse in={ultimoNivel === 3}>
                <Actividad
                    permisos={permisos}
                    onCambioNivel={cambiarNivel(3)}
                    tema={ultimoNivel === 3 ? rutaActiva[ultimoNivel - 1].value : null}
                />
            </Collapse>
            <Collapse in={ultimoNivel === 4}>
                <DetalleActividad permisos={permisos} actividad={ultimoNivel === 4 ? rutaActiva[ultimoNivel - 1].value : null} />
            </Collapse>
        </MainCard>
    );
};

export default Cursos;
