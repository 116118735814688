import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@mui/styles';
import { useForm } from 'react-hook-form';
import { Container, Box, colors, Radio, RadioGroup, FormControl, FormControlLabel, Grid, useFormControl, Button } from '@mui/material';
import NumberFormat from 'react-number-format';
import { PostAdd } from '@mui/icons-material';
import PropTypes from 'prop-types';
import { useLogin } from 'Context/ContextLogin';

import { AutocompleteForm, Tabla, ModalGeneral } from 'ui-component';
import MainCard from 'ui-component/cards/MainCard';
import Services from './Components/services';
import headers from './Components/headers';
import localUtils from './Components/utils';
import FiltrarByDia from './Components/FiltrarByDia';
import Materiales from './Components/Materiales';
import AsignarMaterial from './Components/AsignarMaterial';

const TrainersMaderas = ({ ...props }) => {
    const {
        usuario: { areas }
    } = props;
    const services = Services();
    const [modal, setModal] = useState({ open: false, titulo: '' });
    const {
        register,
        handleSubmit,
        getValues,
        setValue,
        control,
        formState: { errors },
        clearErrors,
        watch
    } = useForm();
    const [ctlg, setCtlg] = useState({
        personal: [],
        areas: [],
        departamentos: [],
        puestos: [],
        personalFiltrados: [],
        areasFiltradas: [],
        puestosFiltrados: [],
        day: -1
    });
    const { usuario } = useLogin();
    // Functions
    const changeCurrentDay = () => {
        setCtlg({ ...ctlg, day: localUtils.getCurrentDate('Admin').day });
    };
    const filtroPorDia = (dia, personal) => {
        if (dia == -1) {
            // SI SE SELECCIONAN TODOS LOS DIAS ENTRA AQUI
            setCtlg({ ...ctlg, personalFiltrados: ctlg.personal });
        } else {
            // SI NO CONTINUA CON EL DIA SELECCIONADO
            //const diasPorFiltrar = localUtils.getDiaXDelMes(dia);
            console.log(dia);
            const personalFiltrado = personal.filter((per) => new Date(`${per.fecha_ingreso}T00:00:00`).getDay() == dia);
            setCtlg({ ...ctlg, personalFiltrados: personalFiltrado });
        }
    };
    const filterForDay = (day, allData, object) => {
        // FILTRAMOS POR DIA
        // if (day == 'Todos') {
        //     if (object) {
        //         console.log(` -- `, object);
        //     }
        //     return false;
        // }
        // const daysToFilter = localUtils.getDiaXDelMes(day);
        // const dataFiltered = allData.filter((per) => daysToFilter.days.includes(per.fecha_ingreso));
        // setPersonal({ ...personal, personal: dataFiltered });
        // return true;
    };
    const cambioValor = (tipo) => (value) => {
        switch (tipo) {
            case 1:
                watch('departamentos', value);
                setValue('areas', null);
                setValue('puestos', null);
                if (value?.iddepto) {
                    let iddepto = value.iddepto;
                    setCtlg({
                        ...ctlg,
                        areasFiltradas: ctlg.areas.filter((e) => e.iddepto == iddepto)
                    });
                } else {
                    setCtlg({ ...ctlg, personalFiltrados: ctlg?.personal });
                }
                break;
            case 2:
                setValue('puestos', null);
                if (value?.idarea) {
                    let iddepto = value.iddepto;
                    let idarea = value.idarea;
                    setCtlg({
                        ...ctlg,
                        puestosFiltrados: ctlg.puestos.filter((e) => e.iddepto == iddepto && e.idarea == idarea)
                    });
                }
                break;
            default:
                break;
        }
    };
    const abrirMateriales = () => () => {
        setModal({ open: true, titulo: 'Materiales', contenido: Materiales, props: {} });
    };

    const abrirAsignarMateriales = (persona) => {
        console.log('per', persona);
        setModal({
            open: true,
            titulo: 'Material asignado a ' + persona.nombre_persona,
            contenido: AsignarMaterial,
            props: { idpersona: persona.idpersona, idpuesto: persona.id_puesto, permisos: props.permisos }
        });
    };
    const cerrarModal = () => setModal({ open: false });
    // Services
    const getInfoInicial = async () => {
        let idAreas = areas.map((area) => area.idarea);
        await services.getInfoInt(
            (res) => {
                if (res?.status == 1) {
                    let personal = res?.data?.personal;
                    let areas = res?.data?.areas;
                    let departamentos = res?.data?.departamentos;
                    let puestos = res?.data?.puestos;

                    setCtlg({
                        ...ctlg,
                        personal: personal,
                        personalFiltrados: personal,
                        departamentos: departamentos,
                        areas: areas,
                        puestos: puestos,
                        day: -1
                    });
                }
            },
            { areas: idAreas }
        );
        changeCurrentDay();
    };

    // Handle
    const handleChangeDay = (event) => {
        setCtlg({ ...ctlg, day: event.target.value });
    };

    const cargarFunciones = () => {
        getInfoInicial();
    };

    const cambioFiltros = ({ departamentos, areas, puestos }) => {
        let personal = ctlg?.personal;
        personal = personal.filter((e) => e.id_area == departamentos?.iddepto);
        if (areas?.idarea) {
            personal = personal.filter((e) => e.id_subarea == areas?.idarea);
        }
        if (puestos?.idpuesto) {
            personal = personal.filter((e) => e.id_puesto == puestos?.idpuesto);
        }
        setCtlg({ ...ctlg, personalFiltrados: personal });
    };
    useEffect(() => {
        // cargarFunciones();
        let idAreas = areas.map((area) => area.idarea);
        services.getInfoInt(
            (res) => {
                if (res?.status == 1) {
                    let personal = res?.data?.personal;
                    let areas = res?.data?.areas;
                    let departamentos = res?.data?.departamentos;
                    let puestos = res?.data?.puestos;

                    setCtlg({
                        ...ctlg,
                        personal: personal,
                        personalFiltrados: personal,
                        departamentos: departamentos,
                        areas: areas,
                        areasFiltradas: areas,
                        puestos: puestos,
                        day: -1
                    });
                }
            },
            { areas: idAreas }
        );
        changeCurrentDay();
    }, []);

    useEffect(() => {
        if (ctlg.personal.length > 0) {
            filtroPorDia(ctlg.day, ctlg.personal);
        }
        //filterForDay(ctlg?.day, ctlg?.personal);
    }, [ctlg.day]);

    // Config
    const acciones = [
        { component: FiltrarByDia, props: { value: ctlg?.day, onChange: handleChangeDay, estatus: 0 } }
        // { label: 'Materiales', icon: <PostAdd />, click: abrirMateriales, disabled: !Boolean(props?.permisos?.creaMaterial) }
    ];
    const accionesFila = [
        // { label: 'Editar', onClick: (row) => {}, enabled: true },
        // { label: 'Eliminar', onClick: (row) => {}, enabled: true },
        // {
        //     label: 'Material Asignado',
        //     onClick: (row) => {
        //         abrirAsignarMateriales(row);
        //     },
        //     enabled: true
        // }
    ];

    return (
        <MainCard title="Trainers Maderas">
            <form onSubmit={handleSubmit(cambioFiltros)}>
                <Grid container xs={12}>
                    <Grid item container xs={8}>
                        <Grid item xs={3} style={{ padding: '5px' }}>
                            <AutocompleteForm
                                id="selectDepartamentos"
                                label="Departamentos"
                                name="departamentos"
                                control={control}
                                onChange={cambioValor(1)}
                                // onChange={cambioValor(1)}
                                rules={{ required: { value: true, message: 'Campo requerido' } }}
                                options={ctlg.departamentos}
                                optionSelected={{ label: 'ndepartamento', id: 'iddepto' }}
                                error={errors.departamentos?.message}
                            />
                        </Grid>
                        <Grid item xs={3} style={{ padding: '5px' }}>
                            <AutocompleteForm
                                id="selectAreas"
                                label="Áreas"
                                name="areas"
                                control={control}
                                onChange={cambioValor(2)}
                                rules={{ required: { value: false, message: 'Campo requerido' } }}
                                options={ctlg.areasFiltradas}
                                optionSelected={{ label: 'narea', id: 'idarea' }}
                                error={errors.areas?.message}
                            />
                        </Grid>
                        <Grid item xs={3} style={{ padding: '5px' }}>
                            <AutocompleteForm
                                id="selectPuestos"
                                label="Puestos"
                                name="puestos"
                                control={control}
                                rules={{ required: { value: false, message: 'Campo requerido' } }}
                                options={ctlg.puestosFiltrados}
                                optionSelected={{ label: 'npuesto', id: 'idpuesto' }}
                                error={errors.puestosFiltrados?.message}
                            />
                        </Grid>
                        <Grid item xs={3} style={{ padding: '5px' }}>
                            <Button id="btnFiltrar" variant="contained" type="submit">
                                Filtrar
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </form>

            <Tabla
                rows={ctlg.personalFiltrados}
                accionesTabla={acciones}
                columns={headers.personalAdmin}
                accionesFila={accionesFila}
                columnKey="idpersona"
            />
            <ModalGeneral open={modal.open} width="md" titulo={modal.titulo} OnCerrar={cerrarModal}>
                {modal.contenido && <modal.contenido {...modal.props} />}
            </ModalGeneral>
        </MainCard>
    );
};

export default TrainersMaderas;
