import React, { useState, useEffect } from 'react';
import { Grid, TextField, Paper, Typography, Button, Divider, FormControlLabel, Box, Stepper, Step, StepLabel } from '@mui/material';
import EstructuraExamen from './EstructuraExamen';
import ServiciosCalificacion from './ServiciosCalificacion';

const steps = ['Nombre de la regla', 'Configuración', 'Resultado Final'];

const OpcRegistro = (props) => {
    const { data, columns } = props;
    const serviciosCalificacion = ServiciosCalificacion();
    const [examenSeleccionado, setExamenSeleccionado] = useState(null);

    useEffect(() => {
        // serviciosCalificacion.getExamenHistorial((data) => setExamenSeleccionado(data));
        serviciosCalificacion.getExamenHistorial((data) => setExamenSeleccionado(...data), {
            idDetalleActividad: data.idDetalleActividad,
            idCalificacionDetalle: data.idCalificacionDetalle,
            idColaborador: data.idColaborador
        });
    }, []);

    return (
        <Box sx={{ width: '100%' }}>
            <Typography gutterBottom variant="h5" component="div" align="center">
                <EstructuraExamen
                    rows={examenSeleccionado?.preguntas || []}
                    columns={columns}
                    accionesTabla={[]}
                    accionesFila={[]}
                    columnKey="idPregunta"
                    // row={row}
                />
            </Typography>
            <Divider variant="middle" />
        </Box>
    );
};
OpcRegistro.defaultProps = {
    vivienda: {}
};
export default OpcRegistro;
