import React from 'react';
import { Close } from '@mui/icons-material';
import { Grid, Typography, IconButton, Tooltip, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    background: {
        backgroundColor: theme.palette.secondary.dark,
        borderRadius: '12px',
        border: '1px solid #fff'
    },
    title: {
        color: '#fff'
    },
    title2: {
        textAlign: 'center',
        color: '#dddded'
    },
    text: {
        marginBottom: 20,
        color: '#bebddc'
    }
}));

export default function SimpleDialog({ data, close, ...props }) {
    const classes = useStyles();
    return (
        <Grid container>
            <Grid ietm xs={12} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Tooltip title="Cerrar">
                    <IconButton onClick={close}>
                        <Close color="primary" />
                    </IconButton>
                </Tooltip>
            </Grid>
            <Grid ietm xs={12}>
                <Typography className={classes.title2} variant="h4">
                    Objetivo
                </Typography>
            </Grid>
            <Grid ietm xs={12}>
                <Typography className={classes.text} variant="h5">
                    {data.objetivo}
                </Typography>
            </Grid>
            <Grid ietm xs={12}>
                <Typography className={classes.title2} variant="h4">
                    Descripción
                </Typography>
            </Grid>
            <Grid ietm xs={12}>
                <Typography className={classes.text} variant="h5">
                    {data.descripcion}
                </Typography>
            </Grid>
            <Grid ietm xs={12} sx={{ display: 'flex', justifyContent: 'end' }}>
                <Tooltip title="Cerrar">
                    <Button onClick={close}>Cerrar</Button>
                </Tooltip>
            </Grid>
        </Grid>
    );
}
