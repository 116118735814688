import LayautContext from 'Context/ContextLayaut';
import { useContext } from 'react';

const ServicioGrupo = () => {
    const context = useContext(LayautContext);
    return {
        grupoInt: context.llamarServidor('Grupo/getGrupoInt'),
        getCursoByGrupo: context.llamarServidor('Grupo/getCursoByGrupo'),
        insertGrupo: context.llamarServidorRespuesta('Grupo/insertGrupo'),
        updateGrupo: context.llamarServidorRespuesta('Grupo/updateGrupo'),
        cambiarEstatusGrupo: context.llamarServidorRespuesta('Grupo/cambiarEstatusGrupo')
    };
};

export default ServicioGrupo;
