import React from 'react';
import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';

const FiltrarByDia = ({ value, onChange, estatus }) => (
    <FormControl component="fieldset">
        <RadioGroup aria-label="gender" name="day" value={value} onChange={onChange} row>
            {estatus == 1 ? (
                <FormControlLabel value="2" control={<Radio id="radMartes" />} label="Martes" />
            ) : (
                <FormControlLabel value="1" control={<Radio id="radLunes" />} label="Lunes" />
            )}
            {estatus == 1 ? (
                <FormControlLabel value="4" control={<Radio id="radJueves" />} label="Jueves" />
            ) : (
                <FormControlLabel value="3" control={<Radio id="radMiercoles" />} label="Miercoles" />
            )}
            <FormControlLabel value="-1" control={<Radio id="radTodos" />} label="Todos" />
        </RadioGroup>
    </FormControl>
);
export default FiltrarByDia;
