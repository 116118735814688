import React, { useState } from 'react';

import { MoreHoriz, EventBusy, AccessTime, LocalAtm, LocationCity, Star } from '@mui/icons-material';
import { Grid, Typography, IconButton, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    infoUser: {
        borderRadius: '8px',
        backgroundColor: '#fff'
    },
    listItem: {
        backgroundColor: theme.palette.secondary.dark,
        color: '#fff',
        borderRadius: '12px',
        border: '1px solid #fff',
        padding: 10
    },
    listItemText: {
        color: '#fff'
    }
}));

export default function CardActividad({ data }) {
    const classes = useStyles();
    const actividad = data?.actividad;

    return (
        <>
            <Grid className={classes.listItem} container>
                <Grid item xs={12}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography sx={{ display: 'inline', color: '#dddded' }} component="span" variant="h4">
                                {actividad?.titulo}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography sx={{ display: 'inline', color: '#bebddc' }} component="span" variant="h5">
                                {actividad?.subtitulo}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}
