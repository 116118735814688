import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@mui/styles';
import {
    Container,
    Box,
    Typography,
    colors,
    Dialog,
    DialogActions,
    DialogTitle,
    DialogContent,
    DialogContentText,
    Grid,
    Divider,
    TextField,
    Slide,
    Button
} from '@mui/material';
import NumberFormat from 'react-number-format';
import { AddBox, DeleteForever, GetApp } from '@mui/icons-material';
import { useFormContext } from 'react-hook-form';

import PropTypes from 'prop-types';
import { Tabla } from 'ui-component';

import services from './services';

const useStyles = makeStyles((theme) => ({
    paper: {
        textAlign: 'center'
    },
    cancelar: {
        color: colors.red[500]
    },
    aceptar: {
        color: colors.green[500]
    },
    texto: {
        color: colors.indigo[900],
        textAlign: 'center',
        fontSize: 13
    },
    textoPequeno: {
        color: colors.indigo[900],
        textAlign: 'center',
        fontSize: 9
    },
    textoTabla: {
        color: colors.indigo[900],
        textAlign: 'left',
        fontSize: 9
    },
    textoTablaTH: {
        color: colors.indigo[900],
        textAlign: 'left',
        fontSize: 11
    },
    cantidadTabla: {
        color: colors.indigo[900],
        textAlign: 'right',
        fontSize: 9
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    textField: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        width: 200
    }
}));

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const ModalEscribirReporte = ({ open, data, cancel, setCargando, setMensaje, ...props }) => {
    const classes = useStyles();

    const { register, setValue, handleSubmit, errors, control } = useFormContext();

    setValue(
        'mensaje',
        `Buen día, Lic. Matilde Granados:

        El presente correo es porque tuve la oportunidad de realizar el seguimiento a la Inducción Específica de la colaboradora DAMARIS DANIELA DORANTES CASTRO, quien forma parte de su equipo de trabajo, ocupando el puesto de AUXILIAR DE CAPACITACIÓN.
        
        Le comento que este procedimiento tiene como objetivo darle seguimiento a la capacitación de la colaboradora y así identificar como se ha sentido en el desempeño de sus actividades y dentro de la empresa, para ello nos apoyamos del Formato de Inducción Específica, en el cual se enlistan todas las actividades que realiza un puesto en particular. Dicho formato lo firman de conformidad la colaboradora y la persona encargada de su capacitación cuando se concluye su capacitación.  
        
        Los comentarios de la colaboradora fueron los siguientes:
        
        1) Damaris Dorantes considera que lleva un 100% de avance en su Inducción Específica, menciona que no necesita refuerzo en ninguno de los temas que ya se revisaron hasta el momento.
        2) Menciona que la persona que la estuvo capacitando en su puesto fue la colaboradora Isabel García
        3) Como comentario adicional mencionó que se siente muy cómoda con el trato y apoyo que le han brindado sus compañeras. Mencionó que es bastante agradable trabajar en un ambiente lleno de compañerismo. 
        4) Por último hace mención de que para ella es muy grato pertenecer a Ciudad Maderas y en un equipo de trabajo donde existe el apoyo entre todos sus compañeros. Asimismo espera seguir aprendiendo mucho y crecer profesionalmente.
        
        El equipo de CAPACITACIÓN agradece su cooperación y disposición en la capacitación de la colaboradora, ya que para nosotros es importante que nuestros colaboradores tengan acompañamiento en su periodo de entrenamiento.
        
        ¡Saludos Cordiales!`
    );

    return (
        <div>
            <Box
                sx={{
                    m: 1,
                    p: 1,
                    mt: 2,
                    bgcolor: 'background.default',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center'
                }}
            >
                <TextField
                    id="inputRemitente"
                    label="Para"
                    {...register('para', { required: { value: true, message: 'Campo requerido' } })}
                />
                <br />
                <TextField
                    id="inputAsunto"
                    label="Asunto"
                    {...register('asunto', { required: { value: true, message: 'Campo requerido' } })}
                />
                <br />
                <TextField
                    id="inputMensaje"
                    label="Mensaje"
                    {...register('mensaje', { required: { value: true, message: 'Campo requerido' } })}
                    multiline
                />
            </Box>
        </div>
    );
};

export default ModalEscribirReporte;
