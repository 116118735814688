const columns = (area) => {
    return {
        colaboradores: [
            { label: '# Empleado', name: 'num_empleado', filtrar: true },
            { label: 'Tipo puesto', name: 'tipo_puesto', filtrar: true },
            { label: 'Puesto', name: 'nom_puesto', filtrar: true },
            { label: 'Sede', name: 'nom_sede', filtrar: true },
            { label: 'Persona', name: 'nombre_persona', filtrar: true },
            { label: 'Fecha ingreso', name: 'primer_contrato', filtrar: true },
            { label: 'Fecha contratación', name: 'segundo_contrato', filtrar: true },
            { label: '# Cursos', name: 'cursosInscritos', filtrar: true },
            { label: 'Cursos Completos', name: 'cursosCompletados', filtrar: true },
            { label: 'Total de Examenes', name: 'totalExamenes', filtrar: true },
            { label: 'Examenes Realizados', name: 'examenContestado', filtrar: true },
            { label: 'Aprobados', name: 'aprobados', filtrar: true },
            { label: 'Reprobados', name: 'reprobados', filtrar: true }
        ]
    };
};
export default columns;
