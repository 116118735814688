const columns = (cambioEstatus) => ({
    users: [
        { label: 'Usuario', name: 'usuario', filtrar: true },
        { label: 'Nombre', name: 'nombre', filtrar: true },
        { label: 'Rol', name: 'nRol', filtrar: true },
        { label: 'Puesto', name: 'npuesto', filtrar: true },
        { label: 'Sede', name: 'sedes', filtrar: true },
        { label: 'correo', name: 'correo', filtrar: true },
        { label: 'Fecha Creación', name: 'fechaCreacion', type: { opcion: 'date', accion: 4 }, filtrar: true },
        { label: 'Fecha Modificación', name: 'fechaModificacion', type: { opcion: 'date', accion: 4 }, filtrar: true },
        {
            label: 'Estatus',
            name: 'idestatus',
            filtrar: false,
            type: { opcion: 'switch', accion: cambioEstatus }
        }
    ]
});

export default columns;
