import LayautContext from 'Context/ContextLayaut';
import { useContext } from 'react';

const ServiciosCurso = () => {
    const context = useContext(LayautContext);
    return {
        // Panel Alumno
        getPanelAlumno: context.llamarServidor('Configuracion/getPanelAlumno'),

        // Banner
        insertBanner: context.llamarServidorRespuesta('Configuracion/insertBanner'),
        updateBanner: context.llamarServidorRespuesta('Configuracion/updateBanner'),
        deleteBanner: context.llamarServidorRespuesta('Configuracion/deleteBanner'),
        updateOrdenBanner: context.llamarServidorRespuesta('Configuracion/updateOrdenBanner'),

        // Video
        insertVideo: context.llamarServidorRespuesta('Configuracion/insertVideo'),
        updateVideo: context.llamarServidorRespuesta('Configuracion/updateVideo'),
        deleteVideo: context.llamarServidorRespuesta('Configuracion/deleteVideo'),
        updateOrdenVideo: context.llamarServidorRespuesta('Configuracion/updateOrdenVideo'),
        chunkFile: context.llamarServidorBigFile('ChunksHandler/chunckFile')
    };
};

export default ServiciosCurso;
