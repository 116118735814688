import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import { Tabla } from 'ui-component';
import MainCard from 'ui-component/cards/MainCard';
import AgregarColaborador from './Components/AgregarColaborador';
import CursoSesion from './Components/CursoSesion';
import Confirm from './Components/Confirm';

import TabContext from '@mui/lab/TabContext';
import GroupsIcon from '@mui/icons-material/Groups';

import Services from './Components/services';
import headers from './Components/headers';
import { useLogin } from 'Context/ContextLogin';

const TrainerAdministrativo = ({ permisos, ...props }) => {
    const services = Services();
    const { usuario } = useLogin();
    const [confirm, setConfirm] = useState({ open: false, title: '', text: '', cancel: () => {}, accept: () => {} });
    const [agregarColaborador, setAgregarColaborador] = useState({
        open: false,
        title: '',
        data: { row: [], personal: [] },
        cancel: () => {}
    });
    const [cursoSesion, setCursoSesion] = useState({
        open: false,
        title: '',
        data: { row: [], personal: [] },
        cancel: () => {}
    });
    const [value, setValue] = useState('1');
    const [personal, setPersonal] = useState({ personal: [], allData: [] });
    const [personalOOAM, setPersonalOOAM] = useState({ personal: [], allData: [] });

    const [administrativo, setAdministrativo] = useState([]);
    const [operativo, setOperativo] = useState([]);
    // Services
    const getInfoInicial = () => {
        services.getInfoNuevo(
            (res) => {
                setAdministrativo(res.personal);
            },
            { tipo_puesto: 'Administrativa' }
        );
    };

    const cargarFunciones = () => {
        getInfoInicial();
    };

    const closeCursoSesion = () => {
        setCursoSesion({ open: false, title: '', data: { row: [], personal: [] }, cancel: () => {} });
    };

    const cursoSesAvance = (row) => {
        setCursoSesion({
            open: true,
            title: 'Curso y Sesiones',
            data: { personal: personalOOAM.personal, tipo: value, withLeader: 0, persona: row },
            cancel: closeCursoSesion
        });
    };
    const bloquearColaborador = (row) => {
        const dataSend = { idContrato: row.idcontrato, idPersona: row.idpersona, isBloqueado: !(row.isBloqueado == 1), tipo: value };
        services.bloquearColaborador((res) => {
            let newPersonal = [];
            if (value == '1') newPersonal = personal.personal;
            else newPersonal = personalOOAM.personal;
            const index = newPersonal.findIndex((per) => per.idcontrato == res.personal.idcontrato);
            if (index != -1) newPersonal[index] = res.personal;
            setPersonal({ ...personal, personal: newPersonal });
        }, dataSend);
    };
    const asigGrup = () => {
        setAgregarColaborador({
            open: true,
            title: 'Agregar colaborador',
            data: { personal: administrativo, withLeader: 0 },
            cancel: closeAgregarColaborador
        });
    };
    const closeAgregarColaborador = () => {
        setAgregarColaborador({ open: false, title: '', data: { row: [], personal: [] }, cancel: () => {} });
    };
    const accionesFilaOOAM = [
        { label: 'Bloquear', onClick: (row) => bloquearColaborador(row), enabled: (row) => row.isBloqueado == 0 },
        { label: 'Desbloquear', onClick: (row) => bloquearColaborador(row), enabled: (row) => row.isBloqueado == 1 },
        { label: 'Activar Sesiones', onClick: cursoSesAvance, enabled: (row) => !(row.isTrainer == 1) }
    ];
    const acciones = [
        {
            label: 'Asignar Curso',
            icon: <GroupsIcon />,
            click: (data) => (even) => {
                asigGrup(1, data);
            },
            dataSelected: false
        }
    ];
    useEffect(() => {
        cargarFunciones();
    }, []);
    return (
        <MainCard title="Lista de colaboradores">
            <TabContext value={value}>
                <Tabla
                    rows={administrativo}
                    columns={headers(() => {}).administrativo}
                    accionesTabla={acciones}
                    accionesFila={accionesFilaOOAM}
                    columnKey="idpersona"
                />
                <Confirm open={confirm.open} title={confirm.title} text={confirm.text} cancel={confirm.cancel} accept={confirm.accept} />
                <AgregarColaborador
                    open={agregarColaborador.open}
                    title={agregarColaborador.title}
                    data={agregarColaborador.data}
                    cancel={agregarColaborador.cancel}
                    updatesetPersonal={getInfoInicial}
                    infoUsuario={props.usuario}
                />
                <CursoSesion
                    open={cursoSesion.open}
                    title={cursoSesion.title}
                    data={cursoSesion.data}
                    cancel={cursoSesion.cancel}
                    updatesetPersonal={getInfoInicial}
                    setTabla={setPersonalOOAM}
                    tabla={personalOOAM?.personal}
                    infoUsuario={props.usuario}
                />
            </TabContext>
        </MainCard>
    );
};

export default TrainerAdministrativo;
