import React from 'react';
import { Grid, TextField } from '@mui/material';
import { useFormContext } from 'react-hook-form';

const ModalAddEditUser = ({ open, isAdd, data, ctl, setCargando, setMensaje, handleClose, ...props }) => {
    const {
        register,
        formState: { errors }
    } = useFormContext();
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <TextField
                    label="Nombre *"
                    size="small"
                    fullWidth
                    {...register('nombre', { required: { value: true, message: 'Campo requerido' } })}
                    error={Boolean(errors.nombre)}
                    helperText={errors.nombre?.message}
                    defaultValue={data?.nombre}
                />
            </Grid>
        </Grid>
    );
};

export default ModalAddEditUser;
