import LayautContext from 'Context/ContextLayaut';
import { useContext } from 'react';

const ServiciosCatalogo = () => {
    const context = useContext(LayautContext);

    return {
        getInfoInicial: context.llamarServidor('Dashboard/getInfoInicial/'),
        getInfoOpenCard: context.llamarServidor('Dashboard/getInfoOpenCard/')
    };
};
export default ServiciosCatalogo;
