import PropTypes from 'prop-types';
import { useState } from 'react';

// material-ui
import { styled, useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { Avatar, Box, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import { School, Groups } from '@mui/icons-material';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import SkeletonEarningCard from 'ui-component/cards/Skeleton/EarningCard';

// assets
import EarningIcon from 'assets/images/icons/earning.svg';
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';

const useStyles = makeStyles((theme) => ({
    curso: {
        borderRadius: '8px',
        color: theme.palette.primary.light,
        backgroundColor: theme.palette.secondary[800],
        '&:hover': theme.palette.secondary.dark
    },
    grupo: {
        borderRadius: '8px',
        color: theme.palette.primary.light,
        backgroundColor: theme.palette.secondary[800]
    }
}));

// ===========================|| DASHBOARD DEFAULT - EARNING CARD ||=========================== //

const ColaboradorCard = ({ isLoading, data, ...props }) => {
    const theme = useTheme();
    const classes = useStyles();

    const selectTheme = data.isTrainer
        ? { background: { backgroundColor: theme.palette.secondary.dark }, circles: { background: theme.palette.secondary[200] } }
        : { background: { backgroundColor: theme.palette.primary.dark }, circles: { background: theme.palette.primary[200] } };

    const CardWrapper = styled(MainCard)(({ theme }) => ({
        ...selectTheme.background,
        color: '#fff',
        overflow: 'hidden',
        position: 'relative',
        '&:after': {
            ...selectTheme.circles,
            content: '""',
            position: 'absolute',
            width: 150,
            height: 150,
            borderRadius: '50%',
            top: -85,
            right: -95,
            [theme.breakpoints.down('sm')]: {
                top: -105,
                right: -140
            }
        },
        '&:before': {
            ...selectTheme.circles,
            content: '""',
            position: 'absolute',
            width: 150,
            height: 150,
            borderRadius: '50%',
            top: -125,
            right: -15,
            opacity: 0.5,
            [theme.breakpoints.down('sm')]: {
                top: -155,
                right: -70
            }
        }
    }));

    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            {isLoading ? (
                <SkeletonEarningCard />
            ) : (
                <CardWrapper border={false} content={false}>
                    <Box width={300} sx={{ p: 2.25, textAlign: 'left' }}>
                        <Grid container>
                            <Grid container item xs={12}>
                                <Grid container direction="column">
                                    <Grid item>
                                        <Typography sx={{ fontSize: '1.200rem', fontWeight: 500, mr: 1, mt: 0.75, mb: 0.75 }}>
                                            {data.isTrainer ? 'Trainer' : 'Colaborador'}
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography sx={{ fontSize: '0.800rem', fontWeight: 500, mr: 0.75, mt: 0.75, mb: 1.75 }}>
                                            {data.nombrePersona}
                                        </Typography>
                                    </Grid>
                                    <Grid item sx={{ mb: 1.25 }}>
                                        <Typography
                                            sx={{
                                                fontSize: '0.700rem',
                                                fontWeight: 500,
                                                color: theme.palette.primary.light
                                            }}
                                        >
                                            {data.nom_puesto}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {/* <Grid container item xs={2}>
                                <Grid container direction="column">
                                    <Grid item>
                                        <Grid container spacing={1} justifyContent="flex-start">
                                            <Grid item>
                                                <Tooltip title="Cursos">
                                                    <IconButton className={classes.curso} variant="rounded">
                                                        <School />
                                                    </IconButton>
                                                </Tooltip>
                                            </Grid>
                                            <Grid item>
                                                <Tooltip title="Grupos">
                                                    <IconButton className={classes.grupo} variant="rounded">
                                                        <Groups />
                                                    </IconButton>
                                                </Tooltip>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid> */}
                        </Grid>
                    </Box>
                </CardWrapper>
            )}
        </>
    );
};

ColaboradorCard.propTypes = {
    isLoading: PropTypes.bool
};

export default ColaboradorCard;
