import React, { useState, useEffect } from 'react';
import { Grid, TextField, Button, colors, FormControlLabel, Radio } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { CloudUpload, CloudDownload } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';

import TextFieldAutoComplete from 'ui-component/TextFieldAutoComplete';
import GroupRadioButtonForm from 'ui-component/GroupRadioButtonForm';

const useStyles = makeStyles((theme) => ({
    helperTextError: {
        color: colors.red[500],
        marginLeft: '14px',
        marginRight: '14px',
        marginTop: '4px',
        fontSize: '0.75rem',
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif'
    },
    helperText: {
        color: 'black',
        marginLeft: '0px',
        marginRight: '14px',
        marginTop: '4px',
        fontSize: '1rem',
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif'
    },
    helperText2: {
        color: colors.grey[500],
        marginLeft: '14px',
        marginRight: '14px',
        marginTop: '4px',
        fontSize: '0.75rem',
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif'
    }
}));

const FormMaterial = (props) => {
    const classes = useStyles();
    const { data, ctls } = props;
    const [fileSelected, setFileSelected] = useState({
        selected: false,
        name: '',
        formatoValido: 'No valido',
        asDefault: data?.ruta ? true : false
    });
    const [areas, setAreas] = useState([]);
    const [puestos, setPuestos] = useState([]);
    const {
        register,
        watch,
        formState: { errors },
        control,
        setValue,
        getValues
    } = useFormContext();

    const cambioDepartamento = (departamento) => {
        setValue('departamento', departamento);
        document.querySelector('#btnClear_area')?.click(); // si se cambia el nombre del atributo 'name' del campo se debe cambiar el selector(#btnClear_{nombre})
        document.querySelector('#btnClear_puesto')?.click();
        setAreas(ctls?.areas?.filter((area) => departamento?.iddepto == area?.iddepto));
    };

    const cambioArea = (area) => {
        setValue('area', area);
        document.querySelector('#btnClear_puesto')?.click();
        setPuestos(ctls?.puestos?.filter((puesto) => area?.idarea == puesto?.idarea));
    };

    const validarTamanio = (tamanio) => {
        if (!tamanio) return false;
        return tamanio / 1024 / 1024 < 25;
    };

    const extensionesNoValidas = [
        'application/x-msdownload',
        'application/javascript',
        'text/javascript',
        'application/x-msdos-program',
        'application/x-sh',
        'text/x-sh',
        'application/octet-stream',
        ''
    ];

    useEffect(() => {
        if (data?.iddepto) setAreas(ctls?.areas?.filter((area) => data.iddepto == area?.iddepto));
        if (data?.idarea) setPuestos(ctls?.puestos?.filter((puesto) => data.idarea == puesto?.idarea));
    }, []);

    return (
        <Grid container spacing={2}>
            <Grid item lg={4} md={4} xs={12}>
                <TextField
                    label="Nombre material *"
                    size="small"
                    fullWidth
                    {...register('nmaterial', { required: { value: true, message: 'Campo requerido' } })}
                    error={Boolean(errors.nmaterial)}
                    helperText={errors.nmaterial?.message}
                    defaultValue={data?.nmaterial}
                />
            </Grid>
            <Grid item lg={4} md={4} xs={12}>
                <TextFieldAutoComplete
                    label="Departamento *"
                    control={control}
                    name="departamento"
                    rules={{ required: { value: true, message: 'Campo requerido' } }}
                    options={ctls.departamentos || []}
                    optionSelected={{ label: 'ndepartamento', id: 'iddepto' }}
                    error={errors.departamento?.message}
                    defaultValue={
                        data?.iddepto && data?.ndepartamento ? { iddepto: data.iddepto, ndepartamento: data.ndepartamento } : null
                    }
                    onChange={cambioDepartamento}
                />
            </Grid>
            <Grid item lg={4} md={4} xs={12}>
                <TextFieldAutoComplete
                    label="Área *"
                    control={control}
                    name="area"
                    {...register('area')}
                    rules={{ required: { value: true, message: 'Campo requerido' } }}
                    options={areas}
                    optionSelected={{ label: 'narea', id: 'idarea' }}
                    error={errors.area?.message}
                    defaultValue={data?.idarea && data?.narea ? { idarea: data.idarea, narea: data.narea } : null}
                    onChange={cambioArea}
                />
            </Grid>
            <Grid item lg={4} md={4} xs={12}>
                <TextFieldAutoComplete
                    label="Puesto *"
                    control={control}
                    name="puesto"
                    rules={{ required: { value: true, message: 'Campo requerido' } }}
                    options={puestos}
                    optionSelected={{ label: 'npuesto', id: 'idpuesto' }}
                    error={errors.puesto?.message}
                    defaultValue={data?.idpuesto && data?.npuesto ? { idpuesto: data.idpuesto, npuesto: data.npuesto } : null}
                    onChange={(puesto) => {
                        setValue('puesto', puesto);
                    }}
                />
            </Grid>
            {fileSelected.asDefault && (
                <Grid item lg={4} md={4} xs={12}>
                    <Button
                        component="label"
                        role={undefined}
                        variant="contained"
                        tabIndex={-1}
                        startIcon={<CloudDownload />}
                        color="primary"
                        onClick={() => window.open(data?.ruta, '_blank', 'noreferrer')}
                    >
                        Ver archivo
                    </Button>
                </Grid>
            )}
            <Grid item lg={4} md={4} xs={12}>
                <Button component="label" role={undefined} variant="contained" tabIndex={-1} startIcon={<CloudUpload />} color="secondary">
                    {fileSelected.asDefault ? 'Cambiar' : 'Subir'} archivo *
                    <TextField
                        InputProps={{
                            accept: 'image/*'
                        }}
                        style={{ display: 'none' }}
                        fullWidth
                        variant="outlined"
                        onChange={(e) => {
                            e.target.files[0]
                                ? setFileSelected({
                                      selected: true,
                                      name: e.target.files[0].name,
                                      asDefault: false
                                  })
                                : setFileSelected({
                                      ...fileSelected,
                                      selected: false,
                                      name: ''
                                  });
                        }}
                        onClick={(event) => {
                            event.target.value = null;
                        }}
                        type="file"
                        {...register('archivoMaterial', {
                            required: { value: fileSelected.asDefault ? false : true, message: 'Campo requerido' },
                            validate: (value) =>
                                fileSelected.asDefault
                                    ? true
                                    : (!extensionesNoValidas.includes(value[0]?.type) && validarTamanio(value[0]?.size)) ||
                                      'Extensión o tamaño no válido'
                        })}
                        error={errors?.archivoMaterial?.message}
                    />
                </Button>
                <br></br>
                <helperText className={classes.helperText2}>{'(max. 25 Mb)'}</helperText>
                <br></br>
                <helperText className={fileSelected.selected && !errors?.archivoMaterial ? classes.helperText2 : classes.helperTextError}>
                    {fileSelected.selected && !errors?.archivoMaterial && '(Seleccionado: ' + fileSelected.name + ' )'}
                    {errors?.archivoMaterial?.message}
                </helperText>
            </Grid>
            <Grid item lg={12} md={12} xs={12}>
                <GroupRadioButtonForm
                    control={control}
                    name="requiereRespuesta"
                    label="¿Requiere respuesta?"
                    defaultValue={data?.requiere_respuesta || '0'}
                    children={
                        <>
                            <FormControlLabel value={1} control={<Radio />} label="Sí" />
                            <FormControlLabel value={0} control={<Radio />} label="No" />
                        </>
                    }
                />
            </Grid>
            <Grid item lg={12} md={12} xs={12}>
                <TextField
                    label="Descripción"
                    size="small"
                    fullWidth
                    {...register('descripcion', { required: { value: false, message: 'Campo requerido' } })}
                    error={Boolean(errors.descripcion)}
                    helperText={errors.descripcion?.message}
                    defaultValue={data?.descripcion}
                />
            </Grid>
        </Grid>
    );
};

export default FormMaterial;
