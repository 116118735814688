import LayautContext from 'Context/ContextLayaut';
import { useContext } from 'react';

const ServiciosUsers = () => {
    const context = useContext(LayautContext);
    return {
        login: context.llamarServidorRespuesta('Login/verificar'),
        userInt: context.llamarServidor('Usuario/getUsersInt'),
        addUser: context.llamarServidorRespuesta('Usuario/addUser/'),
        editUser: context.llamarServidorRespuesta('Usuario/editUser/'),
        cambiarEstatusUsuario: context.llamarServidorRespuesta('Usuario/cambiarEstatusUsuario'),
        getSedesByusuario: context.llamarServidor('Usuario/getSedesByusuario'),
        UpdateSedesByusuario: context.llamarServidorRespuesta('Usuario/UpdateSedesByusuario')
    };
};
export default ServiciosUsers;
