import React, { useState, useEffect, useContext } from 'react';
import LayautContext from 'Context/ContextLayaut';
import { makeStyles, styled } from '@mui/styles';
import AvisoConfirmacion from 'ui-component/AvisoConfirmacion';
import {
    Box,
    Typography,
    colors,
    Dialog,
    DialogActions,
    DialogTitle,
    DialogContent,
    Grid,
    Slide,
    Button,
    Stack,
    Autocomplete,
    TextField
} from '@mui/material';
import { School, GroupAdd } from '@mui/icons-material';
import { Tabla } from 'ui-component';
import Divider from '@mui/material/Divider';

import ActividadTrainer from './ActividadTrainer';

import Services from './services';
import headers from './headers';

const useStyles = makeStyles((theme) => ({
    paper: {
        textAlign: 'center'
    },
    cancelar: {
        color: colors.blue[500]
    },
    aceptar: {
        color: colors.green[500]
    },
    remove: {
        color: colors.red[500]
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        color: 'white',
        backgroundColor: theme.palette.primary.main,
        '&:hover': {
            backgroundColor: theme.palette.primary.light
        },
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(1),
            width: 'auto'
        }
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '10px',
        color: 'white'
    },
    inputRoot: {
        color: 'inherit'
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        color: 'white',
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        borderRadius: '10px',
        width: '0',
        '&:focus': {
            width: '20ch'
        }
    },
    activo: {
        width: '20ch'
    },
    iconButton: {
        color: 'white',
        backgroundColor: theme.palette.blue.main,
        borderRadius: '10px',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(1),
            width: 'auto'
        },
        '&:hover': {
            backgroundColor: theme.palette.blue.light,
            color: 'white'
        }
    }
}));

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const CursoSesion = ({ open, title, data, setTabla, tabla, cancel, updatesetPersonal, ...props }) => {
    const classes = useStyles();
    const services = Services();
    const context = useContext(LayautContext);
    const [cursosTrainer, setCursosTrainer] = React.useState({ cursos: [] });
    const [sesionTrainer, setSesionTrainer] = React.useState({ sesion: [] });
    const [actividadTrainer, setActividadTrainer] = React.useState({
        open: false,
        title: 'Actividad trainer',
        data: {},
        cerrar: () => null
    });
    const [confirm, setConfirm] = useState({ open: false });
    const [index, setIndex] = React.useState(1);

    // Functions
    const actualizarData = (nuevaCurso) => {
        const datausuario = sesionTrainer?.sesion?.map((oc) => oc.idSesion);
        nuevaCurso.forEach((sesionI) => {
            const indice = datausuario.indexOf(sesionI.idSesion);
            if (indice >= 0) sesionTrainer?.sesion?.splice(indice, 1, sesionI);
            else sesionTrainer?.sesion?.splice(0, 0, sesionI);
        });
        setSesionTrainer({ sesion: [...sesionTrainer?.sesion] });
    };

    const actualizarDataTabla = (nuevaCurso) => {
        let colab = 0;
        const datausuario = tabla.map((oc) => oc.idpersona);
        nuevaCurso.forEach((sesionI) => {
            const indice = datausuario.indexOf(sesionI.idpersona);
            if (indice >= 0) tabla.splice(indice, 1, sesionI);
            else tabla.splice(0, 0, sesionI);
            colab = sesionI.idColaborador;
        });
        setTabla({ personal: [...tabla] });
        data.persona.idColaborador = colab;
    };

    const cambioTab = (event, newValue) => {
        if (newValue === null || newValue === 'undefined') {
            return 0;
        }
        services.getContenido(
            (res) => {
                setSesionTrainer({ sesion: res.sesion });
                if (res?.data != null) {
                    actualizarDataTabla(res?.data);
                }
            },
            {
                idCurso: newValue.idCurso,
                idColaborador: data?.persona?.idColaborador,
                idpersona: data?.persona?.idpersona,
                idcontrato: data?.persona?.idcontrato
            }
        );
        return 0;
    };

    const cerrar = () => {
        const cont = index + 1;
        setIndex(cont);
        setCursosTrainer({ cursos: [] });
        setSesionTrainer({ sesion: [] });
        cancel();
    };

    const cerrarActividad = () => {
        setActividadTrainer({ open: false, title: 'Actividad trainer', data: {}, cerrar: () => null });
    };

    const cambioPagado = (sesion) => (e) => {
        let texto = `¿Estás seguro de volver a activar la sesión ${sesion.titulo}?`;
        let status = 1;
        if (sesion.estatus === '1') {
            texto = `¿Estás seguro de desactivar la sesión ${sesion.titulo}, una sesión desactivada invalida el pago?`;
            status = 0;
        }

        services.cambiarEstatusSesionCurso(
            (nuevaData) => {
                actualizarData(nuevaData);
            },
            {
                estatus: status,
                idAsignacionColabo: sesion.idAsignacionColabo,
                idCurso: sesion.idCurso,
                idSesion: sesion.idSesion,
                idColaborador: data?.persona?.idColaborador
            }
        );
    };

    const activarArctividad = (row) => {
        setActividadTrainer({
            open: true,
            title: 'Actividad trainer',
            data: { row, idColaborador: data?.persona?.idColaborador },
            cerrar: cerrarActividad
        });
    };

    // Services
    const getInfoInicial = () => {
        services.getCursos((res) => {
            res = res.filter((curso) => {
                let usuarioTieneArea = false;
                let idAreasUsuario = props?.infoUsuario?.areas?.map((area) => area.idarea) || [];
                curso.areas = curso.areas.split(',') || [];
                curso.areas.forEach((idarea) => {
                    if (idAreasUsuario.includes(idarea)) usuarioTieneArea = true;
                });
                return usuarioTieneArea;
            });
            setCursosTrainer({ cursos: res });
        });
    };

    const cargarFunciones = () => {
        getInfoInicial();
    };

    useEffect(() => {
        if (open) {
            cargarFunciones();
        }
    }, [open]);

    const accionesFila = [{ label: 'Activar Actividades', onClick: activarArctividad, enabled: (row) => !(row.isTrainer == 1) }];

    return (
        <div>
            <Dialog
                open={open}
                maxWidth="lg"
                TransitionComponent={Transition}
                keepMounted
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{title}</DialogTitle>
                <DialogContent>
                    {data.withLeader == 1 && (
                        <Typography variant="h4" gutterBottom component="div">
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                {data?.row?.nombre_persona}
                                <School color="secondary" style={{ paddingBottom: '5px' }} />
                            </div>
                        </Typography>
                    )}
                    <br />
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <div>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        width: 'fit-content',
                                        border: (theme) => `1px solid ${theme.palette.divider}`,
                                        borderRadius: 1,
                                        bgcolor: 'background.paper',
                                        color: 'text.secondary',
                                        '& svg': {
                                            m: 1.5
                                        },
                                        '& hr': {
                                            mx: 0.5
                                        }
                                    }}
                                >
                                    <Typography variant="h4" gutterBottom component="div">
                                        {data?.persona?.nombre_persona}
                                    </Typography>
                                    <Divider orientation="vertical" flexItem />
                                    <Typography variant="h4" gutterBottom component="div">
                                        {data?.persona?.nom_puesto}
                                    </Typography>
                                    <Divider orientation="vertical" flexItem />
                                    <Typography variant="h4" gutterBottom component="div">
                                        {data?.persona?.nom_sede}
                                    </Typography>
                                </Box>
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <div className={classes.divGrupoAlumnos}>
                                <br />
                                <Stack direction="row" alignItems="center" spacing={2}>
                                    <Autocomplete
                                        disablePortal
                                        key={`nivel_${index}`}
                                        id="combo-box-demo"
                                        options={cursosTrainer?.cursos}
                                        onChange={cambioTab}
                                        sx={{ width: 250 }}
                                        renderOption={(props, option) => (
                                            <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                                {option.label}
                                            </Box>
                                        )}
                                        renderInput={(params) => <TextField {...params} label="Curso" key={`nivel_${index}`} />}
                                    />
                                </Stack>
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <Tabla
                                rows={sesionTrainer?.sesion}
                                columns={headers(cambioPagado).sesion}
                                columnKey="numSesion"
                                accionesFila={accionesFila}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button className={classes.cancelar} onClick={cerrar}>
                        Cerrar
                    </Button>
                </DialogActions>
            </Dialog>
            <ActividadTrainer
                open={actividadTrainer.open}
                title={actividadTrainer.title}
                data={actividadTrainer.data}
                cerrar={actividadTrainer.cerrar}
            />
            <AvisoConfirmacion
                open={confirm.open}
                title="Advertencia"
                text={confirm.text}
                accept={confirm.aceptar}
                cancel={() => setConfirm({ open: false })}
            />
        </div>
    );
};

export default CursoSesion;
