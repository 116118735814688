import React, { useState, useEffect, useRef, useContext } from 'react';
import LayautContext from 'Context/ContextLayaut';
import { makeStyles } from '@mui/styles';
import { Container, Box, colors, Radio, RadioGroup, FormControl, FormControlLabel } from '@mui/material';
import { useForm } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import { AddBox, DeleteForever, GetApp } from '@mui/icons-material';
import PropTypes from 'prop-types';

import ModalEscribirReporte from './Components/ModalEscribirReporte';

import { Tabla } from 'ui-component';
import MainCard from 'ui-component/cards/MainCard';
import Services from './Components/services';
import headers from './Components/headers';
import localUtils from './Components/utils';

const useStyles = makeStyles((theme) => ({
    paper: {
        textAlign: 'center'
    },
    cancelar: {
        color: colors.red[500]
    },
    aceptar: {
        color: colors.green[500]
    },
    texto: {
        color: colors.indigo[900],
        textAlign: 'center',
        fontSize: 13
    },
    textoPequeno: {
        color: colors.indigo[900],
        textAlign: 'center',
        fontSize: 9
    },
    textoTabla: {
        color: colors.indigo[900],
        textAlign: 'left',
        fontSize: 9
    },
    textoTablaTH: {
        color: colors.indigo[900],
        textAlign: 'left',
        fontSize: 11
    },
    cantidadTabla: {
        color: colors.indigo[900],
        textAlign: 'right',
        fontSize: 9
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200
    }
}));

const PersonalVentEsp = ({ ...props }) => {
    const classes = useStyles();
    const services = Services();
    const context = useContext(LayautContext);
    const { register, handleSubmit, watch, errors } = useForm();

    // States
    const [cargando, setCargando] = useState(false);
    const [mensaje, setMensaje] = useState({ open: false });
    const [confirm, setConfirm] = useState({ open: false });

    const [modalEscribirReporte, setModalEscribirReporte] = useState({ open: false, data: {} });

    const [personal, setPersonal] = useState({ personal: [], allData: [] });

    // Services
    const getInfoInicialVent = async () => {
        await services.getInfoInicialVent((res) => setPersonal({ personal: res.personal }));
    };

    // Functions
    const cargarFunciones = () => {
        getInfoInicialVent();
    };

    const escribirReporte = (row) => {
        context.formulario.abrir({
            open: true,
            titulo: 'Enviar reporte',
            propsForm: { data: row },
            formulario: ModalEscribirReporte,
            mensajeConfirmacion: 'El reporte será enviado.',
            aceptar: (data) => {
                services.sendCorreoReporte((res) => {
                    context.formulario.cerrarModalForm();
                }, data);
            }
        });
    };

    useEffect(() => {
        cargarFunciones();
    }, []);

    // Config
    const acciones = [{ label: 'Nuevo usuario', icon: <AddBox />, click: (data) => (even) => {} }];
    const accionesFila = [
        {
            label: 'Escribir reporte',
            onClick: (row) => {
                escribirReporte(row);
            },
            enabled: true
        },
        { label: 'Enviar evaluación', onClick: (row) => {}, enabled: true }
    ];

    return (
        <MainCard title="Introducción Ventas">
            <Tabla rows={personal.personal} columns={headers.personalVent} accionesFila={accionesFila} columnKey="idpersona" />
        </MainCard>
    );
};

export default PersonalVentEsp;
