import LayautContext from 'Context/ContextLayaut';
import { useContext } from 'react';

const ServicioEncuesta = () => {
    const context = useContext(LayautContext);
    return {
        encuestaInt: context.llamarServidor('Grupo/getGrupoInt'),
        // insertGrupo: context.llamarServidorRespuesta('Grupo/insertGrupo'),
        // updateGrupo: context.llamarServidorRespuesta('Grupo/updateGrupo'),
        cambiarEstatusEncuesta: context.llamarServidorRespuesta('Grupo/cambiarEstatusGrupo')
    };
};

export default ServicioEncuesta;
