import React, { useEffect, useState } from 'react';
// material-ui
import PropTypes from 'prop-types';
import { styled, useTheme } from '@mui/material/styles';
import { ExpandLess, ExpandMore, KeyboardArrowDown, KeyboardArrowUp, StarBorder } from '@mui/icons-material';
import { Box, LinearProgress, Card, CardMedia, CardActions, CardContent, Typography, Stack, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';

import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import { Scrollbars } from 'react-custom-scrollbars';

// project imports
import Services from 'utils/serviciosVentas';
import CardCurso from './Cards/CardCurso';

// ==============================|| DEFAULT DASHBOARD ||============================== //

const Inicio = (props) => {
    const { permiso, usuario, cursos, hasCursos } = props;

    const services = Services();
    const theme = useTheme();

    return (
        // <Scrollbars ref={refButton} style={{ height: 350 }}>
        // <Stack direction="row">
        <Carousel centerMode centerSlidePercentage={30} showStatus={false} showIndicators={false} showThumbs={false}>
            {cursos?.map((curso) => (
                <CardCurso key={`${curso.titulo}${curso.idCurso}`} curso={curso} {...props} />
            ))}
            {!hasCursos && (
                <>
                    <br></br>
                    <Typography variant="h2" align="center">
                        No te encuentras inscrito a algún curso
                    </Typography>
                </>
            )}
        </Carousel>
        // </Stack>
        // </Scrollbars>
    );
};

export default Inicio;
