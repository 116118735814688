import React, { useState, useEffect, useContext } from 'react';
import LayautContext from 'Context/ContextLayaut';
import { makeStyles } from '@mui/styles';
import { colors, Slide, Stack, TextField } from '@mui/material';
import NumberFormat from 'react-number-format';
import { GroupWork } from '@mui/icons-material';

import DatePicker from '@mui/lab/DesktopDatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import esLocale from 'date-fns/locale/es';

import { useFormContext, Controller } from 'react-hook-form';

import PropTypes from 'prop-types';
import { Tabla } from 'ui-component';

import Services from './ServiciosCurso';
import headers from './headers';

const useStyles = makeStyles((theme) => ({
    paper: {
        textAlign: 'center'
    },
    cancelar: {
        color: colors.blue[500]
    },
    aceptar: {
        color: colors.green[500]
    },
    remove: {
        color: colors.red[500]
    }
}));

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const ModalasignarGrupo = ({ open, title, data, cancel, ...props }) => {
    const classes = useStyles();
    const services = Services();
    const context = useContext(LayautContext);

    const {
        register,
        watch,
        formState: { errors },
        control
    } = useFormContext();

    const [grupos, setGrupos] = useState([]);
    const [ctl, setCtl] = useState({ estatusCurso: [] });
    const [form, setForm] = useState({ fechaInicioCurso: '' });

    let grupoSelected = [];

    // Functions
    const cerrar = () => {
        cancel();
    };

    const getInitialDate = () => new Date();

    // Services
    const agregarGrupoCurso = (dataSelected) => (e) => {
        services.agregarGrupoCurso(
            (res) => {
                console.log(res);
            },
            { grupos: dataSelected }
        );
    };

    // const aceptar = () => {

    // };

    useEffect(() => {
        services.getGruposSinAsignar(
            (ct) => {
                setGrupos(ct.grupo || []);
                setCtl({ ...ctl, estatusGrupo: ct.estatusGrupo });
            },
            { idCurso: data.idCurso }
        );
    }, []);

    // Config
    // const accionesTabla = [{ label: 'Agregar grupo(s)', icon: <GroupWork />, click: agregarGrupoCurso, dataSelected: true }];

    return (
        <>
            <Stack spacing={2}>
                <Controller
                    control={control}
                    name="fechaInicioCurso"
                    defaultValue={getInitialDate()}
                    render={({ field: { onChange } }) => (
                        <LocalizationProvider locale={esLocale} dateAdapter={AdapterDateFns}>
                            <DatePicker
                                label="Inicio curso"
                                value={getInitialDate()}
                                onChange={(newValue) => {
                                    onChange(newValue);
                                }}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider>
                    )}
                />
                <TextField
                    id="outlined-basic"
                    multiline
                    rows="2"
                    label="Obserevaciones"
                    variant="outlined"
                    {...register('observaciones', { required: { value: false, message: 'Campo requerido' } })}
                />
            </Stack>
            <Controller
                control={control}
                name="grupoSelected"
                render={({ field: { onChange } }) => (
                    <Tabla
                        rows={grupos}
                        columns={headers(null, ctl).asignarGrupoByCurso}
                        isSeleccionable={{
                            accion: (data, checked) => {
                                if (checked) {
                                    grupoSelected = [...grupoSelected, data];
                                } else {
                                    grupoSelected = grupoSelected.filter((grupo) => grupo.idGrupo != data.idGrupo);
                                }
                                onChange(grupoSelected);
                            },
                            accionAll: (checked) => {
                                grupoSelected = checked ? grupos : [];
                                onChange(grupoSelected);
                            }
                        }}
                        columnKey="idGrupo"
                    />
                )}
            />
            {/* <Tabla rows={grupos} columns={headers(null, ctl).grupo} accionesTabla={accionesTabla} isSeleccionable columnKey="idGrupo" /> */}
        </>
    );
};

export default ModalasignarGrupo;
