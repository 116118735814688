import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@mui/styles';
import { colors, Grid, Tooltip, IconButton } from '@mui/material';
import { AddBox, Delete } from '@mui/icons-material';

import { Tabla } from 'ui-component';
import AvisoConfirmacion from 'ui-component/AvisoConfirmacion';
import LayautContext from 'Context/ContextLayaut';
import Services from '../services';
import FormAsignarMaterial from './FormAsignarMaterial';
import FormEvidencia from './FormEvidencia';
import { ActualizarData } from 'utils/herramientas';
import { isObject } from 'lodash';

const useStyles = makeStyles((theme) => ({
    paper: {
        textAlign: 'center'
    },
    cancelar: {
        color: colors.red[500]
    },
    aceptar: {
        color: colors.green[500]
    },
    texto: {
        color: colors.indigo[900],
        textAlign: 'center',
        fontSize: 13
    },
    textoPequeno: {
        color: colors.indigo[900],
        textAlign: 'center',
        fontSize: 9
    },
    textoTabla: {
        color: colors.indigo[900],
        textAlign: 'left',
        fontSize: 9
    },
    textoTablaTH: {
        color: colors.indigo[900],
        textAlign: 'left',
        fontSize: 11
    },
    cantidadTabla: {
        color: colors.indigo[900],
        textAlign: 'right',
        fontSize: 9
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    btnDelete: {
        color: colors.red[500],
        backgroundColor: 'transparent',
        '&:hover': {
            color: colors.red[500],
            backgroundColor: 'transparent'
        }
    }
}));

const AsignarMaterial = ({ ...props }) => {
    const classes = useStyles();
    const services = Services();
    const [confirm, setConfirm] = useState({ open: false });
    const [data, setData] = useState([]);
    const context = useContext(LayautContext);

    // Functions
    const abrirFormAsignarMaterial = () => (item) => {
        context.formulario.abrir({
            open: true,
            width: 'sm',
            titulo: 'Asignar nuevo material',
            btnAceptarName: 'Guardar',
            propsForm: { data: data, idpuesto: props.idpuesto },
            formulario: FormAsignarMaterial,
            aceptar: registrarAsignacionMaterial
        });
    };

    const abrirFormEvidencia = () => (item) => {
        context.formulario.abrir({
            open: true,
            width: 'sm',
            titulo: 'Evidencia de material asignado',
            btnAceptarName: 'Guardar',
            propsForm: { idpersona: props.idpersona, idmaterial: item.idmaterial },
            formulario: FormEvidencia,
            aceptar: subirEvidencia(item)
        });
    };

    // Services
    const getInfoInicial = async () => {
        await services.getMaterialesAsignados(
            (res) => {
                setData(res.materialesAsignados);
            },
            { idpersona: props.idpersona }
        );
    };

    const registrarAsignacionMaterial = (dataForm) => {
        services.crearAsignacionMaterial(
            (dataResponse) => {
                setData(dataResponse.materialesAsignados);
                context.formulario.cerrar();
            },
            {
                idmaterial: dataForm?.material?.idmaterial,
                idpersona: props.idpersona
            }
        );
    };

    const construirFormData = (data) => {
        let formData = new FormData();
        for (let field in data) {
            let valor = data[field];
            if (isObject(valor) && field != 'archivoEvidencia') valor = JSON.stringify(valor);
            formData.append(field, valor);
        }
        return formData;
    };

    const subirEvidencia = (item) => (dataForm) => {
        if (!dataForm?.archivoEvidencia[0]) {
            context.formulario.cerrar();
            return;
        }
        services.subirEvidenciaMaterialAsignado(
            (dataResponse) => {
                context.formulario.cerrar();
            },
            construirFormData({
                idmaterial: item.idmaterial,
                idpersona: props.idpersona,
                archivoEvidencia: dataForm?.archivoEvidencia[0]
            })
        );
    };

    const abrirAdvertencia = (item) => () => {
        setConfirm({ open: true, text: '¿Desea eliminar asignación?', aceptar: cambioEstatusMaterialAsignado(item) });
    };

    const cerrarAdvertencia = () => setConfirm({ open: false });

    const cambioEstatusMaterialAsignado = (item) => () => {
        cerrarAdvertencia();
        services.actualizarEstatusMaterialAsignado(
            (dataResponse) => {
                setData(dataResponse.materialesAsignados);
            },
            { idmaterial: item.idmaterial, idpersona: props.idpersona, nuevoEstatus: 0 }
        );
    };

    // Config
    const acciones = [
        { label: 'Agregar material', icon: <AddBox />, click: abrirFormAsignarMaterial, disabled: false /*!permisos.crear*/ }
    ];
    const accionesFila = [
        { label: 'Subir evidencia', onClick: (row) => abrirFormEvidencia()(row), enabled: (row) => row.requiere_respuesta == '1' },
        { label: 'Descargar material', onClick: (row) => window.open(row?.ruta, '_blank', 'noreferrer'), enabled: true }
        //{ label: 'Sin opciones', onClick: (row) => {}, enabled: (row) => row.idestatus == 0 }
    ];

    const columnas = [
        { label: 'Nombre material', name: 'nmaterial', filtrar: true },
        { label: 'Departamento', name: 'ndepartamento', filtrar: true },
        { label: 'Área', name: 'narea', filtrar: true },
        { label: 'Puesto', name: 'npuesto', filtrar: true },
        {
            label: 'Opciones',
            name: 'opciones',
            filtrar: false,
            type: {
                opcion: 'componente',
                accion: (row) => (
                    <Grid container justifyContent="center" alignItems="center">
                        <Grid item lg={4} md={4} xs={4}>
                            {1 == 1 && (
                                <Tooltip title="Eliminar">
                                    <IconButton className={classes.btnDelete} size="small" onClick={abrirAdvertencia(row)}>
                                        <Delete />
                                    </IconButton>
                                </Tooltip>
                            )}
                        </Grid>
                    </Grid>
                )
            }
        }
    ];

    const cargarFunciones = () => {
        getInfoInicial();
    };

    useEffect(() => {
        cargarFunciones();
    }, []);

    return (
        <>
            <Tabla rows={data} accionesTabla={acciones} columns={columnas} accionesFila={accionesFila} columnKey="idmaterial" />
            <AvisoConfirmacion
                open={confirm.open}
                title="Advertencia"
                text={confirm.text}
                accept={confirm.aceptar}
                cancel={() => setConfirm({ open: false })}
            />
        </>
    );
};

export default AsignarMaterial;
