import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@mui/styles';
import { Typography, colors, DialogContent, DialogContentText, Grid, Divider, TextField, Slide, Button } from '@mui/material';
import { useFormContext } from 'react-hook-form';

const useStyles = makeStyles((theme) => ({
    paper: {
        textAlign: 'center'
    },
    cancelar: {
        color: colors.red[500]
    },
    aceptar: {
        color: colors.green[500]
    },
    texto: {
        color: colors.indigo[900],
        textAlign: 'center',
        fontSize: 13
    },
    textoPequeno: {
        color: colors.indigo[900],
        textAlign: 'center',
        fontSize: 9
    },
    textoTabla: {
        color: colors.indigo[900],
        textAlign: 'left',
        fontSize: 9
    },
    textoTablaTH: {
        color: colors.indigo[900],
        textAlign: 'left',
        fontSize: 11
    },
    cantidadTabla: {
        color: colors.indigo[900],
        textAlign: 'right',
        fontSize: 9
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200
    }
}));

const ModalAddEditUser = ({ open, isAdd, data, rols, ctl, setCargando, setMensaje, handleClose, ...props }) => {
    const classes = useStyles();
    const {
        register,
        formState: { errors },
        control
    } = useFormContext();

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <TextField
                    label="Nombre *"
                    fullWidth
                    {...register('nrol', { required: { value: true, message: 'Campo requerido' } })}
                    error={Boolean(errors.nrol)}
                    helperText={errors.nrol?.message}
                    defaultValue={data?.nrol}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    label="Descripción *"
                    fullWidth
                    {...register('descripcion', { required: { value: true, message: 'Campo requerido' } })}
                    error={Boolean(errors.descripcion)}
                    helperText={errors.descripcion?.message}
                    defaultValue={data?.descripcion}
                />
            </Grid>
        </Grid>
    );
};

export default ModalAddEditUser;
