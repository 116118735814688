import React, { useEffect, useState } from 'react';
// material-ui
import PropTypes from 'prop-types';
import { styled, useTheme } from '@mui/material/styles';
import { ExpandLess, ExpandMore, KeyboardArrowDown, KeyboardArrowUp, StarBorder } from '@mui/icons-material';
import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';

// project imports
import { gridSpacing } from 'store/constant';
import Banners from 'views/Configuracion/PanelAlumno/Components/Banners';
import Videos from 'views/Configuracion/PanelAlumno/Components/Videos';
import CardYellow from 'ui-component/cards/CardYellow';
import Services from 'views/Configuracion/Components/Services';
import MainCard from 'ui-component/cards/MainCard';

// ==============================|| DEFAULT DASHBOARD ||============================== //

const Configuracion = (props) => {
    const { permiso, usuario } = props;
    const services = Services();
    // States
    const [panelAlumno, setPanelAlumno] = useState({ banners: [], videos: [] });
    const [ctls, setCtls] = useState({
        departamentos: [],
        asesores: []
    });

    // Services
    const getPanelAlumno = () => {
        services.getPanelAlumno((res) => {
            let idsDepartamentosExistentes = res.deptosExistentes?.map((departamento) => departamento.iddepto) || [];
            setPanelAlumno(res);
            setCtls({ departamentos: res.deptos, departamentosExistentes: idsDepartamentosExistentes });
        });
    };

    useEffect(() => {
        getPanelAlumno();
    }, []);

    const propsAll = {
        panelAlumno,
        setPanelAlumno,
        ctls,
        setCtls
    };

    return (
        <MainCard title="Panel de usuario">
            <Grid container spacing={gridSpacing}>
                <Grid item xs={12}>
                    <Videos {...props} {...propsAll} />
                </Grid>
            </Grid>
        </MainCard>
    );
};

export default Configuracion;
