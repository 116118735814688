import React, { useEffect, useRef } from 'react';
import { Button, ButtonGroup, Grid, TextField, Typography, Divider, colors } from '@mui/material';

import { useFormContext } from 'react-hook-form';
import { FormDropZone, AutocompleteForm } from 'ui-component';
import { BigPlayButton, Player } from 'video-react';
import { Delete } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    helperTextError: {
        color: colors.red[500],
        marginLeft: '14px',
        marginRight: '14px',
        marginTop: '4px',
        fontSize: '0.75rem',
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif'
    }
}));

const FormVideo = ({ open, isAdd, data, ctls, ...props }) => {
    const classes = useStyles();
    const {
        register,
        watch,
        formState: { errors },
        control,
        setValue,
        getValues,
        unregister
    } = useFormContext();
    const video = data?.video;

    const clickEliminarVideoActivo = () => setValue('video', []);
    const cambioDepartamento = (departamento) => {
        setValue('iddepto', departamento?.iddepto);
    };
    const obtenerDepartamentosDisponibles = () => {
        let depDisp =
            ctls?.departamentos?.filter(
                (departamento) =>
                    !ctls.departamentosExistentes.includes(departamento.iddepto) ||
                    (video != null ? video.iddepto == departamento.iddepto : false)
            ) || [];

        return depDisp;
    };

    useEffect(() => {
        register('video');
        setValue('video', video?.linkDrop || []);
        setValue('iddepto', video?.iddepto);
    }, [register]);

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <TextField
                    label="Título *"
                    size="small"
                    fullWidth
                    {...register('titulo', {
                        required: { value: true, message: 'Campo requerido' },
                        validate: (value) => value.match(/(^\s+|\s+$)/g) == null || 'El inicio o el final no pueden ser espacios'
                    })}
                    error={Boolean(errors.titulo)}
                    helperText={errors.titulo?.message}
                    defaultValue={video?.titulo}
                />
            </Grid>
            <Grid item xs={12}>
                <AutocompleteForm
                    id="selectDepartamentos"
                    label="Departamento *"
                    name="departamentos"
                    control={control}
                    onChange={cambioDepartamento}
                    rules={{ required: { value: true, message: 'Campo requerido' } }}
                    options={obtenerDepartamentosDisponibles()}
                    optionSelected={{ label: 'ndepartamento', id: 'iddepto' }}
                    error={errors.departamentos?.message}
                    defaultValue={video?.iddepto ? { ndepartamento: video.ndepartamento, iddepto: video.iddepto } : null}
                />
            </Grid>
            <Grid item xs={12}>
                <Divider textAlign="left">
                    <Typography>Datos de contacto</Typography>
                </Divider>
            </Grid>
            <Grid item xs={12}>
                <TextField
                    id="txtCorreo"
                    size="small"
                    error={Boolean(errors.correo)}
                    helperText={errors?.correo?.message}
                    variant="outlined"
                    fullWidth
                    label="Correo *"
                    name="correo"
                    defaultValue={video?.email}
                    {...register('correo', {
                        required: { value: true, message: 'Campo requerido' },
                        pattern: { value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/, message: 'Formato incorrecto' } //PARA FORMATO COMPLETO DE CORREO
                    })}
                />
            </Grid>
            {watch('video')?.length <= 0 ? (
                <Grid item xs={12}>
                    <FormDropZone
                        control={control}
                        name="video"
                        rules={{ required: { value: true, message: 'Campo requerido' } }}
                        acceptedFiles={['video/*']}
                        {...register('video', { required: { value: true, message: 'Campo requerido' } })}
                        defaultValue={null}
                    />
                </Grid>
            ) : (
                <Grid item xs={12} container spacing={2}>
                    <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center' }}>
                        <ButtonGroup variant="outlined" aria-label="outlined primary button group">
                            <Button color="error" onClick={clickEliminarVideoActivo}>
                                <Delete />
                            </Button>
                        </ButtonGroup>
                    </Grid>
                    <Grid item xs={10}>
                        <Player
                            playsInline
                            src={
                                video?.linkDrop && !Array.isArray(watch('video'))
                                    ? video?.linkDrop
                                    : null || (watch('video')?.length > 0 ? watch('video')[0]?.data : null)
                            }
                        >
                            <BigPlayButton position="center" />
                        </Player>
                    </Grid>
                </Grid>
            )}
            <br></br>
            <helperText className={classes.helperTextError}>{errors?.video?.message}</helperText>
        </Grid>
    );
};

export default FormVideo;
