import React, { useState } from 'react';

import { MoreHoriz, EventBusy, AccessTime, LocalAtm, LocationCity, Star } from '@mui/icons-material';
import { List, ListItem, Collapse } from '@mui/material';
import { makeStyles } from '@mui/styles';

import Modal from './Modal';
import { CardCurso, CardSesion, CardTemario, CardActividad, CardDetalleActividad } from './Cards';

const useStyles = makeStyles((theme) => ({
    infoUser: {
        borderRadius: '8px',
        backgroundColor: '#fff'
    }
}));

const style = {
    list: {
        bgcolor: 'background.paper',
        color: '#616161'
    },
    mlCard: {
        ml: 2
    }
};

export default function ListCursos({ cursos, ...props }) {
    const classes = useStyles();

    const [openCurso, setOpenCurso] = useState(null);
    const [openSesion, setOpenSesion] = useState(null);
    const [openTemario, setOpenTemario] = useState(null);
    const [openActividad, setOpenActividad] = useState(null);
    const [openDetalleActividad, setOpenDetalleActividad] = useState(null);

    // Handle
    const cambiarOpenCurso = (id) => {
        setOpenCurso(id === openCurso ? null : id);
    };
    const cambiarOpenSesion = (id) => {
        setOpenSesion(id === openSesion ? null : id);
    };
    const cambiarOpenTemario = (id) => {
        setOpenTemario(id === openTemario ? null : id);
    };
    const cambiarOpenActividad = (id) => {
        setOpenActividad(id === openActividad ? null : id);
    };
    const cambiarOpenDetalleActividad = (id) => {
        setOpenDetalleActividad(id === openDetalleActividad ? null : id);
    };

    return (
        <div>
            <List className={classes.infoUser} sx={style.list}>
                {cursos?.map((curso) => (
                    <>
                        <ListItem alignItems="flex-start" onClick={() => cambiarOpenCurso(curso.idCurso)}>
                            <CardCurso data={{ curso: curso }} />
                        </ListItem>
                        <Collapse in={curso.idCurso == openCurso} timeout="auto" unmountOnExit>
                            <List className={classes.infoUser} sx={{ ...style.list, ...style.mlCard }}>
                                {curso.sesiones.map((sesion) => (
                                    <>
                                        <ListItem alignItems="flex-start" onClick={() => cambiarOpenSesion(sesion.idSesion)}>
                                            <CardSesion data={{ sesion: sesion }} />
                                        </ListItem>
                                        <Collapse in={sesion.idSesion == openSesion} timeout="auto" unmountOnExit>
                                            <List className={classes.infoUser} sx={{ ...style.list, ...style.mlCard }}>
                                                {sesion.temarios.map((temario) => (
                                                    <>
                                                        <ListItem
                                                            alignItems="flex-start"
                                                            onClick={() => cambiarOpenTemario(temario.idTemario)}
                                                        >
                                                            <CardTemario data={{ temario: temario }} />
                                                        </ListItem>
                                                        <Collapse in={temario.idTemario == openTemario} timeout="auto" unmountOnExit>
                                                            <List className={classes.infoUser} sx={{ ...style.list, ...style.mlCard }}>
                                                                {temario.actividades.map((actividad) => (
                                                                    <>
                                                                        <ListItem
                                                                            alignItems="flex-start"
                                                                            onClick={() => cambiarOpenActividad(actividad.idActividad)}
                                                                        >
                                                                            <CardActividad data={{ actividad: actividad }} />
                                                                        </ListItem>
                                                                        <Collapse
                                                                            in={actividad.idActividad == openActividad}
                                                                            timeout="auto"
                                                                            unmountOnExit
                                                                        >
                                                                            <List
                                                                                className={classes.infoUser}
                                                                                sx={{ ...style.list, ...style.mlCard }}
                                                                            >
                                                                                {actividad.detalleActividades.map((detalleActividad) => (
                                                                                    <>
                                                                                        <ListItem
                                                                                            alignItems="flex-start"
                                                                                            onClick={() =>
                                                                                                cambiarOpenDetalleActividad(
                                                                                                    detalleActividad.idDetalleActividad
                                                                                                )
                                                                                            }
                                                                                        >
                                                                                            <CardDetalleActividad
                                                                                                data={{
                                                                                                    detalleActividad: detalleActividad
                                                                                                }}
                                                                                            />
                                                                                        </ListItem>
                                                                                    </>
                                                                                ))}
                                                                            </List>
                                                                        </Collapse>
                                                                    </>
                                                                ))}
                                                            </List>
                                                        </Collapse>
                                                    </>
                                                ))}
                                            </List>
                                        </Collapse>
                                    </>
                                ))}
                            </List>
                        </Collapse>
                    </>
                ))}
            </List>
        </div>
    );
}
