import LayautContext from 'Context/ContextLayaut';
import { useContext } from 'react';

const ServiciosCatalogo = () => {
    const context = useContext(LayautContext);

    return {
        // Informacion principal
        getInfoInt: context.llamarServidor('IntEspecifica/getInfoInt/'),

        sendCorreoFormulario: context.llamarServidorRespuesta('IntEspecifica/sendCorreoFormulario/'),
        sendCorreoReporte: context.llamarServidorRespuesta('IntEspecifica/sendCorreoReporte/'),

        getCursos: context.llamarServidor('Trainer/getCursos'),
        getGruposDefault: context.llamarServidor('Trainer/getGruposDefault'),
        getAlumnosGrupo: context.llamarServidor('Trainer/getAlumnosGrupo'),
        agregarColaborador: context.llamarServidorRespuesta('Trainer/agregarColaborador'),
        getContenido: context.llamarServidor('Trainer/getsesionCurso'),
        cambiarEstatusSesionCurso: context.llamarServidorRespuesta('Trainer/cambiarEstatusSesionCurso')
    };
};

export default ServiciosCatalogo;
