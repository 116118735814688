import React from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { Autocomplete, TextField } from '@mui/material';

const AutocompleteForm = ({ id, control, name, label, defaultValue, rules, error, options, optionSelected, onChange, ...props }) => (
    <Controller
        control={control}
        name={name}
        rules={rules}
        defaultValue={defaultValue || null}
        render={(props) => {
            let idAC = id || 'select' + name;
            // { field: { onChange, value } }
            return (
                <Autocomplete
                    id={idAC}
                    onChange={(event, item) => {
                        if (props.field.onChange && typeof props.field.onChange === 'function') props.field.onChange(item);
                        if (onChange) onChange(item);
                    }}
                    size="small"
                    fullWidth
                    noOptionsText="No hay coincidencias"
                    options={options}
                    value={props.field.value}
                    getOptionLabel={
                        typeof optionSelected.label === 'function' ? optionSelected.label : (item) => item[optionSelected.label]
                    }
                    getOptionSelected={(option, value) =>
                        value == undefined || value === '' || option[optionSelected.id] === value[optionSelected.id]
                    }
                    renderInput={(params) => <TextField {...params} label={label} error={Boolean(error)} helperText={error} />}
                />
            );
        }}
    />
);
AutocompleteForm.propTypes = {
    control: PropTypes.object.isRequired,
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    defaultValue: PropTypes.object,
    rules: PropTypes.object.isRequired,
    error: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.object).isRequired,
    optionSelected: PropTypes.shape({
        label: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
        id: PropTypes.string.isRequired
    })
};

AutocompleteForm.defaultProps = {
    label: '',
    defaultValue: null,
    optionSelected: { id: 'default', label: 'default' }
};

export default AutocompleteForm;
