import { useContext } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { Grid, Box, ButtonBase, Tooltip } from '@mui/material';

// project imports
import LogoSection from '../LogoSection';
import SearchSection from './SearchSection';
import ProfileSection from './ProfileSection';
import NotificationSection from './NotificationSection';

// assets
import { IconMenu2, IconSettings, IconLogout } from '@tabler/icons';
import { ReactComponent as IconMenu } from 'assets/images/icons/menu.svg';
import { ReactComponent as IconEngrane } from 'assets/images/icons/engrane.svg';
import { ReactComponent as IconSalir } from 'assets/images/icons/salir.svg';
import LoginContext from 'Context/ContextLogin';

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper
    },
    iconDefault: {
        width: '1.8rem',
        fill: theme.palette.primary.dark,
        '&:hover': { fill: theme.palette.dark.main }
    },
    iconSalir: {
        width: '4.5rem',
        fill: theme.palette.primary.dark,
        '&:hover': { fill: theme.palette.red.main }
    }
}));

const Header = ({ handleLeftDrawerToggle }) => {
    const theme = useTheme();
    const classes = useStyles();
    const context = useContext(LoginContext);
    const navigate = useNavigate();

    const handleLogout = async () => {
        context.cerrarLogin();
    };

    const handleListItemClick = (event, index, route = '') => {
        if (route && route !== '') {
            navigate(route);
        }
    };

    const IconTop = (props) => {
        const { id, icon, onClick, tooltip } = props;
        return (
            <Tooltip title={tooltip} disableInteractive>
                <ButtonBase id={id} sx={{ borderRadius: '12px', overflow: 'hidden' }} onClick={onClick}>
                    {icon}
                </ButtonBase>
            </Tooltip>
        );
    };

    return (
        <>
            {/* logo & toggler button */}
            <Box
                sx={{
                    display: 'flex'
                    // [theme.breakpoints.down('md')]: {
                    //     width: 'auto'
                    // }
                }}
            >
                <Box component="span" sx={{ display: { xs: 'none', md: 'block' }, flexGrow: 1 }}>
                    <LogoSection />
                </Box>
            </Box>
            <Grid
                container
                spacing={2}
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between'
                    // [theme.breakpoints.down('md')]: {
                    //     width: 'auto'
                    // }
                }}
            >
                <Grid
                    item
                    xs={12}
                    md={8}
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-around'
                        // [theme.breakpoints.down('md')]: {
                        //     width: 'auto'
                        // }
                    }}
                >
                    <ProfileSection />
                </Grid>
                <Grid
                    item
                    xs={12}
                    md={4}
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-around'
                        // [theme.breakpoints.down('md')]: {
                        //     width: 'auto'
                        // }
                    }}
                >
                    <IconTop
                        id="btnMenuNavBar"
                        icon={<IconMenu stroke={1.5} className={classes.iconDefault} />}
                        tooltip="Esconder menú"
                        onClick={handleLeftDrawerToggle}
                    />
                    <IconTop
                        id="btnConfigurarPerfilNavBar"
                        icon={<IconEngrane stroke={1.5} className={classes.iconDefault} />}
                        tooltip="Configuración de perfil"
                        onClick={(event) => handleListItemClick(event, 0, '/perfil')}
                    />
                    <IconTop
                        id="btnCerrarSesionNavBar"
                        icon={<IconSalir stroke={1.5} className={classes.iconSalir} />}
                        tooltip="Cerrar sesión"
                        onClick={handleLogout}
                    />
                </Grid>
            </Grid>

            {/* header search <SearchSection /> */}
            {/* <Box sx={{ flexGrow: 1 }} />
            <Box sx={{ flexGrow: 1 }} /> */}

            {/* notification & profile <NotificationSection /> */}
        </>
    );
};

Header.propTypes = {
    handleLeftDrawerToggle: PropTypes.func
};

export default Header;
