import LayautContext from 'Context/ContextLayaut';
import { useContext } from 'react';

const ServiciosVentas = () => {
    const context = useContext(LayautContext);
    return {
        getPermisosPaginaByRol: context.llamarServidor('Catalogo/getPermisosPaginaByRol'),
        getInfoCurso: context.llamarServidorRespuesta('Dashboard/getInfoCurso'),
        Allcursos: context.llamarServidor('HomeVentas/Allcursos'),
        getInfoCursoSesiones: context.llamarServidor('Dashboard/getsesionCurso'),
        getInfoTemario: context.llamarServidor('Dashboard/getTemarioSesion'),
        getActividadTema: context.llamarServidor('Dashboard/getActividadTema'),
        getInfoActividad: context.llamarServidor('HomeVentas/getInfoActividad'),
        sendRespuestas: context.llamarServidorRespuesta('HomeVentas/sendRespuestas'),
        // colaboradoresMiSede: context.llamarServidor('HomeVentas/colaboradoresMiSede/'),
        getBannersAndVideos: context.llamarServidor('HomeVentas/getBannersAndVideos/'),

        reiniciarExamen: context.llamarServidorRespuesta('HomeVentas/reiniciarExamen/')
    };
};

export default ServiciosVentas;
