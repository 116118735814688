import React, { useState, useEffect, useRef } from 'react';
import { Typography, colors, DialogContent, DialogContentText, Grid, Divider, TextField, Slide, Button } from '@mui/material';
import { useFormContext } from 'react-hook-form';

import AutocompleteForm from 'ui-component/AutocompleteForm';

const ModalAddEditUser = ({ open, isAdd, data, ctl, setCargando, setMensaje, handleClose, ...props }) => {
    const {
        register,
        watch,
        formState: { errors },
        control,
        setValue
    } = useFormContext();
    useEffect(() => {
        if (data) {
            setValue('rol', data.rol);
        }
    }, []);
    return (
        <DialogContent>
            <DialogContentText>
                <Typography variant="h6"> Usuario </Typography>
            </DialogContentText>
            {/* getValues("idRol") */}
            <Grid container spacing={2}>
                <Grid item xs={6} md={4}>
                    <TextField
                        label="Usuario"
                        size="small"
                        {...register('usuario', { required: { value: true, message: 'Campo requerido' } })}
                        error={Boolean(errors.usuario)}
                        helperText={errors.usuario?.message}
                        defaultValue={data?.usuario}
                    />
                </Grid>
                {!data && (
                    <Grid item xs={6} md={4}>
                        <TextField
                            label="Contraseña"
                            size="small"
                            {...register('password', { required: { value: true, message: 'Campo requerido' } })}
                            type="password"
                            error={Boolean(errors.password)}
                            helperText={errors.password?.message}
                            defaultValue={data?.password}
                        />
                    </Grid>
                )}
                <Grid item xs={6} md={4}>
                    <AutocompleteForm
                        label="Rol *"
                        control={control}
                        name="rol"
                        rules={{ required: { value: true, message: 'Campo requerido' } }}
                        options={ctl.rol}
                        optionSelected={{ label: 'nRol', id: 'idRol' }}
                        error={errors.rol?.message}
                        defaultValue={data?.rol}
                    />
                </Grid>
                <Grid item xs={6} md={4}>
                    <AutocompleteForm
                        label="Puesto"
                        control={control}
                        name="puesto"
                        rules={{}}
                        options={ctl.puesto}
                        optionSelected={{ label: 'npuesto', id: 'idpuesto' }}
                        error={errors.puesto?.message}
                        defaultValue={data?.puesto}
                    />
                </Grid>
            </Grid>

            <br />
            <Divider />
            <br />
            {watch('rol')?.idRol == 5 && (
                <>
                    <DialogContentText>Información CRM</DialogContentText>
                    <Grid container spacing={2}>
                        <Grid item xs={6} md={4}>
                            <TextField
                                label="Numero de empleado *"
                                size="small"
                                {...register('idCRM', { required: { value: true, message: 'Campo requerido' } })}
                                type="idCRM"
                                error={Boolean(errors.idCRM)}
                                helperText={errors.idCRM?.message}
                                defaultValue={data?.idCRM}
                            />
                        </Grid>
                    </Grid>
                    <br />
                    <Divider />
                    <br />
                </>
            )}
            <DialogContentText>Información personal.</DialogContentText>
            <Grid container spacing={2}>
                <Grid item xs={6} md={4}>
                    <TextField
                        label="Nombre *"
                        size="small"
                        {...register('nombre', { required: { value: true, message: 'Campo requerido' } })}
                        error={Boolean(errors.nombre)}
                        helperText={errors.nombre?.message}
                        defaultValue={data?.nombre}
                    />
                </Grid>
                <Grid item xs={6} md={4}>
                    <TextField
                        label="Apellido Paterno *"
                        size="small"
                        {...register('aPaterno', { required: { value: true, message: 'Campo requerido' } })}
                        error={Boolean(errors.aPaterno)}
                        helperText={errors.aPaterno?.message}
                        defaultValue={data?.aPaterno}
                    />
                </Grid>
                <Grid item xs={6} md={4}>
                    <TextField label="Apellido Materno" size="small" {...register('aMaterno')} defaultValue={data?.aPaterno} />
                </Grid>
                <Grid item xs={6} md={4}>
                    <TextField
                        label="Telefono *"
                        size="small"
                        {...register('telefono', {
                            required: { value: true, message: 'Campo requerido' },
                            maxLength: { value: 15, message: 'No más de 15 caracteres' },
                            validate: (value) =>
                                (Number(value) > 0 && Number.isInteger(Number(value || 0))) || 'No es un número de teléfono'
                        })}
                        error={Boolean(errors.telefono)}
                        helperText={errors.telefono?.message}
                        defaultValue={data?.telefono}
                    />
                </Grid>
                <Grid item xs={6} md={4}>
                    <TextField
                        label="Correo *"
                        size="small"
                        {...register('correo', {
                            required: { value: true, message: 'Campo requerido' },
                            maxLength: { value: 100, message: 'No más de 100 caracteres' },
                            pattern: { value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/, message: 'Formato incorrecto' }
                        })}
                        error={Boolean(errors.correo)}
                        helperText={errors.correo?.message}
                        defaultValue={data?.correo}
                    />
                </Grid>
            </Grid>
        </DialogContent>
    );
};

export default ModalAddEditUser;
