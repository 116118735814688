import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@mui/styles';
import { Snackbar, Alert } from '@mui/material';
import { AddBox } from '@mui/icons-material';

import ModalAddEditUser from './components/ModalAddEditUser';

import { ModalGeneral, Tabla } from 'ui-component';
import headers from './components/headers';
import ServiciosUser from 'utils/ServiciosUser';
import MainCard from 'ui-component/cards/MainCard';
import LayautContext from 'Context/ContextLayaut';
import AvisoConfirmacion from 'ui-component/AvisoConfirmacion';
import UsuarioSedes from './components/UsuarioSedes';

const Usuario = ({ permisos }) => {
    //
    const context = useContext(LayautContext);
    const serviciosUser = ServiciosUser();
    // States
    const [modalGeneral, setModalGeneral] = useState({ open: false, titulo: '', props: {}, Formulario: null });
    const [users, setUsers] = useState([]);
    const [ctl, setCtl] = useState({ puesto: [] });
    const [confirm, setConfirm] = useState({ open: false });
    const [mensaje, setMensaje] = useState({
        open: false,
        status: 1,
        value: ''
    });

    const cerrarMensaje = (event) => setMensaje({ ...mensaje, open: false });

    // Handle
    const actualizarData = (nuevaUsuario = []) => {
        const datausuario = users.map((oc) => oc.idUsuario);
        nuevaUsuario.forEach((usuarioI) => {
            const indice = datausuario.indexOf(usuarioI.idUsuario);
            if (indice >= 0) users.splice(indice, 1, usuarioI);
            else users.splice(0, 0, usuarioI);
        });
        setUsers([...users]);
    };

    const abrirEditarUsuario = (row) => {
        row = {
            ...row,
            rol: {
                idRol: row.idRol,
                nRol: row.nRol
            },
            puesto: row.npuesto ? { idpuesto: row.idpuesto, npuesto: row.npuesto } : null,
            sede: row.nom_sede ? { idsede: row.idsede, nom_sede: row.nom_sede } : null
        };
        context.formulario.abrir({
            open: true,
            propsForm: { data: row, ctl: ctl },
            formulario: ModalAddEditUser,
            aceptar: (data) => {
                data.idRol = data.rol.idRol;
                data.idpuesto = data.puesto?.idpuesto || null;
                let buscarNombreUsuario = users.filter(
                    (user) =>
                        user.usuario.toLowerCase() == data.usuario.toLowerCase() && data.usuario.toLowerCase() != row.usuario.toLowerCase()
                );
                let buscarCorreoUsuario = users.filter(
                    (user) =>
                        user.correo?.toLowerCase() == data.correo.toLowerCase() && data.correo.toLowerCase() != row.correo?.toLowerCase()
                );
                let buscarTelefonoUsuario = users.filter(
                    (user) =>
                        user.telefono?.toLowerCase() == data.telefono.toLowerCase() &&
                        data.telefono.toLowerCase() != row.telefono?.toLowerCase()
                );
                // validamos los campos que tienen contraint de tipo 'UNIQUE'
                if (buscarNombreUsuario.length > 0) {
                    setMensaje({ ...mensaje, open: true, status: -1, value: 'El nombre de usuario ya existe' });
                } else if (buscarCorreoUsuario.length > 0) {
                    setMensaje({ ...mensaje, open: true, status: -1, value: 'El correo electrónico ya existe' });
                } else if (buscarTelefonoUsuario.length > 0) {
                    setMensaje({ ...mensaje, open: true, status: -1, value: 'El telefono ingresado ya existe' });
                } else {
                    serviciosUser.editUser(
                        (nuevaData) => {
                            actualizarData(nuevaData.usuarios);
                            context.formulario.cerrar();
                        },
                        { idUser: row.idUsuario, ...data }
                    );
                }
            }
        });
    };
    const abrirNuevoUsuario = () => () => {
        context.formulario.abrir({
            open: true,
            width: 'md',
            propsForm: { data: null, ctl: ctl },
            formulario: ModalAddEditUser,
            aceptar: (data) => {
                data.idRol = data.rol.idRol;
                data.idpuesto = data.puesto?.idpuesto || null;
                data.idsede = data.sede?.idsede || null;
                let buscarNombreUsuario = users.filter((user) => user.usuario.toLowerCase() == data.usuario.toLowerCase());
                let buscarCorreoUsuario = users.filter((user) => user.correo?.toLowerCase() == data.correo.toLowerCase());
                let buscarTelefonoUsuario = users.filter((user) => user.telefono?.toLowerCase() == data.telefono.toLowerCase());
                // validamos los campos que tienen contraint de tipo 'UNIQUE'
                if (buscarNombreUsuario.length > 0) {
                    setMensaje({ ...mensaje, open: true, status: -1, value: 'El nombre de usuario ya existe' });
                } else if (buscarCorreoUsuario.length > 0) {
                    setMensaje({ ...mensaje, open: true, status: -1, value: 'El correo electrónico ya existe' });
                } else if (buscarTelefonoUsuario.length > 0) {
                    setMensaje({ ...mensaje, open: true, status: -1, value: 'El telefono ingresado ya existe' });
                } else {
                    serviciosUser.addUser((nuevaData) => {
                        actualizarData(nuevaData.usuarios);
                        context.formulario.cerrar();
                    }, data);
                }
            }
        });
    };
    const abrirConfirmarCambio = (usuario) => (e) => {
        let texto = `¿Estás seguro de volver a activar al usuario ${usuario.usuario}?`;
        let status = 1;
        if (usuario.idestatus === '1') {
            texto = `¿Estás seguro de desactivar al usuario ${usuario.usuario}, un usuario inactivo no puede ingresar al sistema?`;
            status = 0;
        }
        setConfirm({
            open: true,
            text: texto,
            aceptar: () =>
                serviciosUser.cambiarEstatusUsuario(
                    (nuevaData) => {
                        actualizarData(nuevaData.usuarios);
                        setConfirm({ open: false });
                    },
                    { idUser: usuario.idUsuario, idestatus: status }
                )
        });
    };
    const abrirSedesUsuario = (usuario) => {
        serviciosUser.getSedesByusuario(
            (data) => {
                setModalGeneral({
                    open: true,
                    titulo: `Sedes activas del usuario: ${usuario.nombre}`,
                    props: { usuario: { ...usuario, sedes: data.sedes }, actualizarFrente: actualizarData },
                    Formulario: UsuarioSedes
                });
            },
            {
                idusuario: usuario.idUsuario
            }
        );
    };
    // Config
    const acciones = [{ label: 'Nuevo usuario', icon: <AddBox />, click: abrirNuevoUsuario, disabled: !permisos.crear }];
    const accionesFila = [
        { label: 'Editar', onClick: abrirEditarUsuario, enabled: true },
        {
            label: 'Modificar sedes',
            onClick: abrirSedesUsuario,
            enabled: (rowUsuario) => rowUsuario.permisos?.split(',').includes('fltSd')
        }
    ];
    useEffect(() => {
        serviciosUser.userInt((ct) => {
            setUsers(ct.usuarios || []);
            setCtl({ ...ctl, puesto: ct.puestos, rol: ct.roles, sedes: ct.sedes });
        });
    }, []);
    return (
        <MainCard title="Usuarios">
            <Tabla
                rows={users}
                columns={headers(abrirConfirmarCambio).users}
                accionesTabla={acciones}
                accionesFila={accionesFila}
                columnKey="idUsuario"
            />
            <AvisoConfirmacion
                open={confirm.open}
                title="Advertencia"
                text={confirm.text}
                accept={confirm.aceptar}
                cancel={() => setConfirm({ open: false })}
            />
            <ModalGeneral
                open={modalGeneral.open}
                titulo={modalGeneral.titulo}
                OnCerrar={() => setModalGeneral({ ...modalGeneral, open: false })}
            >
                {modalGeneral.Formulario && <modalGeneral.Formulario {...modalGeneral.props} />}
            </ModalGeneral>
        </MainCard>
    );
};

export default Usuario;
