import React from 'react';
import { Close } from '@mui/icons-material';
import { Dialog, DialogContent, Grid, Tooltip, IconButton, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    background: {
        backgroundColor: theme.palette.secondary.dark,
        borderRadius: '12px',
        border: '1px solid #fff'
    },
    title: {
        color: '#fff'
    },
    title2: {
        textAlign: 'center',
        color: '#dddded'
    },
    text: {
        marginBottom: 20,
        color: '#bebddc'
    }
}));

export default function SimpleDialog({ open, titulo, data, close, maxWidth, ...props }) {
    const classes = useStyles();
    return (
        <Dialog fullWidth maxWidth={maxWidth} onClose={close} open={open}>
            <DialogContent className={classes.background}>
                <Grid container>
                    <Grid item xs={11}>
                        <Typography sx={{ display: 'flex', justifyContent: 'center', color: '#dddded' }} component="span" variant="h4">
                            {titulo}
                        </Typography>
                    </Grid>
                    <Grid item xs={1}>
                        <Tooltip title="Cerrar">
                            <IconButton onClick={close}>
                                <Close color="primary" />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </Grid>
                {data.content}
            </DialogContent>
        </Dialog>
    );
}
