import React, { useState, useEffect, useContext } from 'react';
import { AddBox } from '@mui/icons-material';
import { Tabla } from 'ui-component';
import headers from './components/headers';
import MainCard from 'ui-component/cards/MainCard';
import LayautContext from 'Context/ContextLayaut';
import AvisoConfirmacion from 'ui-component/AvisoConfirmacion';
import { ServiciosEncuesta } from './components';

const Encuesta = ({ permisos }) => {
    //
    const context = useContext(LayautContext);
    const serviciosEncuesta = ServiciosEncuesta();

    // States

    const [encuesta, setEncuesta] = useState([]);
    const [ctl, setCtl] = useState({ estatusEncuesta: [] });
    const [confirm, setConfirm] = useState({ open: false });

    // Handle
    const actualizarData = (nuevaGrupo = []) => {
        const datausuario = encuesta.map((oc) => oc.idGrupo);
        nuevaGrupo.forEach((usuarioI) => {
            const indice = datausuario.indexOf(usuarioI.idGrupo);
            if (indice >= 0) encuesta.splice(indice, 1, usuarioI);
            else encuesta.splice(0, 0, usuarioI);
        });
        setEncuesta([...encuesta]);
    };

    const abrirConfirmarCambio = (encuesta) => (e) => {
        let texto = `¿Estás seguro de volver a activar la encuesta ${encuesta.nombre}?`;
        let status = 1;
        if (encuesta.estatus === '1') {
            texto = `¿Estás seguro de desactivar la encuesta ${encuesta.nombre}?`;
            status = 0;
        }
        setConfirm({
            open: true,
            text: texto,
            aceptar: () =>
                serviciosEncuesta.cambiarEstatusEncuesta(
                    (nuevaData) => {
                        actualizarData(nuevaData.encuesta);
                        setConfirm({ open: false });
                    },
                    { ids: encuesta.idGrupo, estatus: status }
                )
        });
    };

    // Config
    // const acciones = [{ label: 'Nuevo usuario', icon: <AddBox />, click: abrirNuevoGrupo }];
    // const accionesFila = [{ label: 'Editar', onClick: abrirEditarGrupo, enabled: true }];
    useEffect(() => {
        serviciosEncuesta.encuestaInt((ct) => {
            setEncuesta(ct.encuesta || []);
            // setCtl({ ...ctl, estatusProveedor: ct.estatusProveedor });
        });
    }, []);

    return (
        <MainCard title="Encuesta">
            <Tabla
                rows={encuesta}
                columns={headers(abrirConfirmarCambio, ctl).encuesta}
                // accionesTabla={acciones}
                // accionesFila={accionesFila}
                columnKey="idEncuesta"
            />
        </MainCard>
    );
};

export default Encuesta;
