import React, { useState } from 'react';

import { MoreHoriz, EventBusy, AccessTime, LocalAtm, LocationCity, Star } from '@mui/icons-material';
import { List, ListItem, Grid, Typography, IconButton, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';

import Modal from '../Modal';
import ListCursosMoreInfo from '../ListCursosMoreInfo';

const useStyles = makeStyles((theme) => ({
    infoUser: {
        borderRadius: '8px',
        backgroundColor: '#fff'
    },
    listItem: {
        backgroundColor: theme.palette.secondary.dark,
        color: '#fff',
        borderRadius: '12px',
        border: '1px solid #fff',
        padding: 10
    },
    listItemText: {
        color: '#fff'
    }
}));

export default function CardCurso({ data }) {
    const classes = useStyles();
    const curso = data?.curso;
    const [modal, setModal] = useState({ open: false, data: { curso: {}, content: <></> }, close: () => {} });

    // Handle
    const closeShowMore = () => {
        setModal({ open: false, data: { curso: {}, content: <></> }, close: () => {} });
    };

    // Functions
    const showMore = (curso) => {
        setModal({
            open: true,
            titulo: 'Información del curso',
            maxWidth: 'xs',
            data: { curso: curso, content: <ListCursosMoreInfo data={curso} close={closeShowMore} /> },
            close: closeShowMore
        });
    };

    return (
        <>
            <Grid className={classes.listItem} container>
                <Grid item xs={10}>
                    <Grid container>
                        <Grid item xs={12} sx={{ mb: 1 }}>
                            <Typography sx={{ display: 'inline', color: '#dddded' }} component="span" variant="h4">
                                {curso?.titulo}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography sx={{ display: 'inline', color: '#bebddc' }} component="span" variant="h5">
                                {curso?.subtitulo}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'end' }}>
                    <Tooltip title="Mostrar más información.">
                        <IconButton
                            onClick={() => {
                                showMore(curso);
                            }}
                        >
                            <MoreHoriz color="primary" />
                        </IconButton>
                    </Tooltip>
                </Grid>
                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'start', pt: 1 }}>
                    <Tooltip title="Duración.">
                        <div>
                            <AccessTime color="primary" />
                            <Typography sx={{ display: 'inline', color: '#dddded', mr: 1 }} variant="h4">
                                {curso?.duracionCurso}h
                            </Typography>
                        </div>
                    </Tooltip>
                    <Tooltip title="Costo.">
                        <div>
                            <LocalAtm color="primary" />
                            <Typography sx={{ display: 'inline', color: '#dddded', mr: 1 }} component="span" variant="h4">
                                ${curso?.costo}
                            </Typography>
                        </div>
                    </Tooltip>
                    <Tooltip title="Nivel requerido.">
                        <div>
                            <Star color="primary" />
                            <Typography sx={{ display: 'inline', color: '#dddded', mr: 1 }} component="span" variant="h4">
                                {curso?.nivelRequerido}
                            </Typography>
                        </div>
                    </Tooltip>
                    {curso?.extemporaneo ? (
                        <Tooltip sx={{ mr: 1 }} title="Curso extemporaneo.">
                            <EventBusy color="primary" />
                        </Tooltip>
                    ) : null}
                    {curso?.interno ? (
                        <Tooltip sx={{ mr: 1 }} title="Curso interno.">
                            <LocationCity color="primary" />
                        </Tooltip>
                    ) : null}
                </Grid>
            </Grid>
            <Modal open={modal.open} titulo={modal.titulo} maxWidth={modal.maxWidth} data={modal.data} close={modal.close} />
        </>
    );
}
