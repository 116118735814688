import React, { useEffect, useState } from 'react';
// material-ui
import { useTheme } from '@mui/material/styles';
import { Backdrop, CircularProgress } from '@mui/material';

// project imports
import Services from 'utils/serviciosVentas';
import Inicio from './Inicio';
import HomeVentas from './HomeVentas';

// ==============================|| DEFAULT DASHBOARD ||============================== //

const Index = ({ permisos, usuario, option }) => {
    const services = Services();
    const theme = useTheme();

    // States
    const [arbolCursos, setArbolCursos] = useState({
        grupos: [],
        cursos: [],
        sesion: [],
        temario: [],
        calificacion: 0,
        aciertos: 0,
        desaciertos: 0,
        actividades: []
    });
    const [modulo, setModulo] = useState(0);
    const [colaboradores, setColaboradores] = useState([]);
    const [ranking, setRanking] = useState({ posicion: 0, topTen: [] });
    const [videoData, setVideoData] = useState({});
    const [contacto, setContacto] = useState([]);
    const [cargando, setCargando] = useState({ open: false });
    const [hasCursos, setHasCursos] = useState(true);

    // Services
    const getArbolCursos = () => {
        setCargando({ open: true });
        services.Allcursos(
            (data) => {
                setArbolCursos({
                    ...arbolCursos,
                    cursos: data.cursos
                });
                if (data.cursos.length == 0) setHasCursos(false);
                setRanking(data.ranking);
                setContacto({
                    nombre: data.datosGenerales?.nasesor,
                    telefono: data.datosGenerales?.telefono,
                    correo: data.datosGenerales?.email
                });
                if (data.datosGenerales) setVideoData({ linkDrop: data.datosGenerales.linkDrop });
                setCargando({ open: false });
            },
            { idColaborador: usuario.idColaborador, idGrupo: usuario.idGrupo, option }
        );
    };

    // const getColaboradores = () => {
    //     services.colaboradoresMiSede((colaboradores) => setColaboradores(colaboradores));
    // };

    // Functions
    const selectCurso = (idCurso) => {
        setModulo(idCurso);
    };

    const unselectCurso = () => {
        setModulo(0);
    };

    useEffect(() => {
        getArbolCursos();
        // getColaboradores();
    }, []);

    return (
        <div>
            {modulo ? (
                <HomeVentas
                    arbolCursos={arbolCursos}
                    setArbolCursos={setArbolCursos}
                    ranking={ranking}
                    info={contacto}
                    // colaboradores={colaboradores}
                    curso={arbolCursos.cursos.find((curso) => curso.idCurso == modulo)}
                    unselectCurso={unselectCurso}
                    permisos={permisos}
                    usuario={usuario}
                    option={option}
                />
            ) : (
                <Inicio
                    cursos={arbolCursos.cursos}
                    selectCurso={selectCurso}
                    ranking={ranking}
                    info={contacto}
                    videoData={videoData}
                    hasCursos={hasCursos}
                />
            )}
            <Backdrop open={cargando.open}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    );
};

export default Index;
