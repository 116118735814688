const columns = {
    personalAdmin: [
        { label: 'No. Empleado', name: 'num_empleado', filtrar: true },
        { label: 'Puesto', name: 'nom_puesto', filtrar: true },
        { label: 'Sede', name: 'nom_sede', filtrar: true },
        { label: 'Nombre', name: 'nombre_persona', filtrar: true },
        // { label: 'CURP', name: 'CURP', filtrar: true },
        { label: 'Correo', name: 'email_personal', filtrar: true },
        { label: 'Tipo puesto', name: 'tipo_puesto', filtrar: true },
        { label: 'Fecha ingreso', name: 'fecha_ingreso', type: { opcion: 'date', accion: 1 }, filtrar: true },
        { label: 'Fecha contrato', name: 'fecha_contrato', type: { opcion: 'date', accion: 1 }, filtrar: true }
    ],

    personalOper: [
        { label: 'No. Empleado', name: 'num_empleado', filtrar: true },
        { label: 'Puesto', name: 'nom_puesto', filtrar: true },
        { label: 'Sede', name: 'nom_sede', filtrar: true },
        { label: 'Nombre', name: 'nombre_persona', filtrar: true },
        { label: 'Correo', name: 'email_personal', filtrar: true },
        { label: 'Fecha ingreso', name: 'fecha_ingreso', type: { opcion: 'date', accion: 1 }, filtrar: true },
        { label: 'Fecha contrato', name: 'fecha_contrato', type: { opcion: 'date', accion: 1 }, filtrar: true }
    ],

    personalVent: [
        { label: 'No. Empleado', name: 'num_empleado', filtrar: true },
        { label: 'Puesto', name: 'nom_puesto', filtrar: true },
        { label: 'Sede', name: 'nom_sede', filtrar: true },
        { label: 'Nombre', name: 'nombre_persona', filtrar: true },
        { label: 'Correo', name: 'email_personal', filtrar: true },
        { label: 'Fecha ingreso', name: 'fecha_ingreso', type: { opcion: 'date', accion: 1 }, filtrar: true },
        { label: 'Fecha contrato', name: 'fecha_contrato', type: { opcion: 'date', accion: 1 }, filtrar: true }
    ]
};

export default columns;
