import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@mui/styles';
import { colors } from '@mui/material';
import { useForm } from 'react-hook-form';
import { Tabla } from 'ui-component';
import MainCard from 'ui-component/cards/MainCard';
import Services from './Components/services';
import headers from './Components/headers';
import localUtils from './Components/utils';
import FiltrarByDia from './Components/FiltrarByDia';

const useStyles = makeStyles((theme) => ({
    paper: {
        textAlign: 'center'
    },
    cancelar: {
        color: colors.red[500]
    },
    aceptar: {
        color: colors.green[500]
    },
    texto: {
        color: colors.indigo[900],
        textAlign: 'center',
        fontSize: 13
    },
    textoPequeno: {
        color: colors.indigo[900],
        textAlign: 'center',
        fontSize: 9
    },
    textoTabla: {
        color: colors.indigo[900],
        textAlign: 'left',
        fontSize: 9
    },
    textoTablaTH: {
        color: colors.indigo[900],
        textAlign: 'left',
        fontSize: 11
    },
    cantidadTabla: {
        color: colors.indigo[900],
        textAlign: 'right',
        fontSize: 9
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200
    }
}));

const PersonalVent = ({ ...props }) => {
    const classes = useStyles();
    const services = Services();
    const [day, setDay] = useState({ day: 'Todos' });

    const [personal, setPersonal] = useState({ personal: [], allData: [] });

    // Handle
    const handleChangeDay = (event) => {
        setDay({ [event.target.name]: event.target.value });
    };

    // Functions
    const changeCurrentDay = () => {
        setDay({ day: localUtils.getCurrentDate('Vent').day });
    };

    const filterForDay = (day, allData) => {
        if (day == 'Todos') {
            setPersonal({ personal: allData, allData: allData });
            return false;
        }

        const daysToFilter = localUtils.getDiaXDelMes(day);

        const dataFiltered = allData.filter((per) => daysToFilter.days.includes(per.fecha_ingreso));
        setPersonal({ ...personal, personal: dataFiltered });
        return true;
    };

    // Services
    const getInfoInicialVent = async () => {
        const res = await services.getInfoInicialVent((res) => filterForDay(day.day, res.personal));
        changeCurrentDay();
    };

    const cargarFunciones = () => {
        getInfoInicialVent();
    };

    useEffect(() => {
        cargarFunciones();
    }, []);

    useEffect(() => {
        filterForDay(day.day, personal.allData);
    }, [day]);

    // Config
    const acciones = [{ component: FiltrarByDia, props: { value: day.day, onChange: handleChangeDay, estatus: 1 } }];
    const accionesFila = [
        { label: 'Editar', onClick: (row) => {}, enabled: true },
        { label: 'Eliminar', onClick: (row) => {}, enabled: true }
    ];
    return (
        <MainCard title="Introducción Ventas">
            <Tabla rows={personal.personal} accionesTabla={acciones} columns={headers.personalVent} columnKey="idpersona" />
        </MainCard>
    );
};

export default PersonalVent;
