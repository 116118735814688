import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, colors } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    btnAceptar: {
        color: theme.palette.btn.green,
        '&:hover': {
            backgroundColor: theme.palette.btn.green,
            color: theme.palette.btn.white
        }
    },
    btnCancelar: {
        color: theme.palette.btn.red,
        '&:hover': {
            backgroundColor: theme.palette.btn.red,
            color: theme.palette.btn.white
        }
    }
}));
const ModalFormulario = (props) => {
    const {
        open,
        titulo,
        ContenidoMensaje,
        OnCancelar,
        OnCerrar,
        width,
        bloqueo,
        aceptar,
        children,
        mensajeConfirmacion,
        isVer,
        btnCancelarName,
        btnAceptarName
    } = props;
    const classes = useStyles();
    const methods = useForm();
    const [mAdvertencia, setMAdvertencia] = useState({ open: false, data: null });
    const cerrarAdvertencia = () => setMAdvertencia({ open: false, data: null });
    const abrirAdvertencia = (data) => setMAdvertencia({ open: true, data: data });
    const clickConfimar = () => {
        aceptar(mAdvertencia.data);
    };

    useEffect(() => {
        if (!open) {
            if (mAdvertencia.open) cerrarAdvertencia();
        } else methods.reset();
    }, [open]);

    const Acctions = () => {
        if (isVer) {
            return (
                <Button onClick={OnCancelar} className={classes.btnCancelar}>
                    Cerrar
                </Button>
            );
        }
        return (
            <>
                <Button id="btnCancelar" onClick={OnCancelar} className={classes.btnCancelar}>
                    {btnCancelarName}
                </Button>
                <Button id="btnAceptar" type="submit" disabled={bloqueo} className={classes.btnAceptar}>
                    {btnAceptarName}
                </Button>
            </>
        );
    };

    return (
        <>
            <Dialog open={open} onClose={OnCerrar} scroll="body" fullWidth maxWidth={width} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">{titulo}</DialogTitle>
                <FormProvider {...methods}>
                    <form autoComplete="off" onSubmit={methods.handleSubmit(abrirAdvertencia)}>
                        <DialogContent>{children}</DialogContent>
                        <DialogActions>
                            <Acctions />
                        </DialogActions>
                    </form>
                </FormProvider>
            </Dialog>
            <Dialog open={mAdvertencia.open} onClose={cerrarAdvertencia} fullWidth maxWidth="sm">
                <DialogTitle title="Adevertencia" />

                <DialogContent>
                    <DialogContentText>{mensajeConfirmacion}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button id="btnRegresar" onClick={cerrarAdvertencia} className={classes.btnCancelar}>
                        Regresar
                    </Button>
                    <Button id="btnConfirmar" disabled={bloqueo} className={classes.btnAceptar} onClick={clickConfimar}>
                        Confirmar
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};
ModalFormulario.propTypes = {
    open: PropTypes.bool.isRequired,
    titulo: PropTypes.string.isRequired,
    ContenidoMensaje: PropTypes.string,
    OnCancelar: PropTypes.func.isRequired,
    children: PropTypes.node,
    aceptar: PropTypes.func.isRequired,
    width: PropTypes.string,
    bloqueo: PropTypes.bool,
    mensajeConfirmacion: PropTypes.string,
    isVer: PropTypes.bool,
    btnCancelarName: PropTypes.string,
    btnAceptarName: PropTypes.string
};
ModalFormulario.defaultProps = {
    width: 'sm',
    bloqueo: false,
    mensajeConfirmacion: 'Asegúrese de que todos los campos sean correctos, ¿Está seguro de continuar?',
    isVer: false,
    btnCancelarName: 'Cancelar',
    btnAceptarName: 'Aceptar'
};
export default ModalFormulario;
