import React from 'react';
import { Close, School, Group } from '@mui/icons-material';
import { Grid, Typography, IconButton, Tooltip, List, ListItem } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    infoUser: {
        borderRadius: '8px',
        backgroundColor: theme.palette.secondary.main
    },
    listItem: {
        backgroundColor: theme.palette.primary.dark,
        color: '#fff',
        borderRadius: '12px',
        borderColor: theme.palette.primary.dark,
        marginBottom: '5px'
    },
    listItemText: {
        color: '#fff'
    },
    iconColor: {
        color: 'gold',
        marginLeft: 10
    },
    iconBottom: {
        color: theme.palette.primary.main,
        marginRight: 10
    }
}));

export default function ListIntegrantes({ data, close, ...props }) {
    const classes = useStyles();

    return (
        <List
            className={classes.infoUser}
            sx={{
                width: '100%',
                bgcolor: 'background.paper',
                position: 'relative',
                overflow: 'auto',
                maxHeight: 550,
                color: '#616161'
            }}
        >
            {data.grupo.integrantes.map((integrante) => (
                <ListItem className={classes.listItem} onClick={() => {}} alignItems="flex-start">
                    <Grid container>
                        <Grid item xs={10}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Typography sx={{ display: 'inline', color: '#dddded' }} component="span" variant="h4">
                                        {integrante.nombre_persona}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography sx={{ display: 'inline', color: '#bebddc' }} component="span" variant="h5">
                                        {integrante.nom_puesto}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'end' }}>
                            {integrante.isLider ? (
                                <Tooltip title="Es es lider de este grupo.">
                                    <School className={classes.iconColor} />
                                </Tooltip>
                            ) : null}
                        </Grid>
                    </Grid>
                </ListItem>
            ))}
        </List>
    );
}
