import React, { useEffect } from 'react';
import { DialogContent, Grid, TextField } from '@mui/material';
import { useFormContext } from 'react-hook-form';

import { useTheme } from '@mui/material/styles';

const ModalAddEditPagina = ({ open, isAdd, data, ctl, setCargando, setMensaje, handleClose, ...props }) => {
    const {
        register,
        setValue,
        formState: { errors },
        control
    } = useFormContext();

    const theme = useTheme();

    useEffect(() => {
        if (!isAdd) {
            if (data?.idpagina) setValue('idpagina', data.idpagina);
            if (data?.ruta) setValue('ruta', data.ruta);
        }
    }, []);

    return (
        <DialogContent>
            <Grid container spacing={2}>
                <Grid item xs={6} md={2}>
                    <TextField
                        fullWidth
                        label="Id pagina *"
                        placeholder="ejeEje"
                        InputLabelProps={{
                            shrink: true
                        }}
                        size="small"
                        {...register('idpagina', { required: { value: true, message: 'Campo requerido' } })}
                        error={Boolean(errors.idpagina)}
                        helperText={errors.idpagina?.message}
                        defaultValue={data?.idpagina}
                        disabled={!isAdd}
                    />
                </Grid>
                <Grid item xs={6} md={10}>
                    <TextField
                        fullWidth
                        label="Nombre *"
                        placeholder="Ejemplo"
                        InputLabelProps={{
                            shrink: true
                        }}
                        size="small"
                        {...register('npagina', { required: { value: true, message: 'Campo requerido' } })}
                        error={Boolean(errors.npagina)}
                        helperText={errors.npagina?.message}
                        defaultValue={data?.npagina}
                    />
                </Grid>
                <Grid item xs={6} md={4}>
                    <TextField
                        fullWidth
                        label="Ruta *"
                        placeholder="/ejemplo"
                        InputLabelProps={{
                            shrink: true
                        }}
                        size="small"
                        {...register('ruta', { required: { value: true, message: 'Campo requerido' } })}
                        error={Boolean(errors.ruta)}
                        helperText={errors.ruta?.message}
                        defaultValue={data?.ruta}
                        disabled={!isAdd}
                    />
                </Grid>
                <Grid item xs={6} md={4}>
                    <TextField
                        fullWidth
                        label="Id categoría *"
                        placeholder="eje"
                        InputLabelProps={{
                            shrink: true
                        }}
                        size="small"
                        {...register('idcategoria', { required: { value: false, message: 'Campo requerido' } })}
                        error={Boolean(errors.idcategoria)}
                        helperText={errors.idcategoria?.message}
                        defaultValue={data?.idcategoria}
                    />
                </Grid>
                <Grid item xs={6} md={2}>
                    <TextField
                        fullWidth
                        label="Orden *"
                        placeholder="1"
                        InputLabelProps={{
                            shrink: true
                        }}
                        size="small"
                        {...register('orderby', { required: { value: false, message: 'Campo requerido' } })}
                        error={Boolean(errors.orderby)}
                        helperText={errors.orderby?.message}
                        defaultValue={data?.orderby}
                    />
                </Grid>
            </Grid>
        </DialogContent>
    );
};

export default ModalAddEditPagina;
