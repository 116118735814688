import LayautContext from 'Context/ContextLayaut';
import { useContext } from 'react';

const ServiciosCatalogo = () => {
    const context = useContext(LayautContext);

    return {
        getReporteModulo: context.llamarServidor('Reportes/getReporteModulo/'),
        grupoInt: context.llamarServidor('Grupo/getGrupoIntTodos')
    };
};
export default ServiciosCatalogo;
