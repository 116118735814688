import LayautContext from 'Context/ContextLayaut';
import { useContext } from 'react';

const ServicioProveedor = () => {
    const context = useContext(LayautContext);
    return {
        proveedorInt: context.llamarServidor('Grupo/getGrupoInt'),
        // insertGrupo: context.llamarServidorRespuesta('Grupo/insertGrupo'),
        // updateGrupo: context.llamarServidorRespuesta('Grupo/updateGrupo'),
        cambiarEstatusProveedor: context.llamarServidorRespuesta('Grupo/cambiarEstatusGrupo')
    };
};

export default ServicioProveedor;
