import React, { useEffect, useRef } from 'react';
import { Button, ButtonGroup, Grid, TextField } from '@mui/material';

import InputCreateHtml from './InputCreateHtml';
import { useFormContext } from 'react-hook-form';
import { FormDropZone } from 'ui-component';
import { BigPlayButton, ControlBar, VolumeMenuButton, Player } from 'video-react';
import { Delete } from '@mui/icons-material';

const ctlTipoActidad = [
    { id: 1, nombre: 'Lectura' },
    { id: 2, nombre: 'Video' },
    { id: 3, nombre: 'Examen' }
];
const FormDetalleActividad = ({ open, isAdd, data, ctl, isVer, ...props }) => {
    const {
        register,
        watch,
        formState: { errors },
        control,
        setValue,
        getValues,
        unregister
    } = useFormContext();

    const idTipoActividad = watch('idTipoActividad') === undefined ? data?.idTipoActividad : watch('idTipoActividad');
    const clickEliminarVideoActivo = () => setValue('videoActivo', null);
    useEffect(() => {
        if (data && data?.idTipoActividad == '2') {
            register('videoActivo');
            setValue('videoActivo', data?.linkVideo);
        } else unregister('videoActivo');
    }, [register]);
    return (
        <Grid container spacing={2}>
            {!isVer && (
                <>
                    <Grid item xs={6}>
                        <TextField
                            label="Título *"
                            size="small"
                            fullWidth
                            {...register('titulo', {
                                required: { value: true, message: 'Campo requerido' },
                                validate: (value) => value.match(/(^\s+|\s+$)/g) == null || 'El inicio o el final no pueden ser espacios'
                            })}
                            error={Boolean(errors.titulo)}
                            helperText={errors.titulo?.message}
                            defaultValue={data?.titulo}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            label="Tipo de material *"
                            size="small"
                            fullWidth
                            select
                            SelectProps={{ native: true }}
                            disabled={data?.idTipoActividad}
                            {...register('idTipoActividad', { required: { value: !data?.idTipoActividad, message: 'Campo requerido' } })}
                            error={Boolean(errors.idTipoActividad)}
                            helperText={errors.idTipoActividad?.message}
                            defaultValue={data?.idTipoActividad}
                            multiline
                        >
                            <option />
                            {ctlTipoActidad.map((opt) => (
                                <option value={opt.id}>{opt.nombre}</option>
                            ))}
                        </TextField>
                    </Grid>
                </>
            )}
            {idTipoActividad == 2 &&
                (watch('videoActivo') == null && [0, null, undefined].includes(isVer) ? (
                    <Grid item xs={12}>
                        <FormDropZone control={control} name="video" rules={{ required: true }} />
                    </Grid>
                ) : (
                    <Grid item xs={12} container>
                        {!isVer && (
                            <Grid item xs={12}>
                                <ButtonGroup variant="contained" aria-label="outlined primary button group">
                                    <Button color="error" onClick={clickEliminarVideoActivo}>
                                        <Delete />
                                    </Button>
                                </ButtonGroup>
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            <Player src={data?.linkVideo}>
                                <BigPlayButton position="center" />
                                {/* <ControlBar autoHide className="my-class">
                                    <VolumeMenuButton />
                                    <VolumeMenuButton vertical />
                                </ControlBar> */}
                            </Player>
                        </Grid>
                    </Grid>
                ))}
            {idTipoActividad == 1 && (
                <Grid item xs={12}>
                    <InputCreateHtml control={control} name="lectura" label="Lectura" defaultValue={data?.lectura} />
                </Grid>
            )}
        </Grid>
    );
};

export default FormDetalleActividad;
