import React, { useState } from 'react';
import { School, Group } from '@mui/icons-material';
import { List, ListItem, Grid, Typography, Tooltip, IconButton } from '@mui/material';
import { makeStyles } from '@mui/styles';

import Modal from './Modal';
import ListIntegrantes from './ListIntegrantes';

const useStyles = makeStyles((theme) => ({
    infoUser: {
        borderRadius: '8px',
        backgroundColor: '#fff'
    },
    listItem: {
        backgroundColor: theme.palette.secondary.dark,
        color: '#fff',
        borderRadius: '12px',
        border: '1px solid #fff'
    },
    listItemText: {
        color: '#fff'
    },
    iconColor: {
        color: 'gold',
        marginLeft: 10
    },
    iconBottom: {
        color: theme.palette.primary.main,
        marginRight: 10
    }
}));

export default function ListGrupos({ data, fn, ...props }) {
    const classes = useStyles();

    const [modal, setModal] = useState({ open: false, data: {}, close: () => {}, maxWidth: 'xs' });

    // Handle
    const closeModal = () => {
        setModal({ open: false, data: {}, close: () => {}, maxWidth: 'xs' });
    };

    // Functions
    const openIntegrantes = (grupo) => {
        setModal({
            open: true,
            titulo: grupo.nombre,
            data: { content: <ListIntegrantes data={{ grupo: grupo }} /> },
            close: closeModal,
            maxWidth: 'xs'
        });
    };

    return (
        <>
            <List
                className={classes.infoUser}
                sx={{
                    width: '100%',
                    bgcolor: 'background.paper',
                    position: 'relative',
                    overflow: 'auto',
                    maxHeight: 550,
                    color: '#616161'
                }}
            >
                {data.grupos?.map((grupo) => (
                    <ListItem className={classes.listItem} onClick={() => fn.selectGroup(grupo)} alignItems="flex-start">
                        <Grid container>
                            <Grid item xs={10}>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <Typography sx={{ display: 'inline', color: '#dddded' }} component="span" variant="h4">
                                            {grupo.nombre}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography sx={{ display: 'inline', color: '#bebddc' }} component="span" variant="h5">
                                            {grupo.cantCursos} curso(s) asignado(s)
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography sx={{ display: 'inline', color: '#bebddc' }} component="span" variant="h5">
                                            {grupo.integrantes.length} integrantes(s)
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'end' }}>
                                {grupo.isLider ? (
                                    <Tooltip title="Es es lider de este grupo.">
                                        <School className={classes.iconColor} />
                                    </Tooltip>
                                ) : null}
                                {/* <Tooltip title="Enviar encuesta de satisfacción.">
                                <School className={classes.iconColor} />
                            </Tooltip> */}
                            </Grid>
                            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'start' }}>
                                <Tooltip title="Integrantes.">
                                    <IconButton onClick={() => openIntegrantes(grupo)}>
                                        <Group className={classes.iconBottom} />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        </Grid>
                    </ListItem>
                ))}
            </List>
            <Modal open={modal.open} titulo={modal.titulo} data={modal.data} close={modal.close} maxWidth={modal.maxWidth} />
        </>
    );
}
