import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@mui/styles';
import {
    Container,
    Box,
    Typography,
    colors,
    Dialog,
    DialogActions,
    DialogTitle,
    DialogContent,
    DialogContentText,
    Grid,
    Divider,
    TextField,
    Slide,
    Button
} from '@mui/material';
import NumberFormat from 'react-number-format';
import { AddBox, DeleteForever, GetApp } from '@mui/icons-material';
import { useFormContext } from 'react-hook-form';

import PropTypes from 'prop-types';
import { Tabla } from 'ui-component';

import services from './services';

const useStyles = makeStyles((theme) => ({
    paper: {
        textAlign: 'center'
    },
    cancelar: {
        color: colors.red[500]
    },
    aceptar: {
        color: colors.green[500]
    },
    texto: {
        color: colors.indigo[900],
        textAlign: 'center',
        fontSize: 13
    },
    textoPequeno: {
        color: colors.indigo[900],
        textAlign: 'center',
        fontSize: 9
    },
    textoTabla: {
        color: colors.indigo[900],
        textAlign: 'left',
        fontSize: 9
    },
    textoTablaTH: {
        color: colors.indigo[900],
        textAlign: 'left',
        fontSize: 11
    },
    cantidadTabla: {
        color: colors.indigo[900],
        textAlign: 'right',
        fontSize: 9
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200
    }
}));

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const ModalEnvioFormulario = ({ open, data, cancel, setCargando, setMensaje, ...props }) => {
    const classes = useStyles();
    const { register, setValue, errors, control } = useFormContext();

    return (
        <div>
            {data && data.email_personal ? (
                <DialogContentText>
                    <Typography variant="h6" gutterBottom component="div">
                        {`Se enviará el formaulario a "${data && data.nombre_persona}".`}
                    </Typography>
                    <Typography variant="h6" gutterBottom component="div">
                        {' '}
                        Correo: {data && data.email_personal}{' '}
                    </Typography>
                </DialogContentText>
            ) : (
                <DialogContentText>
                    <Typography variant="h6" gutterBottom component="div">
                        {`"${data && data.nombre_persona}" no tiene ningún correo asignado, agregue un correo para enviar el
                                    formulario en esta ocación.`}
                    </Typography>
                    <Box
                        sx={{
                            p: 1,
                            mt: 2,
                            bgcolor: 'background.default',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center'
                        }}
                    >
                        <TextField
                            id="standard-basic"
                            label="Correo"
                            {...register('correo', { required: { value: true, message: 'Campo requerido' } })}
                            error
                            helperText="error"
                            defaultValue="@"
                        />
                    </Box>
                </DialogContentText>
            )}
        </div>
    );
};

export default ModalEnvioFormulario;
