import LayautContext from 'Context/ContextLayaut';
import { useContext } from 'react';

const ServiciosCalificacion = () => {
    const context = useContext(LayautContext);
    return {
        getTrainerCursosInt: context.llamarServidor('Trainer/getTrainerCursosInt'),
        getTrainerCursosPuesto: context.llamarServidor('Trainer/getTrainerCursosPuesto'),
        getTrainerCalificacion: context.llamarServidor('Trainer/getTrainerCalificacion'),
        getExamen: context.llamarServidor('Trainer/getExamen'),
        getExamenHistorial: context.llamarServidor('Trainer/getExamenHistorial'),
        restaurarExamen: context.llamarServidorRespuesta('Trainer/restaurarExamen'),
        editarCalificacion: context.llamarServidorRespuesta('Trainer/editarCalificacion')
    };
};

export default ServiciosCalificacion;
