import React from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { Autocomplete } from '@mui/material';
import { TextField } from '@mui/material';
import { isArray } from 'lodash-es';

const AutocompleteForm = ({
    id,
    control,
    name,
    label,
    defaultValue,
    disabled,
    required,
    onChange,
    hidden,
    rules,
    error,
    helperText,
    options,
    optionSelected,
    slotProps,
    disableClearable
}) => {
    let idloc = id || `input${name?.replaceAll('', '')}`;
    if (control)
        return (
            <Controller
                control={control}
                name={name}
                rules={rules}
                defaultValue={defaultValue || null}
                render={(props) => (
                    <Autocomplete
                        id={idloc}
                        onChange={(event, item) => {
                            //props.onChange(item);
                            if (onChange) onChange(item);
                        }}
                        size="small"
                        fullWidth
                        noOptionsText="No hay coincidencias"
                        disabled={disabled}
                        options={options || []}
                        value={props.value}
                        defaultValue={defaultValue || null}
                        getOptionLabel={
                            typeof optionSelected.label === 'function' ? optionSelected.label : (item) => item[optionSelected.label]
                        }
                        multiple={isArray(defaultValue)}
                        getOptionSelected={(option, value) =>
                            value == undefined || value === '' || option[optionSelected.id] === value[optionSelected.id]
                        }
                        slotProps={slotProps || { clearIndicator: { id: 'btnClear_' + name } }} // NO MODIFICAR FORMATO ID
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                hidden={hidden}
                                label={label}
                                required={!isArray(defaultValue) && required}
                                error={Boolean(error)}
                                helperText={helperText || error}
                            />
                        )}
                    />
                )}
            />
        );
    return (
        <Autocomplete
            id={idloc}
            onChange={(event, item) => {
                if (onChange) onChange(item);
            }}
            size="small"
            fullWidth
            noOptionsText="No hay coincidencias"
            disabled={disabled}
            options={options || []}
            defaultValue={defaultValue || null}
            disableClearable
            getOptionLabel={typeof optionSelected.label === 'function' ? optionSelected.label : (item) => item[optionSelected.label]}
            multiple={isArray(defaultValue)}
            getOptionSelected={(option, value) =>
                value == undefined || value === '' || option[optionSelected.id] === value[optionSelected.id]
            }
            slotProps={slotProps || { clearIndicator: { id: 'btnClear_' + name } }} // NO MODIFICAR FORMATO ID
            renderInput={(params) => (
                <TextField {...params} variant="outlined" hidden={hidden} label={label} error={Boolean(error)} helperText={error} />
            )}
        />
    );
};

AutocompleteForm.propTypes = {
    id: PropTypes.string,
    control: PropTypes.object,
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    disabled: PropTypes.bool,
    defaultValue: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.object), PropTypes.object]),
    rules: PropTypes.object,
    error: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.object).isRequired,
    optionSelected: PropTypes.shape({
        label: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired
    }),
    slotProps: PropTypes.object,
    disableClearable: PropTypes.bool
};

AutocompleteForm.defaultProps = {
    label: '',
    defaultValue: null,
    optionSelected: { id: 'default', label: 'default' },
    disableClearable: false
};

export default AutocompleteForm;
