import { School } from '@mui/icons-material';
import { IconButton } from '@mui/material';

const columns = {
    seguimiento: (idReporte, idSesion, data, option) => {
        let head = [];
        switch (idReporte) {
            // REPORTE DE CALIFICACIONES
            case 1:
                if (idSesion == 0 || idSesion == null)
                    head = [
                        { label: 'Sesión', name: 'NomSesion', filtrar: true },
                        { label: 'Actividad', name: 'nactividad', filtrar: true },
                        { label: 'Calificación', name: 'calificacion', filtrar: true }
                    ];
                else {
                    let keys = data?.length > 0 ? Object.keys(data[0]?.temarios || {}) : [];
                    keys.forEach((key) => {
                        head.push({ label: key, name: key, filtrar: true });
                    });
                }
                break;
            //REPORTE GLOBAL PROGRESO
            case 2:
                head = [
                    { label: 'Sede', name: 'sede', filtrar: true },
                    { label: 'Progreso', name: 'progreso', type: { opcion: 'numero', accion: 'percent' }, filtrar: true },
                    { label: 'Promedio', name: 'promedio', type: { opcion: 'numero' }, filtrar: true },
                    { label: 'Estatus', name: 'estatus', filtrar: true },
                    { label: 'Curso', name: 'titulo', filtrar: true }
                ];
                break;
            //REPORTE REGISTRADOS
            case 3:
                head = [
                    { label: 'SEDE', name: 'nom_sede', filtrar: true },
                    { label: 'DIRECCIÓN', name: 'direccion', filtrar: true },
                    { label: 'CP', name: 'cp', filtrar: true },
                    { label: 'COLONIA', name: 'colonia', filtrar: true },
                    { label: 'MUNICIPIO', name: 'municipio', filtrar: true },
                    { label: 'ESTADO', name: 'estado', filtrar: true },
                    { label: 'TELEFONO', name: 'telefono1', filtrar: true },
                    { label: 'EMAIL', name: 'email_personal', filtrar: true },
                    { label: 'EMAIL EMPRESA', name: 'email_empresarial', filtrar: true }
                ];
                break;
            //REPORTE DE GRUPOS
            //REPORTE DE CURSOS
            case 4:
                head = [
                    { label: option == 1 ? 'CURSO' : 'GRUPO', name: 'nombre_grupo', filtrar: true },
                    { label: 'SESIÓN', name: 'titulo', filtrar: true },
                    { label: 'PROMEDIO', type: { opcion: 'numero', accion: 'cantidad' }, name: 'Promedio', filtrar: true },
                    { label: 'PROGRESO', type: { opcion: 'numero', accion: 'percent' }, name: 'progreso', filtrar: true }
                ];
                break;
            //REPORTE DE FINALIZACIÓN
            case 5:
                head = [
                    { label: 'Sede', name: 'sede', filtrar: true },
                    { label: 'Fecha Fin', name: 'fechaFin', type: { opcion: 'date', accion: 1 }, filtrar: true },
                    { label: 'Estatus', name: 'estatus', filtrar: true },
                    { label: 'Curso', name: 'titulo', filtrar: true }
                ];
                break;
            //REPORTE ADMINISTRATIVO
            case 6:
                head = [
                    { label: 'Sede', name: 'sede', filtrar: true },
                    { label: 'Nom Video', name: 'ultimoVideo', filtrar: true },
                    { label: 'Promedio', name: 'promedio', filtrar: true },
                    { label: 'Estatus', name: 'estatus', filtrar: true }
                ];
                break;
            default:
                break;
        }

        head = [
            { label: '# empleado', name: 'num_empleado', filtrar: true },
            { label: 'Nombre', name: 'nombre_completo', filtrar: true },
            { label: 'CURP', name: 'CURP', filtrar: true },
            ...head
        ];

        return head;
    }
};

export default columns;
