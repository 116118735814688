import React, { useEffect, useState } from 'react';
import { Container, Grid, Button, TextField, ButtonGroup, Icon, Autocomplete } from '@mui/material';

import { ModalGeneral, Tabla } from 'ui-component';
import { Block, Delete, Publish, VisibilityOff } from '@mui/icons-material';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLightbulb } from '@fortawesome/free-solid-svg-icons';

const FormPermisosRelacion = ({ item, tipo, ctlg, setMensaje, setCargando, id, cambioPagina, cambioPermisos }) => {
    const [mAdvertencia, setMAdvertencia] = useState({ open: false, titulo: '' });
    const [pagina, setPagina] = useState({ pagina: null, permisos: [] });

    const onCambioPagina = (event, value) => {
        if (value) cambioPagina((prm) => setPagina({ pagina: value, permisos: prm }), value);
        else setPagina({ pagina: null, permisos: [] });
    };
    const servicios = {
        baja: (data) =>
            cambioPermisos((data) => {
                setPagina({ ...pagina, permisos: data });
                setMAdvertencia({ ...mAdvertencia, open: false });
            }, data)
    };
    const clickBaja = (estatus) => (seleccionados) => () => {
        let permisos = null;
        if (estatus == 0)
            permisos = seleccionados
                .filter((slc) => ['1', '-1'].includes(slc.idestatus))
                .map((prm) => ({ id: prm.idpermiso, idestatus: prm.idestatus == '-1' ? -2 : 0 }));
        else if (estatus == 1)
            permisos = seleccionados
                .filter((slc) => ['0', '-1'].includes(slc.idestatus))
                .map((prm) => ({ id: prm.idpermiso, idestatus: prm.idestatus == '-1' ? 2 : 1 }));
        else
            permisos = seleccionados
                .filter((slc) => ['1', '0'].includes(slc.idestatus))
                .map((prm) => ({ id: prm.idpermiso, idestatus: -1 }));
        setMAdvertencia({
            open: true,
            width: 'sm',
            titulo: 'Advertencia',
            paddingDisabled: false,
            mensaje: `${seleccionados.length} permiso(s) selccionados serán ${
                estatus ? 'activados' : 'desactivados'
            }, ¿Está seguro de continuar?`,
            aceptar: () =>
                servicios.baja({
                    [id]: item[id],
                    idpagina: pagina.pagina.idpagina,
                    estatus: estatus,
                    permisos: permisos
                })
        });
    };
    const cambioEstatusFila = (item) => (event) => clickBaja(event.target.checked)([item])();
    const cambioEstatusFilaButton = (item, estatus) => (event) => {
        if (item.idestatus != estatus) clickBaja(estatus)([item])();
    };
    const acciones = [
        { label: 'DESACTIVAR', icon: <Delete />, click: clickBaja(0), dataSelected: true },
        { label: 'ACTIVAR', icon: <Publish />, click: clickBaja(1), dataSelected: true }
    ];
    const columns = [
        { label: 'Permiso', name: 'npermiso', filtrar: true },
        ...(tipo == 'button'
            ? [
                  {
                      label: 'Estatus',
                      name: 'idestatus',
                      filtrar: true,
                      type: (row) => (
                          <ButtonGroup variant="contained" fullWidth size="small">
                              <Button color={row.idestatus === '1' ? 'secondary' : 'inherit'} onClick={cambioEstatusFilaButton(row, 1)}>
                                  <Icon component={FontAwesomeIcon} icon={faLightbulb} />
                              </Button>
                              <Button color={row.idestatus === '0' ? 'secondary' : 'inherit'} onClick={cambioEstatusFilaButton(row, 0)}>
                                  <Block />
                              </Button>
                              <Button
                                  color="inherit"
                                  disabled={!['1', '0'].includes(row.idestatus)}
                                  onClick={cambioEstatusFilaButton(row, -1)}
                              >
                                  <VisibilityOff />
                              </Button>
                          </ButtonGroup>
                      )
                  }
              ]
            : [
                  {
                      label: 'Estatus',
                      name: 'idestatus',
                      filtrar: false,
                      type: { opcion: 'switch', accion: cambioEstatusFila }
                  }
              ])
    ];
    useEffect(() => {}, []);

    return (
        <Container maxWidth={false}>
            <Grid container spacing={3} justify="center" alignItems="center">
                <Grid item xs={12}>
                    <Tabla
                        rows={pagina.permisos}
                        columns={columns}
                        filterGeneralDisabled
                        accionesTabla={acciones}
                        accionesFila={[]}
                        isSeleccionable
                        columnKey="idpermiso"
                        toolExtra={
                            <Grid container spacing={3}>
                                <Grid item xs={8}>
                                    <Autocomplete
                                        size="small"
                                        options={ctlg.pagina}
                                        onChange={onCambioPagina}
                                        getOptionLabel={(option) => option.npagina}
                                        renderInput={(params) => <TextField {...params} label="Página" variant="outlined" />}
                                    />
                                </Grid>
                            </Grid>
                        }
                    />
                </Grid>
            </Grid>
            <ModalGeneral
                open={mAdvertencia.open}
                titulo={mAdvertencia.titulo}
                width={mAdvertencia.width}
                paddingDisabled={mAdvertencia.paddingDisabled}
                ContenidoMensaje={mAdvertencia.mensaje}
                acciones={
                    <>
                        <Button color="error" onClick={(event) => setMAdvertencia({ ...mAdvertencia, open: false })}>
                            Cerrar
                        </Button>
                        {Boolean(mAdvertencia.aceptar) && (
                            <Button color="success" onClick={mAdvertencia.aceptar}>
                                Aceptar
                            </Button>
                        )}
                    </>
                }
            >
                {mAdvertencia.Formulario && (
                    <mAdvertencia.Formulario setCargando={setCargando} setMensaje={setMensaje} proveedor={mAdvertencia.proveedor} />
                )}
            </ModalGeneral>
        </Container>
    );
};

export default FormPermisosRelacion;
