import React, { useState, useEffect, useContext } from 'react';
import { AddBox, Reorder } from '@mui/icons-material';

import { ModalGeneral, Tabla } from 'ui-component';
import headers from './components/headers';
import LayautContext from 'Context/ContextLayaut';
import AvisoConfirmacion from 'ui-component/AvisoConfirmacion';
import { ServiciosCurso, ModalCostosGrupo } from './components';
import FormTemario from './components/FormTemario';
import FormOrdenar from './components/FormOrdenar';
import FormDependenciaTemario from './components/FormDependenciaTemario';

const Temario = ({ permisos, sesion, onCambioNivel }) => {
    //
    const context = useContext(LayautContext);
    const serviciosCursos = ServiciosCurso();

    const [temario, setTemario] = useState([]);
    const [ctl, setCtl] = useState({ estatusCurso: [] });
    const [confirm, setConfirm] = useState({ open: false });
    const [modal, setModal] = useState({ open: false, titulo: '' });
    // Handle
    const actualizarData = (nuevaCurso = []) => {
        const datausuario = temario.map((oc) => oc.idTemario);
        nuevaCurso.forEach((temarioI) => {
            const indice = datausuario.indexOf(temarioI.idTemario);
            if (indice >= 0) temario.splice(indice, 1, temarioI);
            else temario.splice(0, 0, temarioI);
        });
        setTemario([...temario]);
    };
    const abrirEditarCurso = (row) => {
        row = {
            ...row,
            estatusCurso: {
                idEstatus: row.idTemarioEstatus,
                nCursoEstatus: row.nCursoEstatus
            },
            extemporaneo: row.extemporaneo === 'Si' ? '1' : '0',
            interno: row.interno === 'Si' ? '1' : '0',
            puesto: row.npuesto ? { idpuesto: row.idpuesto, npuesto: row.npuesto } : null
        };
        context.formulario.abrir({
            open: true,
            titulo: `Editar la temario ${row.titulo}`,
            propsForm: { data: row, ctl: ctl },
            formulario: FormTemario,
            aceptar: (dataForm) => {
                serviciosCursos.updateTemario(
                    (res) => {
                        actualizarData(res.temario);
                        context.formulario.cerrar();
                    },
                    { idTemario: row.idTemario, ...dataForm, idSesion: sesion.idSesion }
                );
            }
        });
    };
    const abrirNuevoCurso = () => () => {
        context.formulario.abrir({
            open: true,
            width: 'sm',
            titulo: 'Nueva temario',
            propsForm: { data: null, ctl: ctl },
            formulario: FormTemario,
            aceptar: (dataForm) => {
                serviciosCursos.insertTemario(
                    (res) => {
                        actualizarData(res.temario);
                        context.formulario.cerrar();
                    },
                    { ...dataForm, orden: temario.length + 1, idSesion: sesion.idSesion }
                );
            }
        });
    };
    const abrirConfirmarCambio = (temario) => (e) => {
        let texto = `¿Estás seguro de volver a activar el temario ${temario.titulo}?`;
        let status = 1;
        if (temario.estatus === '1') {
            texto = `¿Estás seguro de desactivar el temario ${temario.titulo}, una sesión desactivada no se tomará en cuenta para calificar al colaborador?`;
            status = 0;
        }
        setConfirm({
            open: true,
            text: texto,
            aceptar: () =>
                serviciosCursos.cambiarEstatusSesionTemario(
                    (nuevaData) => {
                        actualizarData(nuevaData.temario);
                        setConfirm({ open: false });
                    },
                    { idTemario: temario.idTemario, estatus: status, idSesion: sesion.idSesion }
                )
        });
    };
    const abrirModalCurso = (temario) => {
        serviciosCursos.getCursoCosto(
            (res) =>
                setModal({
                    open: true,
                    titulo: `Costos del temario :${temario.titulo}`,
                    formulario: ModalCostosGrupo,
                    temario: { ...temario, costo: res.temarioCosto }
                }),
            { idTemario: temario.idTemario }
        );
    };
    const cerrarModalCurso = () => setModal({ ...temario, open: false });

    const abrirOrdenar = () => () => {
        context.formulario.abrir({
            open: true,
            width: 'sm',
            titulo: 'Ordenar Temas',
            propsForm: { data: { dataOrden: temario }, ctl: ctl },
            formulario: FormOrdenar,
            aceptar: (dataForm) => {
                if (dataForm.dataOrdenada == undefined) return context.mensaje({ open: true, value: 'No se ha hacho ningun orden' });
                serviciosCursos.updateOrdenTemario(
                    (res) => {
                        setTemario(res.temario);
                        context.formulario.cerrar();
                    },
                    { ...dataForm, idSesion: sesion.idSesion }
                );
                return true;
            }
        });
    };
    const guardarDependencia = (dependenciaSelected, regSelected) => (event) => {
        const dataSend = {
            dataSelected: dependenciaSelected,
            idSesion: sesion.idSesion,
            idTemario: regSelected.idTemario
        };
        serviciosCursos.guardarDependenciaTemario((res) => {
            setTemario(res.temario);
            cerrarModalCurso();
        }, dataSend);
    };
    const openFormDependencia = (selected) => {
        setModal({
            open: true,
            titulo: 'Agregar dependencias',
            formulario: FormDependenciaTemario,
            propsModal: {
                data: {
                    selected: selected,
                    allData: temario.filter((tem) => tem.idTemario != selected.idTemario),
                    columnKey: 'idTemario'
                },
                headers: headers(null, { estatusCurso: null }, false).sesion,
                guardarDependencia: guardarDependencia,
                cerrar: cerrarModalCurso
            }
        });
    };
    // Config
    const acciones = [
        { label: 'Nuevo tema', icon: <AddBox />, click: abrirNuevoCurso, disabled: !permisos.modificar },
        { label: 'Ordenar actividades', icon: <Reorder />, click: abrirOrdenar, disabled: !permisos.modificar }
    ];
    const accionesFila = [
        { label: 'Editar', onClick: abrirEditarCurso, enabled: (row) => row.estatus == '1' && permisos.modificar },
        { label: 'Dependencia', onClick: openFormDependencia, enabled: (row) => row.estatus == '1' && permisos.modificar },
        {
            label: 'Actividades',
            onClick: (temarioSeleccionado) => onCambioNivel(temarioSeleccionado, temario),
            enabled: true
        }
    ];
    useEffect(() => {
        if (sesion)
            serviciosCursos.getTemarioSesion(
                (ct) => {
                    setTemario(ct.temario || []);
                },
                { idSesion: sesion.idSesion }
            );
        else setTemario([]);
    }, [sesion]);
    return (
        <>
            <Tabla
                rows={temario}
                columns={headers(abrirConfirmarCambio, ctl, permisos).temario}
                accionesTabla={acciones}
                accionesFila={accionesFila}
                columnKey="idTemario"
            />
            <AvisoConfirmacion
                open={confirm.open}
                title="Advertencia"
                text={confirm.text}
                accept={confirm.aceptar}
                cancel={() => setConfirm({ open: false })}
            />
            <ModalGeneral open={modal.open} width="md" titulo={modal.titulo} OnCerrar={cerrarModalCurso}>
                {/* <ModalCostosGrupo temario={modal.temario} /> */}
                {modal.formulario && <modal.formulario {...modal.propsModal} />}
            </ModalGeneral>
        </>
    );
};

export default Temario;
